import Vue from 'vue';
const deposit = {
    state: {
      depositList: [],
      paymentList: [],
      depositById: {},
      depositQuery: null,
      depositReportList: [],
      depositReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      depositList: (state) => state.depositList,
      paymentList: (state) => state.paymentList,
      depositById: (state) => state.depositById,
      depositQuery: (state) => state.depositQuery,
      depositReportList: (state) => state.depositReportList,
      depositReportQuery: (state) => state.depositReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_DEPOSIT_QUERY: (state, payload) => {
        state.depositQuery = payload;
      },
      SET_DEPOSIT: (state, payload) => {
        state.depositList = payload;
      },
      SET_PAYMENT: (state, payload) => {
        state.paymentList = payload;
      },
      SET_RECEIPT_PAYMENT: (state, payload) => {
        state.paymentList = payload;
      },
      SET_DEPOSIT_BY_ID: (state, payload) => {
        if(payload) {
          state.depositById = payload;
        } else {
          state.depositById = {};
        }
      },
      SET_DEPOSIT_REPORT_QUERY: (state, payload) => {
        state.depositReportQuery = payload;
      },
      SET_DEPOSIT_REPORT: (state, payload) => {
        state.depositReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetPayment: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.deposit
              .getPayment(params)
              .then(({ data }) => {
                context.commit("SET_PAYMENT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetReceiptTaxInvoicePayments: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.deposit
              .getReceiptTaxInvoicePayments(params)
              .then(({ data }) => {
                context.commit("SET_RECEIPT_PAYMENT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetDepositReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.depositSalesReport
              .getDepositSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_DEPOSIT_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetDeposit: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.deposit
              .getDeposit(params)
              .then(({ data }) => {
                context.commit("SET_DEPOSIT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetDeposit: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.deposit
            .addDeposit(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'deposit.message.depositSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditDeposit: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.deposit
            .editDeposit(payload.deposit_id, payload.deposit)
            .then((res) => {
              if (res.data.abc && res.data.abc.type === 'confirmation') {
                context.commit('SET_BOOKKEEPING_CONFIRMATION', res.data.abc.type, {root: true});
              } else if (res.data.abc && res.data.abc.type === 'alert') {
                context.commit('SET_ALERT_CONFIRMATION', res.data.abc.type, {root: true});
              }
              let successData = Object.assign({ success: 'deposit.message.depositSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetDepositById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.deposit
              .getDepositById(payload)
              .then(({ data }) => {
                context.commit('SET_DEPOSIT_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteDepositItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.deposit
            .deleteDeposit(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'deposit.message.depositSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetReceipt', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default deposit;