export default {
    receiptTaxInvoice: "Receipt Tax Invoice",
    addReceiptTaxInvoice: "Add Receipt Tax Invoice",
    receiptTaxInvoiceList: "Receipt Tax Invoice List",
    receiptTaxInvoiceActions: "Receipt Tax Invoice Actions",
    from: "From",
    to: "To",
    id: "ID",
    saveAndCreate: "Save & Create",
    save: "Save",
    customerDetail: "Customer Detail",
    receiptTaxInvoiceDetail: "Receipt Tax Invoice Detail",
    listOfItems: "List Of Items",
    no: "No",
    product: "Item",
    itemDescription: "Item Description",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    lineDiscount: "Line Discount",
    total: "Total",
    action: "Action",
    createReceipt: "Show Receipt",
    copy: "Copy & Create New",
    activityLog: "Activity Log",
    addReceipt: "Create Receipt",
    filters: "Filters",
    excel: "Excel",
    resetFilter: "Reset Filter",
    execute: "Execute",
    reset: "Reset",
    fieldView: "Field View",
    selectFields: "Select Fields",
    update: "Update",
    selectAll: "Select All",
    debitReport: "Receipt Tax Invoice Report",
    receiptTaxItem: "Receipt Tax Invoice Item",
    receiptTaxInvoiceNo: "Receipt Tax Invoice Doc Number.",
    paymentType: "Payment Type",
    chequeNo: "Cheque No.",
    bank: "Bank",
    branch: "Branch",
    accountNo: "Account No.",
    amount: "Amount",
    remarks: "Remarks",
    taxInvoiceStatus: "Tax Invoice status",
    field: {
      status: "Status",
      customer: "Customer",
      all: "All",
      open: "Open",
      finished: "Closed",
      canceled: "Cancelled",
      title: "Title",
      grandTotal: "Grand total",
      customerName: "Customer Name",
      address: "Address",
      town: "Town",
      customerOrderNo: "Customer Order No.",
      contact: "Contact",
      agent: "Employee",
      order: "Order",
      remarks: "Remarks",
      discount: "Discount",
      afterDiscount: "After discount",
      vat: "VAT",
      year: "Year",
      invoiceDate: "Invoice Date",
      deliveryDate: "Delivery Date",
      deliveryCompany: "Delivery Company",
      quotationNo: "Quotation No.",
      orderNo: "Order No.",
      carNo: "Car No.",
      balance: "Balance",
      accountUpdate: "Account",
      email: "E-mail",
      country: "Country",
      authorizedDealerNo: "Authorized Dealer No.",
      cel: "Cellular",
      contactId: "Contact Id",
      customerId: "Customer Id",
      discountPrecentage: "Discount Precentage",
      employeeId: "Employee Id",
      fax: "Fax",
      quotationId: "Quotation Id",
      orderId: "Order Id",
      tel1: "Tel 1",
      tel2: "Tel 2",
      paymentDate: "Payment Date",
      vatPrecentage: "VAT Precentage",
      createdAt: "Craeted At",
      updatedAt: "Updated At",
    },
    message: {
      receiptTaxSuccessfullyAdded: "Receipt tax invoice added successfully",
      receiptTaxSuccessfullyDeleted: "Receipt tax invoice cancelled successfully",
      receiptTaxSuccessfullyEdited: "Receipt tax invoice edited successfully",
    }
}