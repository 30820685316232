export default {
    returnCertificate: "תעודת החזרה",
    returnCertificateList: "רשימת תעודת החזרה",
    debitReport: 'דו"ח תעודת החזרה',
    returnCertificateTime: "זְמַן תעודת החזרה",
    returnCertificateDate: "תַאֲרִיך תעודת החזרה",
    returnCertificateCompany: "חֶברָה תעודת החזרה",
    returnCertificateNo: "לא תעודת החזרה",
    returnCertificateStatus: "סטָטוּס תעודת החזרה",
    docNumber: "מספר מסמך תעודת החזרה",
    returnCertificateAction: "פעילות תעודת החזרה",
    addReturnCertificate: "הוסף תעודת החזרה",
    createCreditInvoice: "שמור וצור חשבונית אשראי",
    deliveryNo: "מס' משלוח",
    returnCertificateDetail: "פרטי תעודת החזרה",
    returnCertificateItems: "פריטים תעודת החזרה",
    createReturnCertificate:"לִיצוֹר תעודת החזרה",
    message: {
        returnCertificateSuccessfullyAdded: "תעודת החזרה נוספה בהצלחה",
        returnCertificateSuccessfullyDeleted: "תעודת החזרה נמחקה בהצלחה",
        returnCertificateSuccessfullyEdited: "תעודת החזרה נערכה בהצלחה",
    }
}