import Vue from "vue";

const generalEvaluation = {
  state: {
    obligationDataList: [],
    doubleClickDataList: [],
    pageChange: {},
  },
  getters: {
    obligationDataList: (state) => state.obligationDataList,
    doubleClickDataList: (state) => state.doubleClickDataList,
    pageChange: (state) => state.pageChange,
  },
  mutations: {
    SET_OBLIGATION: (state, payload) => {
      state.obligationDataList = payload;
    },
    SET_DOUBLE_CLICK_DATA: (state, payload) => {
      state.doubleClickDataList = payload;
    },
    SHOW_PAGE_CHANGE: (state, payload) => {
      state.pageChange = payload;
    },
  },
  actions: {
    GetObligationData: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.generalEvaluation
            .getObligationData(params)
            .then(({ data }) => {
              context.commit("SET_OBLIGATION", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetFunds", err);
      }
    },
    GetDoubleClickData: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.generalEvaluation
            .getDoubleClickData(params)
            .then(({ data }) => {
              context.commit("SET_DOUBLE_CLICK_DATA", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetFunds", err);
      }
    },
  },
  namespaced: true,
};

export default generalEvaluation;
