export default {
  debitReport: 'דו"ח מכירות',
  group: "קבוצה לקוח",
  customer: 'לקוח',
  agent: "סוכן",
  branch: "סניף/תוקף",
  customerName : "שם לקוח",
  customerNumber : "מספר לקוח",
  balance : "סכום מצטבר",
  area: "קבוצה אזור",
  tel1 : "טלפון",
  cel1 : "סלולר",
  total: 'סה"כ',
  listOfItems: "רשימת פריטים",
  from: "מ",
  to: "עד",
  amount: "סכום",
  filters: "מסננים",
  resetFilter: "אפס מסנן",
  fromInvoiceDate:"מ תאריך חשבונית",
  toInvoiceDate:"עד תאריך חשבונית",
  fromPaymentDate:"מ תאריך תשלום",
  toPaymentDate:"עד תאריך תשלום",
  focused: "מְרוּכָּז",
  reset: "אתחול",
  execute: 'בצע',
  invoiceNumber: "מספר",
  invoiceDate: "תאריך חשבונית",
  originalAmount: "כמות",
  cumulativeAmount: "סכום מצטבר",
  creditInvoiceId: "מזהה חשבונית אשראי",
  creditInvoiceGrandTotal: "סכום סופי",
  creditInvoiceTitle: "כותרת חשבונית אשראי",
  creditInvoiceTotal: "סך חשבונית זיכוי",
  itemGroup: "קבוצת פריטים",
  withPrice: "סך הכל ללקוח",
  type: "סוּג",
  Invoice: "חשבונית",
  CreditInvoice: "חשבונית זיכוי",
  excel: "Excel",
  allCumulitiveTotal: 'סה"כ מצטבר',
  alloriginalAmountTotal: 'סה"כ לקוח'
}