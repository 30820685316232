import Vue from 'vue';

const other = {
    state: {
        otherList: [],
        otherById: {},
        otherQuery: null,
    },
    getters: {
        otherList: (state) => state.otherList,
        otherById: (state) => state.otherById,
        otherQuery: (state) => state.otherQuery,
    },
    mutations: {
        SET_OTHER_QUERY: (state, payload) => {
            state.otherQuery = payload;
        },
        SET_OTHER: (state, payload) => {
            state.otherList = payload;
        },
        SET_OTHER_BY_ID: (state, payload) => {
            if (payload) {
                state.otherById = payload;
            } else {
                state.otherById = {};
            }
        }
    },
    actions: {
        GetOther: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.bookkeeping
                        .getIncome(params)
                        .then(({ data }) => {
                            context.commit("SET_OTHER", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetOther', err);
            }
        },
        SetOther: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .addIncome(payload)
                    .then((res) => {
                        if (res.data.type === "success" || res.data.type === "confirmation") {
                            resolve(res);
                        } else {
                            let successData = Object.assign({ success: 'other.message.otherSuccessfullyAdded' })
                            context.dispatch('SetSnackBar', successData, { root: true });
                            resolve(res);
                        }
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
        EditOther: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .editIncome(payload.other_id, payload.other)
                    .then((res) => {
                        if (res.data.type === "success" || res.data.type === "confirmation") {
                            resolve(res);
                        } else {
                            let successData = Object.assign({ success: 'other.message.otherSuccessfullyEdited' })
                            context.dispatch('SetSnackBar', successData, { root: true })
                            resolve(res);
                        }
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    })
            })
        },
        GetOtherById: async (context, payload) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.bookkeeping
                        .getIncomeById(payload)
                        .then(({ data }) => {
                            context.commit('SET_OTHER_BY_ID', data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetOther', err);
            }
        },
        DeleteOtherById: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .deleteIncome(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'other.message.otherSuccessfullyDeleted' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
    },
    namespaced: true,
}
export default other