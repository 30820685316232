export default {
  supplier: 'Supplier',
  supplierActions: "Supplier Actions",
  editSupplier: "Edit Supplier",
  addSupplier: "Add Supplier",
  supplierName: "Supplier Name",
  supplierName2: "Supplier Name 2",
  save: "Save",
  saveAndContinue: "Save and continue",
  suppliersList: "Supplier List",
  id: "ID",
  action: "Action",
  group: "Group",
  basicDetails: "Basic Details",
  otherDetails: "Other Details",
  notes: "Notes",
  displayText: "Communication",
  communication: "Communication",
  extension: "Extension",
  no: "No.",
  areaCode: "Area Code",
  country: "Country",
  tel: "Tel",
  cel: "Cel",
  fax: "Fax",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Supplier Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  message: {
    supplierSuccessfullyDeleted: "Supplier cancelled successfully",
    supplierSuccessfullyAdded: "Supplier added successfully",
    supplierSuccessfullyEdited: "Supplier edited successfully",
  },
  field: {
    termsOfPayment: "Terms of Payment",
    group: "Supplier group",
    area: "Region",
    status: "Status",
    address1: "Address 1",
    pobox1: "P.O.Box 1",
    postalCode1: "Postal Code 1",
    town1: "Town 1",
    address2: "Address 2",
    pobox2: "P.O.Box 2",
    postalCode2: "Postal Code 2",
    town2: "Town 2",
    state: "State",
    country: "Country",
    supplierOpeningDate: "Supplier opening date",
    supplierEndDate: "Supplier end date",
    email: "E-mail",
    site: "Site",
    authorizedDealerNo: "Authorized dealer number",
    bookKipingNo: "Bookkeeping number",
    warningNote: "Warning note",
    generalRemarks: "General remarks",
    countryPrefix0: "Country prefix",
    dialingArea0: "Area prefix",
    phones0: "Phones",
    extension0: "Extension",
    countryPrefix1: "Country prefix 1",
    dialingArea1: "Dialing area 1",
    phones1: "Phones 1",
    extension1: "Extension 1",
    countryPrefix2: "Country prefix 2",
    dialingArea2: "Dialing area 2",
    phones2: "Phones 2",
    extension2: "Extension 2",
    countryPrefix3: "Country prefix 3",
    dialingArea3: "Dialing area 3",
    cellular1: "Cellular 1",
    cellular2: "Cellular 2",
    cellular3: "Cellular 3",
    countryPrefix4: "Country prefix 4",
    dialingArea4: "Dialing area 4",
    cellular4: "Cellular 4",
    countryPrefix5: "Country prefix 5",
    dialingArea5: "Dialing area 5",
    cellular5: "Cellular 5",
    createdAt: "Created At",
    updatedAt: "Updated At"
  }
}