import Vue from "vue";
const pdfTemplate = {
  state: {
    pdfTemplateList: [],
    pdfTemplateQuery: null,
  },
  getters: {
    pdfTemplateList: (state) => state.pdfTemplateList,
    pdfTemplateQuery: (state) => state.pdfTemplateQuery,
  },
  mutations: {
    SET_PDF_TEMPLATE_QUERY: (state, payload) => {
      state.pdfTemplateQuery = payload;
    },
    SET_PDF_TEMPLATE: (state, payload) => {
      state.pdfTemplateList = payload;
    },
  },
  actions: {
    GetPdfTemplate: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.pdfTemplate
            .getPdfTemplate(params)
            .then(({ data }) => {
              context.commit("SET_PDF_TEMPLATE", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    SetPdfTemplate: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.pdfTemplate
          .addPdfTemplate(payload)
          .then((res) => {
            let successData = Object.assign({success: "journalEntry.message.pdfTemplateSuccessfullyAdded"});
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditPdfTemplate: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.pdfTemplate
          .editPdfTemplate(payload.id, payload.pdfTemplate)
          .then((res) => {
            let successData = Object.assign({success: "journalEntry.message.pdfTemplateSuccessfullyEdited"});
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    DeletePdfTemplate: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.pdfTemplate
          .deletePdfTemplate(payload)
          .then((res) => {
            let successData = Object.assign({success: "journalEntry.message.pdfTemplateSuccessfullyDeleted"});
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
export default pdfTemplate;
