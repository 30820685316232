import Vue from 'vue';

const personalMenu = {
    state: {
        menuList: {},
    },
    getters: {
        menuList: (state) => state.menuList,
    },
    mutations: {
        SET_MENU_BY_ID: (state, payload) => {
            if (payload) {
                state.menuList = payload;
            } else {
                state.menuList = {};
            }
        },
    },
    actions: {
        GetPersonalMenuList: async (context, payload) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.getUserList
                        .getMenuList(payload)
                        .then(({ data }) => {
                            data.sort((a, b) => {
                                let comparison = 0;
                                if (a.menu < b.menu) {
                                    comparison = -1;
                                } else if (a.menu > b.menu) {
                                    comparison = 1;
                                }
                                return comparison;
                            });
                            context.commit('SET_MENU_BY_ID', data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetOrder', err);
            }
        },
        AddMenuList: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.getUserList
                    .addMenuList(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'remarks.message.personalMenuSuccessfullyEdited' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
        EditPersonalMenu: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.getUserList
                    .updateMenuList(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'remarks.message.personalMenuSuccessfullyEdited' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
    }
}

export default personalMenu;