import Vue from "vue";
const supplierPrice = {
  state: {
    supplierPriceListData: [],
    supplierPriceQuery: null,
    checkPriceData: null,
  },
  getters: {
    supplierPriceListData: (state) => state.supplierPriceListData,
    supplierPriceQuery: (state) => state.supplierPriceQuery,
    checkPriceData: (state) => state.checkPriceData,
  },
  mutations: {
    SET_SUPPLIER_PRICE_QUERY: (state, payload) => {
      state.supplierPriceQuery = payload;
    },
    SET_SUPPLIER_PRICE: (state, payload) => {
      state.supplierPriceListData = payload;
    },
    SET_SUPPLIER_IS_EXIST: (state, payload) => {
      state.checkPriceData = payload;
    },
  },
  actions: {
    GetSupplierPriceData: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierPrice
            .getSupplierPrice(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_PRICE", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetBank", err);
      }
    },
    SetSupplierPriceData: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPrice
          .addSupplierPrice(payload)
          .then((res) => {
            let successData = Object.assign({success: "product.message.supplierPriceSuccessfullyAdded"});
            context.dispatch("GetSupplierPriceData", "?where=item_id|" + payload.item_id);
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditSupplierPrice: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPrice
          .editSupplierPrice(payload.id, payload.supplierPrice)
          .then((res) => {
            let successData = Object.assign({success: "product.message.supplierPriceSuccessfullyEdited"});
            context.dispatch("GetSupplierPriceData", "?where=item_id|" + payload.supplierPrice.item_id);
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    SupplierIsExist: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierPrice
            .checkSupplierIsExist(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_IS_EXIST", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetBank", err);
      }
    },
    DeleteSupplierPriceDataById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPrice
          .deleteSupplierPrice(payload)
          .then((res) => {
            let successData = Object.assign({success: "product.message.supplierPriceSuccessfullyDeleted"});
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
export default supplierPrice;
