import Vue from "vue";
const supplierDeliveryNote = {
  state: {
    supplierDeliveryNoteList: [],
    supplierDeliveryNoteQuery: null,
    supplierDeliveryNoteReportList: [],
    supplierDeliveryNoteReportQuery: null,
    rowIndex: null,
    supplierDeliveryNoteById: {},
  },
  getters: {
    supplierDeliveryNoteList: (state) => state.supplierDeliveryNoteList,
    supplierDeliveryNoteQuery: (state) => state.supplierDeliveryNoteQuery,
    supplierDeliveryNoteReportList: (state) => state.supplierDeliveryNoteReportList,
    supplierDeliveryNoteReportQuery: (state) => state.supplierDeliveryNoteReportQuery,
    rowIndex: (state) => state.rowIndex,
    supplierDeliveryNoteById: (state) => state.supplierDeliveryNoteById,
  },
  mutations: {
    SET_SUPPLIER_DELIVERY_NOTE_QUERY: (state, payload) => {
      state.supplierDeliveryNoteQuery = payload;
    },
    SET_SUPPLIER_DELIVERY_NOTE: (state, payload) => {
      state.supplierDeliveryNoteList = payload;
    },
    SET_SUPPLIER_DELIVERY_NOTE_REPORT_QUERY: (state, payload) => {
      state.supplierDeliveryNoteReportQuery = payload;
    },
    SET_SUPPLIER_DELIVERY_NOTE_REPORT: (state, payload) => {
      state.supplierDeliveryNoteReportList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
    SET_SUPPLIER_DELIVERY_NOTE_BY_ID: (state, payload) => {
      if (payload) {
        state.supplierDeliveryNoteById = payload;
      } else {
        state.supplierDeliveryNoteById = {};
      }
    },
  },
  actions: {
    GetSupplierDeliveryNote: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierDeliveryNote
            .getSupplierDeliveryNote(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_DELIVERY_NOTE", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] Getorder", err);
      }
    },
    GetSupplierDeliveryNoteReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.salesReport
            .getSalesReport(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_DELIVERY_NOTE_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    GetSupplierDeliveryNoteById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierDeliveryNote
            .getSupplierDeliveryNoteById(payload)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_DELIVERY_NOTE_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetOrder", err);
      }
    },
    DeleteSupplierDeliveryNoteById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierDeliveryNote
          .deleteSupplierDeliveryNote(payload)
          .then((res) => {
            if (res?.data?.type !== "delete") {
              let successData = Object.assign({
                success: "supplierDeliveryNote.message.supplierDeliveryNoteSuccessfullyDeleted",
              });
              context.dispatch("SetSnackBar", successData, { root: true });
            }
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    SetSupplierDeliveryNote: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierDeliveryNote
          .addSupplierDeliveryNote(payload)
          .then((res) => {
            let successData = Object.assign({
              success: "supplierDeliveryNote.message.supplierDeliveryNoteSuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (!error.response?.data?.type) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditSupplierDeliveryNote: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierDeliveryNote
          .editSupplierDeliveryNote(payload.supplier_delivery_note_id, payload.supplierDeliveryNote)
          .then((res) => {
            let successData = Object.assign({
              success: "supplierDeliveryNote.message.supplierDeliveryNoteSuccessfullyEdited",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (!error.response?.data?.type) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
export default supplierDeliveryNote;
