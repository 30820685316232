import Vue from 'vue';

const updateModule = {
    actions: {
        SetUpdateReceipt: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.updatedDocuments
                    .updateReceipt(payload)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
        SetUpdateCreditInvoice: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.updatedDocuments
                    .updateCreditInvoice(payload)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
    },
    namespaced: true,
}

export default updateModule;