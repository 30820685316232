import Vue from 'vue';
const contact = {
    state: {
      contactList: [],
      contactById: {},
    },
  
    getters: {
      contactList: (state) => state.contactList,
      contactById: (state) => state.contactById,
    },
    mutations: {
      SET_CONTACT: (state, payload) => {
        state.contactList = payload;
      },
      SET_CONTACT_BY_ID: (state, payload) => {
        if(payload) {
          state.contactById = payload;
        } else {
          state.contactById = {};
        }
      }
    },
    actions: {
      GetContact: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.contact
              .getContact(params)
              .then(({ data }) => {
                context.commit("SET_CONTACT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetContact: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.contact
            .addContact(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'contact.message.contactSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditContact: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.contact
            .editContact(payload.contact_id, payload.contact)
            .then((res) => {
              let successData = Object.assign({ success: 'contact.message.contactSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetContactById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.contact
              .getContactById(payload)
              .then(({ data }) => {
                context.commit('SET_CONTACT_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteContactItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.contact
            .deleteContact(payload.id)
            .then((res) => {
              let successData = Object.assign({ success: 'contact.message.contactSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default contact;