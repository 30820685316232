export default {
    receiptTaxInvoice: "חשבונית/קבלה",
    addReceiptTaxInvoice: "הוסף חשבונית/קבלה",
    receiptTaxInvoiceList: "רשימת חשבונית/קבלה",
    receiptTaxInvoiceActions: "פעולות חשבונית/קבלה",
    from: "מ",
    to: "עד",
    id: "קוד",
    saveAndCreate: "שמור וצור",
    save: "שמור",
    customerDetail: "מידע לקוח",
    receiptTaxInvoiceDetail: "פרטי חשבונית/קבלה",
    listOfItems: "רשימת פריטים",
    no: "מס' שורה",
    product: "פריט",
    itemDescription: "תאור פריט",
    quantity: "כמות",
    unitPrice: "מחיר יחידה",
    lineDiscount: "הנחה",
    total: 'סה"כ',
    action: "פעילות",
    createReceipt: "הצג קבלה",
    copy: "העתק וצור חדש",
    activityLog: "יומן פעילות",
    addReceipt: "וצור קבלה",
    filters: "מסננים",
    excel: "Excel",
    resetFilter: "אפס מסנן",
    execute: "בצע",
    reset: "אתחול",
    fieldView: "תצוגת שדה",
    selectFields: "בחר שדות",
    update: "עִדכּוּן",
    selectAll: "בחר הכל",
    debitReport: "דוח חשבונית/קבלה",
    receiptTaxItem: "פריט חשבונית/קבלה",
    receiptTaxInvoiceNo: "חשבונית/קבלה מספר מסמך",
    paymentType: "סוג תשלום",
    chequeNo: "מספר המחאה/אישור",
    bank: "בנק",
    branch: "סניף/תוקף",
    accountNo: "מספר חשבון/כרטיס",
    amount: "סכום",
    remarks: "הערות",
    taxInvoiceStatus: "סטטוס חשבוניות קבלה",
    field: {
      status: "מצב הצעה",
      customer: "לקוח",
      all: "כולם",
      open: "פתוח",
      finished: "סגור",
      canceled: "מבוטל",
      title: "כותרת חשבונית/קבלה",
      grandTotal: 'סה"כ סופי',
      customerName: "שם לקוח",
      address: "כתובת לקוח",
      town: "עיר",
      customerOrderNo: "הזמנת לקוח",
      contact: "איש קשר",
      agent: "סוכן",
      order: "להזמין",
      remarks: "הערות",
      discount: "הנחה",
      afterDiscount: "לאחר הנחה",
      vat: 'מע"מ',
      year: "שָׁנָה",
      invoiceDate: "תאריך חשבונית",      
      deliveryDate: "תאריך משלוח",
      deliveryCompany: "חברת שליחויות",
      quotationNo: "מספר הצעת מחיר",
      orderNo: "מספר הזמנה",
      carNo: "מספר רכב",
      balance: "יתרה",
      accountUpdate: 'הנה"ח',
      email: "אי מייל",
      country: "מדינה",
      authorizedDealerNo: "מספר עוסק מורשה",
      cel: "נוסף סלולרי",
      contactId: "זיהוי איש קשר",
      customerId: "מספר לקוח",
      discountPrecentage: "אחוז הנחה",
      employeeId: "כרטיס עובד",
      fax: "נוסף פקס",
      quotationId: "זיהוי הצעת מחיר",
      orderId: "מספר הזמנה",
      tel1: "טל. נוסף 1",
      tel2: "טל. נוסף 2",
      paymentDate: "תאריך תשלום",
      vatPrecentage: 'אחוז מע"מ',
      createdAt: "נוצר ב",
      updatedAt: "מעודכן ב",
    },
    message: {
      receiptTaxSuccessfullyAdded: "חשבונית/קבלה נוספה בהצלחה",
      receiptTaxSuccessfullyDeleted: "חשבונית/קבלה נמחקה בהצלחה",
      receiptTaxSuccessfullyEdited: "חשבונית/קבלה נערכה בהצלחה",
    }
}