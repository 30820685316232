export default {
    order: 'Order',
    orderActions: "Order Actions",
    editOrder: "Edit Order",
    addOrder: "Add Order",
    save: "Save",
    orderList: "Order List",
    id: "ID",
    action: "Action",
    no: "No",
    copy: 'Copy & Create New',
    product: "Item",
    orderItem: 'Order Item',
    quantity: "Quantity",
    unitPrice: "Unit Price",
    lineDiscount: "Line Discount",
    itemDescription: "Item Description",
    total: "Total",
    orderDetail: "Order Detail",
    customerDetail: "Customer Detail",
    additionalCustomerDetail: "Additional Customer Details",
    listOfItems: "List Of Items",
    from: "From",
    to: "To",
    createdelivery: "Save & Create Delivery",
    saveAndCreate: "Save & Create",
    resetFilter: "Reset filter",
    filters: "Filters",
    execute: "Execute",
    reset: "Reset",
    fieldView: "Field View",
    debitReport: "Order Report",
    selectFields: "Select Fields",
    confirm: "Confirm",
    cancel: "Cancel",
    selectAll: "Select All",
    excel: "Excel",
    update: "Update",
    customerGroup: "Customer Group",
    message: {
      orderSuccessfullyDeleted: "Order cancelled successfully",
      orderSuccessfullyAdded: "Order added successfully",
      orderSuccessfullyEdited: "Order edited successfully",
    },
    field: {
      customer: "Customer",
      customerName: "Customer Name",
      orderStartDate: "Order date",
      orderEndDate: "Delivery date",
      orderTime: "Order time",
      customerOrderNo: "Customer Order No.",
      quotation: "Quotation",
      title: "Title",
      address: "Address",
      town: "Town",
      country: "Country",
      tel1: "Tel 1",
      tel2: "Tel 2",
      cel: "Cel",
      fax: "Fax",
      email: "E-mail",
      authorizedDealerNo: "Authorized dealer number",
      contact: "Contact",
      agent: "Employee",
      remarks: "Remarks",
      timePrinted: "Time printed",
      orderNo: "Order no.",
      invoiceNo: "Invoice no.",
      invoiceYear: "Invoice year",
      status: "Status",
      total: "Total",
      discountPrecentage: "Discount percentage",
      discount: "Discount",
      afterDiscount: "After discount",
      vat: "VAT",
      vatPrecentage: "Vat percentage",
      grandTotal: "Grand total",
      all: "All",
      open: "Open",
      finished: "Closed",
      canceled: "Cancelled",
      deliveryNo: "Delivery No.",
      contactId: "Contact Id",
      customerId: "Customer Id",
      employeeId: "Employee Id",
      quotationId: "Quotation Id",
      proformaDeliveryNoteId: "Proforma Delivery Note Id",
      deliveryNoteId: "Delivery Note Id",
      createdAt: "Created At",
      updatedAt: "Updated At",
      ordersStatus: "Orders status",
      docNo: "Doc Num",
      docDate: "Doc Date",
      docTitle: "Doc Title",
    }
  }