import Vue from 'vue';
const profile = {
    state: {
      profileById: {},
    },
  
    getters: {
      profileById: (state) => state.profileById,
    },
    mutations: {
      SET_PROFILE_BY_ID: (state, payload) => {
        if(payload) {
          state.profileById = payload;
        } else {
          state.profileById = {};
        }
      },
    },
    actions: {
      EditCompany: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.profile
            .editCompany(payload.company)
            .then((res) => {
              let successData = Object.assign({ success: 'company.message.companySuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetCompanyById: async (context) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.profile
              .getCompanyById()
              .then(({ data }) => {
                context.commit('SET_PROFILE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetMedia: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.profile
            .addMedia(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditMedia: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.profile
            .editMedia(payload.media_id, payload.formData)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeleteMediaById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.profile
            .deleteMedia(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default profile;