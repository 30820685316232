export default {
  delivery: 'תעודת משלוח',
  deliveryActions: "פעולות תעודת משלוח",
  editDelivery: "ערוך מסירה",
  addDelivery: "הוסף משלוח",
  save:"שמור",
  deliveryList: "רשימת משלוחים",
  id: "קוד",
  action: "פעילות",
  no: "מס' שורה",
  product: "פריט",
  deliveryItem: 'פריט משלוחים',
  quantity: "כמות",
  unitPrice: "מחיר יחידה",
  lineDiscount: "הנחה",
  itemDescription: "תאור פריט",
  total: 'סה"כ',
  deliveryDetail: "פרטי משלוח",
  customerDetail: "מידע לקוח",
  additionalCustomerDetail: "פרטי לקוח נוספים",
  listOfItems: "רשימת פריטים",
  from: "מ",
  to: "עד",
  createInvoice: "שמור וצור חשבונית",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח ת.משלוח",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerGroup: "קבוצה של לקוחות",
  message: {
    deliverySuccessfullyDeleted: "המשלוח נמחק בהצלחה",
    deliverySuccessfullyAdded: "המשלוח נוסף בהצלחה",
    deliverySuccessfullyEdited: "המשלוח נערך בהצלחה",
  },
  field: {
    customer: "לקוח",
    customerName: "שם לקוח",
    deliveryDate: "תאריך משלוח",
    deliveryTime: "זמן משלוח",
    title: "כותרת משלוח",
    address: "כתובת לקוח",
    town: "עיר",
    country: "מדינה",
    tel1: "טל. נוסף 1",
    tel2: "טל. נוסף 2",
    cel: "נוסף סלולרי",
    fax: "נוסף פקס",
    email: "אי מייל",
    authorizedDealerNo: "מספר עוסק מורשה",
    contact: "איש קשר",
    agent: "סוכן",
    remarks: "הערות",
    timePrinted: "שעת הדפסה",
    orderNo: "מספר הזמנה",
    invoiceNo: "מספר חשבונית",
    invoiceYear: "שנת חשבונית",
    status: "מצב משלוח",
    total: 'סה"כ',
    discountPrecentage: "אחוז הנחה",
    discount: "הנחה",
    afterDiscount: "לאחר הנחה",
    vat: 'מע"מ',
    vatPrecentage: 'אחוז מע"מ',
    grandTotal: 'סה"כ סופי',
    all: "כולם",
    open: "פתוח",
    finished: "סגור",
    canceled: "מבוטל",
    noInvoiced: "ללא חשבונית",
    customerOrderNo: "הזמנת לקוח",
    quotationNo: "מספר הצעת מחיר",
    deliveryNo: "משלוח מספר",
    carNo: "מספר רכב",
    order: "להזמין",
    deliveryCompany: "חברת שליחויות",
    hour: "חח:מ'מ",
    year: "שָׁנָה",
    contactId: "זיהוי איש קשר",
    customerId: "מספר לקוח",
    employeeId: "כרטיס עובד",
    quotationId: "זיהוי הצעת מחיר",
    orderId: "מספר הזמנה",
    proformaInvoiceId: "זיהוי חשבונית פרופורמה",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    deliveryStatus: "סטטוס תעודות משלוח",
    warehouse: "מחסן"
  }
}