export default {
    id: "קוד",
    other: "אחרים",
    description: "תאור",
    accountNumber: ".מספר חשבון",
    otherAction: "אחרים פעולה",
    addOther: "הוסף אחר",
    actions: "פעילות",
    options: "אפשרויות",
    copy: "העתק וצור חדש",
    activityLog: "יומן פעילות",
    save: "שמור",
    relativeNumber: "מספרים יחסיים",
    balanceSheetSection: "סעיפי מאזן בוחן",
    grossProfit: "רווח גולמי",
    otherList: "רשימה אחרת",
    message: {
        otherSuccessfullyDeleted: "אחרת בוטלה בהצלחה",
        otherSuccessfullyAdded: "אחרת נוספה בהצלחה",
        otherSuccessfullyEdited: "אחרת נוצרה בהצלחה",
    },
}