export default {
  supplierPaymentList: "Supplier Payment List",
  supplierPaymentNo: "Supplier Payment No",
  supplierPaymentDetail: "Supplier Payment Detail",
  supplierPaymentItem: "Supplier Payment Item",
  message: {
    supplierPaymentSuccessfullyAdded: "Supplier Payment cancelled successfully",
    supplierPaymentSuccessfullyEdited: "Supplier Payment added successfully",
    supplierPaymentSuccessfullyDeleted: "Supplier Payment edited successfully",
  },
};
