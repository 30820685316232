export default {
  auxiliaryTable: "Category",
  description: "Description",
  days: "Days",
  months: "Months",
  current: "Current",
  orderBy: "Order By",
  save: "Save",
  noDataFound: "No data found",
  listOfTables: "List Of Categories",
  selectTable: "Select Table",
  addTableName: "Add table name",
  editTableName: "Edit table name",
  tableName: "Table Name",
  editValue: "Edit Value",
  addNewValue: "Add new value",
  confirm: "Confirm",
  alert: "Alert",
  cancel: "Cancel",
  confirmMessage: "Are you sure you want to cancel this item?",
  message: {
    auxiliaryTableNameSuccessfullyDeleted: "Auxiliary table name cancelled successfully",
    auxiliaryTableNameSuccessfullyAdded: "Auxiliary table name added successfully",
    auxiliaryTableNameSuccessfullyEdited: "Auxiliary table name edited successfully",
    auxiliaryTableValueSuccessfullyDeleted: "Table value cancelled successfully",
    auxiliaryTableValueSuccessfullyAdded: "Table value added successfully",
    auxiliaryTableValueSuccessfullyEdited: "Table value edited successfully",
  },
}