export default {
  invoice: 'Invoice',
  invoiceActions: "Invoice Actions",
  editInvoice: "Edit Invoice",
  addInvoice: "Add Invoice",
  save: "Save",
  invoiceList: "Invoice List",
  id: "ID",
  action: "Action",
  no: "No",
  product: "Item",
  invoiceItem: 'Invoice Item',
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lineDiscount: "Line Discount",
  itemDescription: "Item Description",
  total: "Total",
  invoiceDetail: "Invoice Detail",
  createReceipt: "Create Receipt",
  customerDetail: "Customer Detail",
  additionalCustomerDetail: "Additional Customer Details",
  listOfItems: "List Of Items",
  from: "From",
  to: "To",
  createFromMultiple: "Create from multiple",
  prepareInvoice: "Prepare invoice",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Invoice Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerGroup: "Customer Group",
  empty: "Empty",
  invoiceStatus: "Invoices status",
  tech: "Tech",
  qutation: "Quotation",
  order: 'Order', 
  proformainvoice: 'Proforma Invoice',
  recipttaxinvoice: 'Receipt Tax Invoice',
  creditinvoice: 'Credit Invoice',
  returncertificate: 'Return Certificate',
  delivery: "Delivery",
  createDate: "Creating Document Date",
  message: {
    invoiceSuccessfullyDeleted: "Invoice cancelled successfully",
    invoiceSuccessfullyAdded: "Invoice added successfully",
    invoiceSuccessfullyEdited: "Invoice edited successfully",
    periodClosureSuccessfullyAdded: "Period closure edited successfully",
    periodClosureSuccessfullyDeleted: "Period closure cancelled successfully"
  },
  field: {
    customer: "Customer",
    customerName: "Customer Name",
    invoiceDate:"Invoice Date",
    deliveryDate:"Delivery Date",
    paymentDate:"Payment Date",
    title: "Title",
    address: "Address",
    town: "Town",
    country: "Country",
    tel1: "Tel 1",
    tel2: "Tel 2",
    cel: "Cel",
    fax: "Fax",
    email: "E-mail",
    authorizedDealerNo: "Authorized dealer number",
    contact: "Contact",
    agent: "Employee",
    remarks: "Remarks",
    timePrinted: "Time printed",
    orderNo: "Order no.",
    invoiceNo: "Invoice no.",
    invoiceYear: "Invoice year",
    status: "Status",
    total: "Total",
    discountPrecentage: "Discount percentage",
    discount: "Discount",
    afterDiscount: "After discount",
    vat: "VAT",
    vatPrecentage: "Vat percentage",
    grandTotal: "Grand total",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    customerOrderNo: "Customer Order No.",
    quotationNo: "Quotation No.",
    receiptNo: "Receipt No.",
    carNo: "Car No.",
    order: "Order",
    deliveryCompany: "Delivery Company",
    balance: "Balance",
    accountUpdate: "Account",
    year: "Year",
    contactId: "Contact Id",
    customerId: "Customer Id",
    employeeId: "Employee Id",
    quotationId: "Quotation Id",
    orderId: "Order Id",
    proformaInvoiceId: "Proforma Invoice Id",
    deliveryNoteId: "Delivery Note Id",
    createdAt: "Created At",
    updatedAt: "Updated At",
    proformaNo: "Proforma Invoice No.",
    deliveryNo: "Delivery No.",
  }
}