export default {
    debitReport: 'Sales Report',
    group: "Customer group",
    customer: "Customer",
    agent: "Employee",
    branch: "Branch",
    customerName : "Customer Name",
    invoiceNumber: "Number",
    invoiceDate: "Invoice Date",
    customerNumber : "Customer Number",
    balance : "Balance",
    area: "Region group",
    tel1 : "Tel",
    cel1 : "Cel",
    total: "Total",
    listOfItems: "List Of Items",
    from: "From",
    to: "To",
    amount: "Amount",
    filters: "Filters",
    resetFilter: "Reset filter",
    fromInvoiceDate:"From invoice date",
    toInvoiceDate:"To invoice date",
    fromPaymentDate:"From payment date",
    toPaymentDate:"To payment date",
    focused: "Focused",
    reset: "Reset",
    execute: 'Execute',
    originalAmount: "Amount",
    cumulativeAmount: "Cumulative Amount",
    creditInvoiceId: "Credit Invoice Id",
    creditInvoiceGrandTotal: "Grand Total",
    creditInvoiceTitle: "Credit Invoice Title",
    creditInvoiceTotal: "Credit Invoice Total",
    itemGroup: "Item group",
    withPrice: "Total per customer",
    type: "Type",
    Invoice: "Invoice",
    CreditInvoice: "CreditInvoice",
    excel: "Excel",
    allCumulitiveTotal: "Cumulitive Total",
    alloriginalAmountTotal: "Customer Total"
  }