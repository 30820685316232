export default {
    activityLog: 'יומן פעילות',
    loginLog: 'התחברות יומן',
    activityLogList: 'יומני פעילות משתמש',
    log: 'יומני',
    logType: "{type}",
    save: "שמור",
    id: "קוד",
    action: "פעילות",
    no: "מספר",
    from: "מתאריך",
    to: "עד היום",
    activityLogDetailsModal: {
        header: "יומן פרטי פעולה",
        previous: "פרטים ישנים",
        new: "פרטים חדשים",
        property: "שם הנכס",
        user: 'מִשׁתַמֵשׁ',
        date: 'תַאֲרִיך',
    },
    field: {
        logName: "שם יומן",
        description: "תיאור",
        subjectType: "סוג נושא",
        subjectId: 'מזהה נושא',
        userId: "תעודת זהות של משתמש",
        date: 'תַאֲרִיך',
    },
}