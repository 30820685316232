export default {
    id: "קוד",
    bookKeeping: "הנהלת חשבונות",
    income: "הכנסות",
    settings: "הגדרות",
    incomeAction: "הכנסות פעילות",
    description: "תאור",
    actions: "פעילות",
    options: "אפשרויות",
    addIncome: "הוסף הכנסה",
    copy: "העתק וצור חדש",
    activityLog: "יומן פעילות",
    accountNumber: ".מספר חשבון",
    vatExemptedIncome: 'פטורות מע"מ',
    overseasIncome: 'מע"מ עסקאות',
    vat: 'מע"מ',
    customerWithHoldingTax: "מס במקור",
    incomeList: "רשימת הכנסה",
    save: "שמור",
    relativeNumber: "מספרים יחסיים",
    balanceSheetSection: "סעיפי מאזן בוחן",
    grossProfit: "רווח גולמי",
    message: {
        bookkeepingSuccessfullyDeleted: "הנהלת הכנסות נמחקה בהצלחה",
        bookkeepingSuccessfullyAdded: "הנהלת הכנסות נוספה בהצלחה",
        bookkeepingSuccessfullyEdited: "הנהלת הכנסות נערכה בהצלחה",
        complusoryNumberSuccessfullyAdded: "מספר חובה נוסף בהצלחה",
        complusoryNumberSuccessfullyEdited: "מספר חובה נערך בהצלחה"
    },
};