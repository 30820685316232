export default {
  testByBina: 'Test Software By BINA',
  companyAddress: "ת.ד. 274 גבעת שמואל מיקוד 5410102",
  fax: "פקס",
  tel: "טלפון.",
  vatRegNo: "עוסק מורשה מספר.",
  receiptNo: "קבלה מספר",
  To: "לכבוד",
  att: "לידי",
  date: "תאריך",
  ourNumber: "מספר לקוח",
  page: "עמוד",
  of: "מתוך",
  no: "מס'",
  total: 'סה"כ',
  withRegards: "בברכה",
  generatePDF: "צור PDF",
  previewPDF: "PDF תצוגה מקדימה",
  paymentType: "סוג תשלום",
  chequeNumber: "מספר המחאה/אישור",
  bankNo: "בנק/חברת אשראי",
  accountNo: "מספר חשבון/כרטיס",
  branch: "סניף/תוקף",
  amount: "סכום",
  paymentDate:"תאריך תשלום",
  original: "מקור",
  copy: "עותק",
  cancelled: "מבוטל",
  invoices: "עבור חשבוניות",
  message: {
    generatingPdf: "PDF יוצר",
  },
  field: {
    total: 'סה"כ',
    discount: "מס במקור",
    grandTotal: 'סה"כ סופי',
  }
}