export default {
  welcomeToDashboard: "Welcome to the admin dashboard!",
  welcomeToBina: "Welcome to Bina!",
  receipts: 'Receipts',
  quotations: 'Quotations',
  invoices: 'Invoices',
  orders: 'Orders',
  sumOfAllQuotations: 'Sum of all quotations',
  sumOfClosedQuotations: 'Sum of closed quotations',
  sumOfCancelledQuotations: 'Sum of cancelled quotations',
  sumOfAllOrders: 'Sum of all orders',
  sumOfClosedOrders: 'Sum of closed orders',
  sumOfAllInvoices: 'Sum of all invoices',
  sumOfInvoicesWithUnpaidBalance: 'Sum of invoices with unpaid balance',
  sumOfAllReceipts: 'Sum of all receipts',
  barChart: 'Bar chart'
}