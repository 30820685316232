export default {
    id: "קוד",
    miscellaneous: "שונות",
    save: "שמור",
    relativeNumber: "מספרים יחסיים",
    vatDebitCredit: 'מע"מ חו"ז',
    balanceCancelation: "ביטול יתרות",
    employeePrefix: "קידומת עובד",
    customerPrefix: "קידומת לקוח",
    supplierPrefix: "קידומת ספק",
    referenceDateLimitFrom: "הגבלת תאריך אסמכתא מ",
    referenceDateLimitUntill: "הגבלת תאריך אסמכתא עד",
    referenceTwoDateLimitFrom: "הגבלת תאריך ערך מ",
    referenceTwoDateLimitUntill: "הגבלת תאריך ערך עד",
    bankNotesDateLimitFrom: "הגבלת תאריך דפי בנק מ",
    bankNotesDateLimitUntill: "הגבלת תאריך דפי בנק עד",
    basicNumbers: 'מספרים בסיסיים',
    prefixNumbers: "מספרי קידומת",
    referenceDates: 'תאריכי התייחסות'
};