export default {
  company: "חברה",
  companyActions: "פעילות חברה",
  editCompany: "ערוך חברה",
  viewCompany: "הצג פרטים",
  addCompany: "הוסף חברה",
  addCompanies: "הוסף חברות",
  companyName: "שם החברה",
  companyHeName: "שם עברי",
  companyUrl: "אתר החברה",
  save: "שמור",
  companiesList: "רשימת חברותt",
  id: "קוד",
  action: "פעולה",
  basicDetails: "מידע בסיסי",
  otherDetails: "מידע נוסף",
  displayText: "הצג טקסט",
  printingAndPricing: "הדפסה ותמחור",
  conditionsAndRestrictions: "התניות והגבלות",
  companyLogo: "לוגו חברה",
  message: {
    companySuccessfullyDeleted: "החברה נמחקה בהצלחה",
    companySuccessfullyAdded: "החברה נוספה בהצלחה",
    companySuccessfullyEdited: "החברה נערכה בהצלחה",
  },
  field: {
    grandTotalRequested: "סך הכל התבקש",
    calendarEmail: "אימייל של לוח שנה",
    phones: "טלפונים",
    email: "אי מייל",
    password: "סיסמה",
    addressLine1: "כתובת 1",
    addressLine2: "כתובת 2",
    addressLine3: "כתובת 3",
    authorizedDealerNo: "מספר עוסק מורשה",
    decimalPoints: "נקודה עשרונית מס'",
    occasionalCustomerNo: "מספר לקוח מזדמן",
    vatRegNo: "עוסק מורשה מספר.",
    totalRounding: "עיגול מלא",
    printingaManagerName: "הדפס שם מנהל",
    bestRegards: "בברכה",
    activeManager: "מנהל פעיל",
    vatPrecentage: 'אחוז מע"מ',
    textAttheBeginningOfaProposal: "מלל בתחילת הצעת מחיר",
    textAttheEndOfProposal: "מלל בסוף הצעת מחיר",
    textAttheBeginningoforder: "מלל בתחילת הזמנה",
    textAttheEndoforder: "מלל בסוף הזמנה",
    textForDueDate: "טקסט לתאריך תשלום",
    textAtTheBeginningOfTheDelivery: "מלל בתחילת תעודת משלוח",
    textAtTheEndOfADeliveryNote: "מלל בסוף תעודת משלוח",
    textAtTheBeginningOfAnInvoice: "מלל בתחילת חשבונית",
    textAtTheEndOfAnInvoice: "מלל בסוף חשבונית",
    beginningReceiptText: "מלל בתחילת קבלה",
    endReceiptText: "מלל בסוף קבלה",
    printingDueDate: "הדפסת תאריך תשלום",
    balancePrintingOnInvoice: "הדפסת יתרה בחשבונית",
    topLogo: "לוגו עליון",
    bottomLogo: "לוגו תחתון",
    aTitleIsRequired: "כותרת חובה",
    dateFormatLabel: "פורמט תאריך ברירת המחדל צריך dd-mm-yyyy",
    dateFormatHint: "פורמט התאריך הוא yyyy-mm-dd",
    bookkeepingNumberIsRequired: 'מספר הנה"ח חובה',
    documentIncludingVat: 'מסמך כולל מע"מ',
    totalIncludingVAT: 'סך הכל כולל מע"מ',
    warehouseInveManage: "ניהול מלאי מחסנים",
    warehouseCompulsory: "מחסן חובה",
    decimalPoint: "נקודות עשרוניות",
  },
};
