export default {
  supplierDeliveryNoteList: "Supplier Delivery Note List",
  deliveryDate: "Delivery Date",
  year: "Year", 
  receivedBy: "Received By",
  invoiceNo: "Invoice No.",
  invoiceYear: "Invoice Year",
  includingVat: "Including VAT",
  purchaseOrderId: "Purchase Order Id",
  supplierDeliveryNoteItems: "Supplier Delivery Note Items",
  supplierDeliveryNoteAction: "Supplier Delivery Note Action",
  supplierDeliveryNoteDetail: "Supplier Delivery Note Detail",
  purchaseOrderNo: "Purchase Order No.",
  deliveryNoteNo: "Delivery Note No.",
  draft: "Draft",
  message: {
    supplierDeliveryNoteSuccessfullyDeleted: "Supplier Delivery Note cancelled successfully",
    supplierDeliveryNoteSuccessfullyAdded: "Supplier Delivery Note added successfully",
    supplierDeliveryNoteSuccessfullyEdited: "Supplier Delivery Note edited successfully",
    deliveryNoAlreadyUsed: "Delivery Note No. is already used",
  },
};
