export default {
    order: 'הזמנה',
    orderActions: "אפשרויות הזמנה",
    editOrder: "ערוך הזמנה",
    addOrder: "הוסף הזמנה",
    save:"שמור",
    orderList: "רשימת הזמנות",
    id: "קוד",
    action: "פעילות",
    no: "מס' שורה",
    copy: "העתק וצור חדש",
    product: "פריט",
    orderItem: 'פריט הזמנה',
    quantity: "כמות",
    unitPrice: "מחיר יחידה",
    lineDiscount: "הנחה",
    itemDescription: "תאור פריט",
    total: 'סה"כ',
    orderDetail: "מידע הזמנה",
    customerDetail: "מידע לקוח",
    additionalCustomerDetail: "פרטי לקוח נוספים",
    listOfItems: "רשימת פריטים",
    from: "מ",
    to: "עד",
    createdelivery: "שמור וצור משלוח",
    saveAndCreate: "שמור וצור",
    resetFilter: "אפס מסנן",
    filters: "מסננים",
    execute: "בצע",
    reset: "אתחול",
    fieldView: "תצוגת שדה",
    debitReport: "דוח הזמנה",
    selectFields: "בחר שדות",
    confirm: "אשר",
    cancel: "לְבַטֵל",
    selectAll: "בחר הכל",
    excel: "Excel",
    update: "עִדכּוּן",
    customerGroup: "קבוצה של לקוחות",
    message: {
      orderSuccessfullyDeleted: "הזמנה נמחק בהצלחה",
      orderSuccessfullyAdded: "נוספה הזמנה",
      orderSuccessfullyEdited: "הזמנה נערך בהצלחה",
    },
    field: {
      customer: "לקוח",
      customerName: "שם לקוח",
      orderStartDate: "תאריך הזמנה",
      orderEndDate: "תאריך אספקה",
      orderTime: "זמן הזמנה",
      customerOrderNo: "הזמנת לקוח",
      quotation: "הצעת מחיר",
      title: "כותרת ההזמנה",
      address: "כתובת לקוח",
      town: "עיר",
      country: "מדינה",
      tel1: "טל. נוסף 1",
      tel2: "טל. נוסף 2",
      cel: "נוסף סלולרי",
      fax: "נוסף פקס",
      email: "אי מייל",
      authorizedDealerNo: "מספר עוסק מורשה",
      contact: "איש קשר",
      agent: "סוכן",
      remarks: "הערות",
      timePrinted: "שעת הדפסה",
      orderNo: "מספר הזמנה",
      invoiceNo: "מספר חשבונית",
      invoiceYear: "שנת חשבונית",
      status: "מצב הזמנה",
      total: 'סה"כ',
      discountPrecentage: "אחוז הנחה",
      discount: "הנחה",
      afterDiscount: "לאחר הנחה",
      vat: 'מע"מ',
      vatPrecentage: 'אחוז מע"מ',
      grandTotal: 'סה"כ סופי',
      all: "כולם",
      open: "פתוח",
      finished: "סגור",
      canceled: "מבוטל",
      deliveryNo: "מס' משלוח",
      contactId: "זיהוי איש קשר",
      customerId: "מספר לקוח",
      employeeId: "כרטיס עובד",
      quotationId: "זיהוי הצעת מחיר",
      proformaDeliveryNoteId: "זיהוי תעודת משלוח פרופורמה",
      deliveryNoteId: "מזהה תעודת משלוח",
      createdAt: "נוצר ב",
      updatedAt: "מעודכן ב",
      ordersStatus: "סטטוס הזמנות",
      docNo: "מספר מסמך",
      docDate: "תאריך מסמך",
      docTitle: "כותרת מסמך",
    }
  }