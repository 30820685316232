export default {
  supplier: 'ספק',
  supplierActions: "פעולות ספק",
  editSupplier: "ערוך ספק",
  addSupplier: "הוסף ספק",
  supplierName: "שם ספק",
  supplierName2: "שם הספק 2",
  save:"שמור",
  saveAndContinue: "שמור והמשך",
  suppliersList: "רשימת ספקים",
  id: "קוד",
  action: "פעילות",
  group: "קבוצה",
  basicDetails: "מידע בסיסי",
  otherDetails: "מידע נוסף",
  notes: "הערות",
  displayText: "תקשורת",
  communication: "תקשורת",
  extension: "שלוחה",
  no: "מספר",
  areaCode: "קידומת",
  country: "מדינה",
  tel: "טלפון",
  cel: "סלולר",
  fax: "פקס",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח ספק",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  message: {
    supplierSuccessfullyDeleted: "ספק נמחק בהצלחה",
    supplierSuccessfullyAdded: "ספק נוסף בהצלחה",
    supplierSuccessfullyEdited: "ספק נערך בהצלחה",
  },
  field: {
    termsOfPayment: "תנאי תשלום",
    group: "קבוצה ספק",
    area: "אזור",
    status: "סטטוס",
    address1: "כתובת",
    pobox1: ".ת.ד",
    postalCode1: "מיקוד",
    town1: "עיר",
    address2: "כתובת 2",
    pobox2: "ת.ד. 2",
    postalCode2: "מיקוד 2",
    town2: "עיר 2",
    state: "מדינה",
    country: "ארץ",
    supplierOpeningDate: "תאריך פתיחת ספק",
    supplierEndDate: "תאריך סיום ספק",
    email: "אי מייל",
    site: "אתר",
    authorizedDealerNo: "מספר עוסק מורשה",
    bookKipingNo: 'מספר הנה"ח',
    warningNote: "הערת התראה",
    generalRemarks: "הערות כלליות",
    countryPrefix0: "קידומת מדינה",
    dialingArea0: "קידומת",
    phones0: "טלפון",
    extension0: "שלוחה",
    countryPrefix1: "2 קידומת מדינה ",
    dialingArea1: "קידומת 2",
    phones1: "טלפון 2",
    extension1: "2 שלוחה",
    countryPrefix2: "3 קידומת מדינה",
    dialingArea2: "קידומת 3",
    phones2: "טלפון 3",
    extension2: "3 שלוחה",
    countryPrefix3: "4 קידומת מדינה",
    dialingArea3: "קידומת 4",
    cellular1: "סלולר 1",
    cellular2: "סלולר 2",
    cellular3: "3 סלולר",
    countryPrefix4: "5 קידומת מדינה",
    dialingArea4: "קידומת 5",
    cellular4: "4 סלולר",
    countryPrefix5: "6 קידומת מדינה",
    dialingArea5: "קידומת 6",
    cellular5: "5 סלולר",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
  }
}