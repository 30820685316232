import Vue from 'vue';
const trialBalance = {
    state: {
        trialBalanceList: [],
        trialBalanceQuery: null,
        accountCardDataList: []
    },
    getters: {
        trialBalanceList: (state) => state.trialBalanceList,
        trialBalanceQuery: (state) => state.trialBalanceQuery,
        accountCardDataList: (state) => state.accountCardDataList
    },
    mutations: {
        SET_TRIALBALANCE_QUERY: (state, payload) => {
            state.trialBalanceQuery = payload;
        },
        SET_TRIALBALANCE: (state, payload) => {
            state.trialBalanceList = payload;
        },
        SET_ACCOUNT_CARD_DATA: (state, payload) => {
            state.accountCardDataList = payload;
        }
    },
    actions: {
        GetTrialBalanceReport: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.trialBalance
                        .getTrialBalanceReport(params)
                        .then(({ data }) => {
                            context.commit("SET_TRIALBALANCE", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        },
        GetAccountCardData: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.trialBalance
                        .getAccountCardData(params)
                        .then(({ data }) => {
                            context.commit("SET_ACCOUNT_CARD_DATA", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        },
    },
    namespaced: true,
}
export default trialBalance;