import Vue from 'vue';
const bank = {
    state: {
      bankList: [],
      bankById: {},
      bankQuery: null,
      bankReportList: [],
      bankReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      bankList: (state) => state.bankList,
      bankById: (state) => state.bankById,
      bankQuery: (state) => state.bankQuery,
      bankReportList: (state) => state.bankReportList,
      bankReportQuery: (state) => state.bankReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_BANK_QUERY: (state, payload) => {
        state.bankQuery = payload;
      },
      SET_BANK: (state, payload) => {
        state.bankList = payload;
      },
      SET_BANK_BY_ID: (state, payload) => {
        if(payload) {
          state.bankById = payload;
        } else {
          state.bankById = {};
        }
      },
      SET_BANK_REPORT_QUERY: (state, payload) => {
        state.bankReportQuery = payload;
      },
      SET_BANK_REPORT: (state, payload) => {
        state.bankReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetBank: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.bank
              .getBank(params)
              .then(({ data }) => {
                context.commit("SET_BANK", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      GetBankReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.bank
              .getBank(params)
              .then(({ data }) => {
                context.commit("SET_BANK_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetBank: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.bank
            .addBank(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'bank.message.bankSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditBank: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.bank
            .editBank(payload.bank_id, payload.bank)
            .then((res) => {
              let successData = Object.assign({ success: 'bank.message.bankSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetBankById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.bank
              .getBankById(payload)
              .then(({ data }) => {
                context.commit('SET_BANK_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      DeleteBankItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.bank
            .deleteBank(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'bank.message.bankSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetBank', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default bank;