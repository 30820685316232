export default {
  supplierInvoiceList: "רשימת חשבוניות ספקים",
  invoiceDate: "תאריך",
  vatDate: 'תאריך מע"מ',
  deliveryNo: "ת.מ. מספר",
  deliveryYear: "שנת ת.מ.",
  supplierInvoiceAction: "פעולת חשבונית ספק",
  supplierInvoiceDetail: "פרטי חשבונית ספק",
  supplierInvoiceItems: "פריטי חשבונית ספק",
  invoiceNo: "מס' חשבונית",
  supplierNumber: "מספר ספק",
  totalPerSupplier: "סך הכל לספק",
  supplierTotal: 'סה"כ ספק',
  SupplierInvoice: "חשבונית ספק",
  SupplierCreditInvoice: "חשבונית זיכוי ספק",
  creditInvoiceNo: "מס' חשבונית אשראי",
  supplierInvoiceNo: "מס' חשבונית ספק",
  supplierDeliveryNoteNo: "מספר ת.מ.",
  SupplierCreditInvoiceNo: "מס' חשבונית אשראי ספק.",
  amount: "סכום",
  message: {
    supplierInvoiceSuccessfullyDeleted: "חשבונית הספק בוטלה בהצלחה",
    supplierInvoiceSuccessfullyAdded: "חשבונית ספק נוספה בהצלחה",
    supplierInvoiceSuccessfullyEdited: "חשבונית הספק נערך בהצלחה",
    invoiceNoAlreadyUsed: "חשבונית מס. כבר בשימוש",
    creditInvoiceNoAlreadyUsed: "חשבונית אשראי מס. כבר בשימוש",
  },
};
