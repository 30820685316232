import Vue from 'vue';
const receipt = {
    state: {
      receiptList: [],
      receiptById: {},
      receiptQuery: null,
      receiptReportList: [],
      receiptReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      receiptList: (state) => state.receiptList,
      receiptById: (state) => state.receiptById,
      receiptQuery: (state) => state.receiptQuery,
      receiptReportList: (state) => state.receiptReportList,
      receiptReportQuery: (state) => state.receiptReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_RECEIPT_QUERY: (state, payload) => {
        state.receiptQuery = payload;
      },
      SET_RECEIPT: (state, payload) => {
        state.receiptList = payload;
      },
      SET_RECEIPT_BY_ID: (state, payload) => {
        if(payload) {
          state.receiptById = payload;
        } else {
          state.receiptById = {};
        }
      },
      SET_RECEIPT_REPORT_QUERY: (state, payload) => {
        state.receiptReportQuery = payload;
      },
      SET_RECEIPT_REPORT: (state, payload) => {
        state.receiptReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetReceipt: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.receipt
              .getReceipt(params)
              .then(({ data }) => {
                context.commit("SET_RECEIPT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetChartReceipt: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.receipt
              .getChartReceipt(params)
              .then(({ data }) => {
                context.commit("SET_RECEIPT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetReceiptReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.receiptSalesReport
              .getReceiptSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_RECEIPT_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetReceipt: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receipt
            .addReceipt(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'receipt.message.receiptSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditReceipt: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receipt
            .editReceipt(payload.receipt_id, payload.receipt)
            .then((res) => {
              if (res.data.abc && res.data.abc.type === 'confirmation') {
                context.commit('SET_BOOKKEEPING_CONFIRMATION', res.data, {root: true});
              } else {
                let successData = Object.assign({ success: 'receipt.message.receiptSuccessfullyEdited'})
                context.dispatch('SetSnackBar', successData, {root: true});
                resolve(res);
              }
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetReceiptById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.receipt
              .getReceiptById(payload)
              .then(({ data }) => {
                context.commit('SET_RECEIPT_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteReceiptItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receipt
            .deleteReceipt(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'receipt.message.receiptSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetReceipt', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default receipt;