export default {
    id: "קוד",
    banks: "בנק",
    description: "תאור",
    accountNumber: ".מספר חשבון",
    banksActions: "אפשרויות בנק",
    actions: "פעילות",
    options: "אפשרויות",
    copy: "העתק וצור חדש",
    activityLog: "יומן פעילות",
    save: "שמור",
    addBanks: "הוסף בנק",
    banksList: "רשימת בנקים",
    message: {
        banksSuccessfullyDeleted: "בנק נמחק בהצלחה",
        banksSuccessfullyAdded: "בנק נוסף בהצלחה",
        banksSuccessfullyEdited: "בנק נערך בהצלחה",
    },
}