import Vue from 'vue';
const order = {
    state: {
      orderList: [],
      orderById: {},
      orderQuery: null,
      orderReportList: [],
      orderReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      orderList: (state) => state.orderList,
      orderById: (state) => state.orderById,
      orderQuery: (state) => state.orderQuery,
      orderReportList: (state) => state.orderReportList,
      orderReportQuery: (state) => state.orderReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_ORDER_QUERY: (state, payload) => {
        state.orderQuery = payload;
      },
      SET_ORDER: (state, payload) => {
        state.orderList = payload;
      },
      SET_ORDER_BY_ID: (state, payload) => {
        if(payload) {
          state.orderById = payload;
        } else {
          state.orderById = {};
        }
      },
      SET_ORDER_REPORT_QUERY: (state, payload) => {
        state.orderReportQuery = payload;
      },
      SET_ORDER_REPORT: (state, payload) => {
        state.orderReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetOrder: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.order
              .getOrder(params)
              .then(({ data }) => {
                context.commit("SET_ORDER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getorder', err);
        }
      },
      GetChartOrder: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.order
              .getChartOrder(params)
              .then(({ data }) => {
                context.commit("SET_ORDER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getquotation', err);
        }
      },
      GetOrderReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_ORDER_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetOrder: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.order
            .addOrder(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'order.message.orderSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditOrder: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.order
            .editOrder(payload.order_id, payload.order)
            .then((res) => {
              let successData = Object.assign({ success: 'order.message.orderSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetOrderById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.order
              .getOrderById(payload)
              .then(({ data }) => {
                context.commit('SET_ORDER_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetOrder', err);
        }
      },
      DeleteOrderItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.order
            .deleteOrder(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'order.message.orderSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetOrder', {page: 1, limit: 10, where_and: 'status|'+0});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default order;