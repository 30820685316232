export default {
    id: "קוד",
    funds: "כספים",
    description: "תאור",
    accountNumber: ".מספר חשבון",
    fundsAction: "פעולות מימון",
    creditCard: "כרטיס אשראי",
    actions: "פעילות",
    options: "אפשרויות",
    copy: "העתק וצור חדש",
    activityLog: "יומן פעילות",
    save: "שמור",
    addFunds: "הוסף כספים",
    fundsList: "רשימת קרנות",
    relativeNumber: "מספרים יחסיים",
    cash: "כסף מזומן",
    checks: "צ'קים",
    message: {
        fundsSuccessfullyDeleted: "הכספים בוטלה בהצלחה",
        fundsSuccessfullyAdded: "כספים נוספה בהצלחה",
        fundsSuccessfullyEdited: "הכספים נערכה בהצלחה",
    },
}