import Vue from 'vue';

const bookkeeping_banks = {
    state: {
        banksList: [],
        banksById: {},
        banksQuery: null
    },
    getters: {
        banksList: (state) => state.banksList,
        banksById: (state) => state.banksById,
        banksQuery: (state) => state.banksQuery
    },
    mutations: {
        SET_BANKS_QUERY: (state, payload) => {
            state.banksQuery = payload
        },
        SET_BANKS: (state, payload) => {
            state.banksList = payload
        },
        SET_BANKS_BY_ID: (state, payload) => {
            if (payload) {
                state.banksById = payload
            } else {
                state.banksById = {}
            }
        }
    },
    actions: {
        GetBanks: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.bookkeeping
                        .getIncome(params)
                        .then(({ data }) => {
                            context.commit("SET_BANKS", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetBanks', err);
            }
        },
        SetBanks: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .addIncome(payload)
                    .then((res) => {
                        if (res.data.type === "success" || res.data.type === "confirmation") {
                            resolve(res);
                        } else {
                            let successData = Object.assign({ success: 'bookkeepingBank.message.banksSuccessfullyAdded' })
                            context.dispatch('SetSnackBar', successData, { root: true });
                            resolve(res);
                        }
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
        EditBanks: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .editIncome(payload.banks_id, payload.banks)
                    .then((res) => {
                        if (res.data.type === "success" || res.data.type === "confirmation") {
                            resolve(res);
                        } else {
                            let successData = Object.assign({ success: 'bookkeepingBank.message.banksSuccessfullyEdited' })
                            context.dispatch('SetSnackBar', successData, { root: true })
                            resolve(res);
                        }
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    })
            })
        },
        GetBanksById: async (context, payload) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.bookkeeping
                        .getIncomeById(payload)
                        .then(({ data }) => {
                            context.commit('SET_BANKS_BY_ID', data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetBanks', err);
            }
        },
        DeleteBanksById: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .deleteIncome(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'bookkeepingBank.message.banksSuccessfullyDeleted' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
    },
    namespaced: true,
}

export default bookkeeping_banks