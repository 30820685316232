export default {
  role: "Role",
  rolesActions: "Roles Actions",
  editRole: "Edit Role",
  addRole: "Add Role",
  addRoles: "Add Roles",
  roleName: "Role Name",
  roleHeName: "Hebrew Name",
  description: "Description",
  save: "Save",
  rolesList: "Roles List",
  id: "ID",
  action: "Action",
  rolePermissions: "Role Permissions",
  create: "Create",
  show: "Show",
  edit: "Edit",
  delete: "Delete",
  financial: "Financial",
  bookkeeping: "Bookkeeping",
  Employee: "Employee",
  message: {
    roleSuccessfullyDeleted: "Role cancelled successfully",
    roleSuccessfullyAdded: "Role added successfully",
    roleSuccessfullyEdited: "Role edited successfully",
  }
}