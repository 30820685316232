export default {
  testByBina: 'Test Software By BINA',
  companyAddress: "POBox 274 Givat Shmuel 5410102 Israel",
  fax: "Fax",
  tel: "Tel.",
  vatRegNo: "VAT Reg. No.",
  depositNo: 'Deposit no.',
  To: "To",
  page: "Page",
  of: "Of",
  no: "No",
  withRegards: "With Regards",
  generatePDF: "Generate PDF",
  previewPDF: "Preview PDF",
  type: "Type",
  bank: 'Bank',
  depositToBank: 'Deposit to Bank',
  paymentType: "Payment Type",
  chequeNumber: "Cheque No",
  amount: "Amount",
  paymentDate: "Payment Date",
  depositDate:"Deposit Date",
  customerName: "Customer Name",
  agent: "Employee",
  original: "Original",
  copy: "Copy",
  cancelled: "Cancelled",
  message: {
    generatingPdf: "Generating PDF",
  }
}