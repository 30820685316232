export default {
  bank: 'Bank',
  bankActions: "Bank Actions",
  editBank: "Edit Bank",
  addBank: "Add Bank",
  save: "Save",
  bankList: "Bank List",
  id: "ID",
  action: "Action",
  basicDetails: "Basic Details",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Bank Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  message: {
    bankSuccessfullyDeleted: "Bank cancelled successfully",
    bankSuccessfullyAdded: "Bank added successfully",
    bankSuccessfullyEdited: "Bank edited successfully",
  },
  field: {
    name: "Name",
    address: "Address",
    town: "Town",
    postalCode: "Postal Code",
    branchNo: "Branch no",
    branchName: "Branch name",
    accountNo: "Account no",
    tel1: "Tel 1",
    tel2: "Tel 2",
    tel3: "Tel 3",
    fax: "Fax",
    email: "Email",
    site: "Site",
    contactName: "Contact's name",
    accountingNo: "Accounting no",
    createdAt: "Created At",
    updatedAt: "Updated At",
    no: "Account No.",
    addressTown: "Address/Town"
  }
}