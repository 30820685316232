import route from "./route";
import login from "./login";
import singup from "./singup";
import dashboard from "./dashboard";
import roles from "./roles";
import company from "./company";
import employee from "./employee";
import customer from "./customer";
import contact from "./contact";
import auxiliaryTable from "./auxiliaryTable";
import activityLog from "./activityLog";
import settings from "./settings";
import formRules from "./formRules";
import product from "./product";
import supplier from "./supplier";
import quotation from "./quotation";
import quotationPdf from "./quotationPdf";
import order from "./order";
import orderPdf from "./orderPdf";
import delivery from "./delivery";
import deliveryPdf from "./deliveryPdf";
import invoice from "./invoice";
import invoicePdf from "./invoicePdf";
import receipt from "./receipt";
import receiptPdf from "./receiptPdf";
import bank from "./bank";
import deposit from "./deposit";
import depositPdf from "./depositPdf";
import creditInvoice from "./creditInvoice";
import creditInvoicePdf from "./creditInvoicePdf";
import dbQuery from "./dbQuery";
import proforma from "./proforma";
import proformaPdf from "./proformaPdf";
import remarks from "./remarks";
import debitReport from "./debitReport";
import bookkeeping from "./bookkeeping";
import expenses from "./expenses";
import funds from "./funds";
import bookkeepingBank from "./bookkeepingBank";
import other from "./other";
import miscellaneous from "./miscellaneous";
import bookkeepingRecords from "./bookkeepingRecords";
import salesReport from "./salesReport";
import accountingCardReport from "./accountingCardReport";
import receiptTax from "./receiptTax";
import trialBalance from "./trialBalance";
import news from "./news";
import documentSeries from "./documentSeries";
import calendar from "./calendar";
import returnCertificate from "./returnCertificate";
import journalEntry from "./journalEntry";
import purchaseOrder from "./purchaseOrder";
import supplierDeliveryNote from "./supplierDeliveryNote";
import supplierInvoice from "./supplierInvoice";
import supplierCreditInvoice from "./supplierCreditInvoice";
import supplierPayment from "./supplierPayment";
import cashFlow from "./cashFlow";
import generalEvaluation from "./generalEvaluation";

export default {
  route,
  login,
  singup,
  dashboard,
  roles,
  company,
  employee,
  customer,
  contact,
  auxiliaryTable,
  activityLog,
  settings,
  formRules,
  product,
  supplier,
  quotation,
  quotationPdf,
  order,
  orderPdf,
  delivery,
  deliveryPdf,
  invoice,
  invoicePdf,
  receipt,
  bank,
  receiptPdf,
  deposit,
  depositPdf,
  creditInvoice,
  creditInvoicePdf,
  dbQuery,
  proforma,
  proformaPdf,
  remarks,
  debitReport,
  bookkeeping,
  expenses,
  funds,
  bookkeepingBank,
  other,
  miscellaneous,
  bookkeepingRecords,
  salesReport,
  accountingCardReport,
  receiptTax,
  trialBalance,
  news,
  documentSeries,
  calendar,
  returnCertificate,
  journalEntry,
  purchaseOrder,
  supplierDeliveryNote,
  supplierInvoice,
  supplierCreditInvoice,
  supplierPayment,
  cashFlow,
  generalEvaluation,
  toolbar: {
    appname: "Application",
    settings: "Account Settings",
    companySetting: "Company Settings",
    profile: "Profile",
    logout: "Logout",
  },
  common: {
    edit: "Edit",
    delete: "Cancel",
    view: "View",
    employee: "Employee",
    less: "less",
    greater: "greater",
    numberOfTotalRows: "Number of total rows:",
    search: "Search",
    noDataFound: "No data found",
    loading: "Loading...",
    doYouWant: "Do you want to change the status of document to open or close?",
    pleaseClickOnBelowButton: "Please click on below status button.",
    closeStatus: "Close status",
    openStatus: "Open status",
    sendByMail: "Send by mail",
    selectContacts: "Select contacts",
    contactEmails: "Contact emails",
    otherEmails: "Other emails",
    sendEmail: "Send email",
    documentReadOnlyModeNotice:
      "This document/card is being used by {userId} and it is open for read only",
    byWholeInvoice: "By Whole Invoice",
    byTheUser: "By The User",
    pleaseClickOnBelowOptionButton: "Please click on below option button.",
    pleaseClickOnBelowOptionButton2:
      "Credit invoice can only be prepared when the balance is full.",
    ok: "OK",
    checkStatusInvoice:
      "Credit invoice can only be prepared when the invoice is closed",
    bookkeepingConfirmation:
      "You must enter a bookkeeping number on the customer card before saving the document.",
    copyAndCreateNew:
      "Which document you want to copy and create please select it from the following options",
    newDocCreated: "Document is created",
    documentVAT: "Document includes VAT",
    pdfTemplate: "PDF Template",
    createPdfTemplate: "Create PDF Template",
    fontSize: "Font Size",
    fontStyle: "Font Style",
    fontWeight: "Font Weight",
    textColor: "Text Color",
    textDecoration: "Text Decoration",
    templateName: "Template Name",
    previewTemplate: "Preview Template",
    chooseDyamicTemplate: "Please select a template for printing",
    selectTemplate: "Select Template",
    documentDiscount: "Document Discount",
    copyDocument: "Copy Document",
    suppliedMsg: "There are no items left to supply in this document.",
    suppliedQuantities: "Supplied Quantity",
    supplierBookkeepingConfirmation:
      "You must enter a bookkeeping number on the supplier card before saving the document.",
    supplierInvoiceCancel:
      "The document cannot be canceled, When a credit invoice, or a payment associated",
    purchaseOrderCancel:
      "The document cannot be canceled, When a delivery or invoice associated",
    supplierDeliveryNoteCancel:
      "The document cannot be canceled, When an invoice is associated",
    supplierPaymentCancel:
      "The document cannot be canceled,When invoice associated",
    details: "Details",
    daily: "Daily",
    monthly: "Monthly",
    supplierCreditInvoiceCancel:
      "The document cannot be canceled,When invoice associated",
  },
};
