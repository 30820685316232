export default {
  titleIn: 'התחברות',
  titleUn: 'טופס הרשמה',
  singIn: 'אישור',
  singUp: 'התחבר',
  email: 'אימייל',
  password: 'סיסמה',
  oldPassword: 'סיסמה ישנה',
  newPassword: 'סיסמה חדשה',
  confirmPassword: "אשר סיסמה",
  send: "שלח",
  rememberPassword: "?זכור סיסמה",
  emailHasBeenSent: "המייל נשלח",
  checkYourInbox: "אנא בדוק את תיבת הדואר הנכנס שלך ולחץ על הקישור שהתקבל כדי לאפס סיסמה",
  changePassword: '?לשנות סיסמה',
  forgotPassword: '?שכחת סיסמה',
  resetPassword: "לאפס את הסיסמה",
  confirm: "אישור",
  reset: "אתחול",
  loginWithCompany: "התחבר עם החברה שלך",
  pleaseSignIn: "אנא היכנס לחשבון שלך והתחל בהרפתקה",
  pleaseForgotPassword: 'הזן את הדוא"ל הרשום שלך למטה כדי לקבל הנחיות לאיפוס סיסמהn',
  welcomeTo: "ברוכים הבאים לבינה",
  newPlatform: "?חדש בפלטפורמה שלנו",
  createAccount: "צור חשבון",
  successFullylogin: "התחברת בהצלחה לבינה",
  passwordResetSuccessfully: "איפוס הסיסמה הצליחה",
  rememberMe: "זכור אותי",
  lastLoggedIn: "מחובר לאחרונה"
}