export default {
  supplierDeliveryNoteList: "תעודת משלוח ספק רשימה",
  deliveryDate: "תאריך ת. משלוח",
  year: "שנת עבודה",
  receivedBy: "מקבל",
  invoiceNo: "מספר חן ספק",
  invoiceYear: "שנת עבודה",
  includingVat: 'כולל מע"מ',
  purchaseOrderId: "מספר הזמנה",
  supplierDeliveryNoteItems: "פריטי תעודת משלוח ספק",
  supplierDeliveryNoteAction: "פעולה תעודת משלוח ספק",
  supplierDeliveryNoteDetail: "פירוט הערת משלוח של ספק",
  purchaseOrderNo: "מספר הזמנה",
  deliveryNoteNo: "תעודת משלוח מס'.",
  draft: "טיוטא",
  message: {
    supplierDeliveryNoteSuccessfullyDeleted: "תעודת משלוח ספק בוטלה בהצלחה",
    supplierDeliveryNoteSuccessfullyAdded: "תעודת משלוח ספק נוספה בהצלחה",
    supplierDeliveryNoteSuccessfullyEdited: "הערת המשלוח של הספק נערך בהצלחה",
    deliveryNoAlreadyUsed: "מספר תעודת משלוח כבר בשימוש"
  },
};
