import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import i18n from '@/locale/index';

Vue.use(VueRouter)

// function hasPermission(roles, permissionRoles) {
//   if (roles.filter((obj) => obj.name === 'admin').length > 0) return true;
//   if (!permissionRoles) return true;
//   return roles.some((role) => permissionRoles.includes(role.name));
// }
function hasPermission(roles, permissionRoles, actionRole, rolesPermission) {
  if (roles.slug === 'admin') return true;
  // if (roles.filter((obj) => obj.name === 'admin').length > 0) return true;
  // return roles.some((role) => permissionRoles.includes(role.name));
  // below line is use for restrict modules for open 
  // return permissionRoles.includes(roles.slug);
  if (permissionRoles && permissionRoles.length > 0 && store.state.user.user.role.slug){
    return permissionRoles.includes(store.state.user.user.role.slug)
  }
  if (actionRole && store.state.user.accessRight) return store.state.user.accessRight.includes(actionRole)
  if (!permissionRoles || !actionRole) return true;
  if (!rolesPermission) return true;
  return true;
}

const beforeEnter = (_to, _from, next) => {
  _to.meta.type = 'company'
  if (_to.meta.auth) {
    if (store.state.user.token) {
      if (_to.path === "/signin") {
        next({
          path: "/",
        });
      } else {
        if (hasPermission(store.state.user.user.role, _to.meta.accessRoles, _to.meta.actionRole, store.state.user.user.role.role_list)) {
          const rolesPermission = store.state.user.token && store.state.user.user.role.role_list ? store.state.user.user.role.role_list.split(',') : '';
          const routeTitle = _to.meta.title.split('.')[1];
          if (store.state.user.token && rolesPermission.includes(routeTitle) && routeTitle !== 'dashboard') {
            next('/')
          } else {
            next();
          }
        } else {
          next('/');
        }
      }
    } else {
      next("/signin");
    }
  } else {
    if (_to.path === "/signin") {
      if (!store.state.user.token) {
        next();
      } else {
        next({
          path: "/",
        });
      }
    } else {
      next();
    }
  }
};

export const routes = [
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '',
        component: () => import("@/views/companyApp/Dashboard/index.vue"),
        name: 'Dashboard',
        meta: {
          auth: true, title: 'route.dashboard', icon: 'mdi-monitor-dashboard', noCache: true, affix: true,
        },
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/settings',
        component: () => import("@/views/companyApp/settings/index.vue"),
        name: 'Settings',
        meta: {
          auth: true, title: 'route.companySetting', accessRoles: ['admin', 'manager'], noCache: true, affix: true, icon: 'mdi-account-cog-outline',
        }
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/auxiliary-table',
        component: () => import("@/views/companyApp/auxiliaryTable/index.vue"),
        name: 'AuxiliaryTable',
        noVisibleChildren: true,
        meta: {
          auth: true, title: 'route.auxiliaryTable', icon: 'mdi-table-column-width', noCache: true, affix: true
        }
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    name: "Accounts",
    redirect: '/role',
    meta: {
      auth: true, title: 'route.accounts', icon: 'mdi-briefcase-account-outline'
    },
    children: [
      {
        path: '/role',
        component: () => import("@/views/companyApp/roles/index.vue"),
        name: 'Roles',
        noVisibleChildren: true,
        meta: {
          padding: 2,auth: true, title: 'route.roles', icon: 'mdi-account-tie', noCache: true, affix: true,
        },
        children: [
          {
            path: 'roleAction/:id?',
            component: () => import("@/views/companyApp/roles/RolesAction.vue"),
            name: 'RolesAction',
            hidden: true,
            alias: ['editRole/:id'],
            meta: {
              auth: true, title: 'route.rolesAction', noCache: true, affix: true
            },
            beforeEnter: beforeEnter,
          },
          {
            path: 'addRole',
            component: () => import("@/views/companyApp/roles/RolesAction.vue"),
            name: 'AddRole',
            hidden: true,
            meta: {
              auth: true, title: 'route.rolesAction', noCache: true, affix: true, actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          // {
          //   path: 'roleAction/:id?',
          //   component: () => import("@/views/companyApp/roles/RolesAction.vue"),
          //   name: 'RolesAction',
          //   hidden: true,
          //   alias: ['editRole/:id', 'addRole'],
          //   meta: {
          //     auth: true, title: 'route.rolesAction', noCache: true, affix: true, actionRole: 'edit'
          //   },
          // },
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/employee/EmployeeStepView.vue"),
        name: "EmployeeStepView",
        redirect: "/employee",
        meta: {
          padding: 2, auth: true, title: 'route.employee', icon: 'mdi-account-multiple-outline'
        },
        children: [
          {
            path: '/addEmployee',
            component: () => import("@/views/companyApp/employee/EmployeeAction.vue"),
            name: 'addEmployee',
            meta: {
              padding: 4, auth: true, title: 'route.addEmployee', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/employee',
            component: () => import("@/views/companyApp/employee/index.vue"),
            name: 'Employee',
            meta: {
              padding: 4, auth: true, title: 'route.employeeCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'employeeAction/:employee_id?',
                component: () => import("@/views/companyApp/employee/EmployeeAction.vue"),
                name: 'EmployeeAction',
                hidden: true,
                alias: ['editEmployee/:employee_id'],
                meta: {
                  auth: true, title: 'route.employeeAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addEmployee',
                component: () => import("@/views/companyApp/employee/EmployeeAction.vue"),
                name: 'AddEmployee',
                hidden: true,
                meta: {
                  auth: true, title: 'route.employeeAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/employeeReport',
            component: () => import("@/views/companyApp/employee/EmployeeReport.vue"),
            name: 'EmployeeReport',
            meta: {
              padding: 4, auth: true, title: 'route.employeeReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          },
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/customer/CustomerStepView.vue"),
        name: "CustomerStepView",
        redirect: "/customer",
        meta: {
          padding: 2, auth: true, title: 'route.customer', icon: 'mdi-account-group-outline'
        },
        children: [
          {
            path: '/addCustomer',
            component: () => import("@/views/companyApp/customer/CustomerAction.vue"),
            name: 'addCustomer',
            meta: {
              padding: 4, auth: true, title: 'route.addCustomer', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/customer',
            component: () => import("@/views/companyApp/customer/index.vue"),
            name: 'Customer',
            meta: {
              padding: 4, auth: true, title: 'route.customerCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'customerAction/:customer_id?',
                component: () => import("@/views/companyApp/customer/CustomerAction.vue"),
                name: 'CustomerAction',
                hidden: true,
                alias: ['editCustomer/:customer_id'],
                meta: {
                  auth: true, title: 'route.customerAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addCustomer',
                component: () => import("@/views/companyApp/customer/CustomerAction.vue"),
                name: 'AddCustomer',
                hidden: true,
                meta: {
                  auth: true, title: 'route.customerAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
            beforeEnter: beforeEnter,
          },
          {
            path: '/customerReport',
            component: () => import("@/views/companyApp/customer/CustomerDebitReport.vue"),
            name: 'CustomerDebitReport',
            meta: {
              padding: 4, auth: true, title: 'route.customerReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          },
        ]
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/supplier/SupplierStepView.vue"),
        name: "SupplierStepView",
        redirect: "/supplier",
        meta: {
          padding: 2, auth: true, title: 'route.supplier', icon: 'mdi-account-badge-outline'
        },
        children: [
          {
            path: '/addSupplier',
            component: () => import("@/views/companyApp/supplier/SupplierAction.vue"),
            name: 'addSupplier',
            meta: {
              padding: 4, auth: true, title: 'route.addSupplier', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/supplier',
            component: () => import("@/views/companyApp/supplier/index.vue"),
            name: 'Supplier',
            meta: {
              padding: 4, auth: true, title: 'route.supplierCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'supplierAction/:supplier_id?',
                component: () => import("@/views/companyApp/supplier/SupplierAction.vue"),
                name: 'SupplierAction',
                hidden: true,
                alias: ['editSupplier/:supplier_id'],
                meta: {
                  auth: true, title: 'route.supplierAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addSupplier',
                component: () => import("@/views/companyApp/supplier/SupplierAction.vue"),
                name: 'AddSupplier',
                hidden: true,
                meta: {
                  auth: true, title: 'route.supplierAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/supplierReport',
            component: () => import("@/views/companyApp/supplier/SupplierReport.vue"),
            name: 'SupplierReport',
            meta: {
              padding: 4, auth: true, title: 'route.supplierReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/product/ProductStepView.vue"),
        name: "ProductStepView",
        redirect: "/item",
        meta: {
          padding: 2, auth: true, title: 'route.product', icon: 'mdi-cart-plus'
        },
        children: [
          {
            path: '/addItem',
            component: () => import("@/views/companyApp/product/ProductAction.vue"),
            name: 'addItem',
            meta: {
              padding: 4, auth: true, title: 'route.addItem', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/item',
            component: () => import("@/views/companyApp/product/index.vue"),
            name: 'Product',
            meta: {
              padding: 4, auth: true, title: 'route.productCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'itemAction/:product_id?',
                component: () => import("@/views/companyApp/product/ProductAction.vue"),
                name: 'ProductAction',
                hidden: true,
                alias: ['editItem/:product_id'],
                meta: {
                  auth: true, title: 'route.productAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addItem',
                component: () => import("@/views/companyApp/product/ProductAction.vue"),
                name: 'AddProduct',
                hidden: true,
                meta: {
                  auth: true, title: 'route.productAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/itemReport',
            component: () => import("@/views/companyApp/product/ProductReport.vue"),
            name: 'ProductReport',
            meta: {
              padding: 4, auth: true, title: 'route.productReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/bank/BankStepView.vue"),
        name: "BankStepView",
        redirect: "/bank",
        meta: {
          padding: 2, auth: true, title: 'route.bank', icon: 'mdi-bank'
        },
        children: [
          {
            path: '/addBank',
            component: () => import("@/views/companyApp/bank/BankAction.vue"),
            name: 'addBank',
            meta: {
              padding: 4, auth: true, title: 'route.addBank', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/bank',
            component: () => import("@/views/companyApp/bank/index.vue"),
            name: 'Bank',
            meta: {
              padding: 4, auth: true, title: 'route.bankCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'bankAction/:bank_id?',
                component: () => import("@/views/companyApp/bank/BankAction.vue"),
                name: 'BankAction',
                hidden: true,
                alias: ['editBank/:bank_id'],
                meta: {
                  auth: true, title: 'route.bankAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addBank',
                component: () => import("@/views/companyApp/bank/BankAction.vue"),
                name: 'AddBank',
                hidden: true,
                meta: {
                  auth: true, title: 'route.bankAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/bankReport',
            component: () => import("@/views/companyApp/bank/BankReport.vue"),
            name: 'BankReport',
            meta: {
              padding: 4, auth: true, title: 'route.bankReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          },
        ],
        beforeEnter: beforeEnter,
      },
    ],
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    name: "Sales",
    redirect: '/priceList',
    meta: {
      auth: true, title: 'route.orderManagement', icon: 'mdi-file-table-outline'
    },
    children: [
      {
        path: '/priceList',
        component: () => import("@/views/companyApp/priceList/index.vue"),
        name: 'PriceListTable',
        noVisibleChildren: true,
        meta: {
          padding: 2,auth: true, title: 'route.priceList', icon: 'mdi-alpha-p-box-outline', noCache: true, affix: true,
        },
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/quotation/QuotationStepView.vue"),
        name: "QuotationStepView",
        redirect: "/quotation",
        meta: {
          padding: 2, auth: true, title: 'route.quotation', icon: 'mdi-file-delimited-outline'
        },
        children: [
          {
            path: '/addQuotation',
            component: () => import("@/views/companyApp/quotation/QuotationAction.vue"),
            name: 'addQuotation',
            meta: {
              padding: 4, auth: true, title: 'route.addQuotation', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/quotation',
            component: () => import("@/views/companyApp/quotation/index.vue"),
            name: 'Quotation',
            meta: {
              padding: 4, auth: true, title: 'route.quotationCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'quotationAction/:quotation_id?',
                component: () => import("@/views/companyApp/quotation/QuotationAction.vue"),
                name: 'QuotationAction',
                hidden: true,
                alias: ['editQuotation/:quotation_id'],
                meta: {
                  auth: true, title: 'route.quotationAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addQuotation',
                component: () => import("@/views/companyApp/quotation/QuotationAction.vue"),
                name: 'AddQuotation',
                hidden: true,
                meta: {
                  auth: true, title: 'route.quotationAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ]
          },
          {
            path: '/quotationReport',
            component: () => import("@/views/companyApp/quotation/QuotationReport.vue"),
            name: 'QuotationReport',
            meta: {
              padding: 4, auth: true, title: 'route.quotationReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          },
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/order/OrderStepView.vue"),
        name: "OrderStepView",
        redirect: "/order",
        meta: {
          padding: 2, auth: true, title: 'route.order', icon: 'mdi-note-multiple-outline'
        },
        children: [
          {
            path: '/addOrder',
            component: () => import("@/views/companyApp/order/OrderAction.vue"),
            name: 'addOrder',
            meta: {
              padding: 4, auth: true, title: 'route.addOrder', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/order',
            component: () => import("@/views/companyApp/order/index.vue"),
            name: 'Order',
            meta: {
              padding: 4, auth: true, title: 'route.orderCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'orderAction/:order_id?',
                component: () => import("@/views/companyApp/order/OrderAction.vue"),
                name: 'OrderAction',
                hidden: true,
                alias: ['editOrder/:order_id'],
                meta: {
                  auth: true, title: 'route.orderAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addOrder',
                component: () => import("@/views/companyApp/order/OrderAction.vue"),
                name: 'AddOrder',
                hidden: true,
                meta: {
                  auth: true, title: 'route.orderAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/orderReport',
            component: () => import("@/views/companyApp/order/OrderReport.vue"),
            name: 'OrderReport',
            meta: {
              padding: 4, auth: true, title: 'route.orderReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          },
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/proforma/ProformaStepView.vue"),
        name: "ProformaStepView",
        redirect: "/proforma",
        meta: {
          padding: 2, auth: true, title: 'route.proforma', icon: 'mdi-file-document-alert-outline'
        },
        children: [
          {
            path: '/addProforma',
            component: () => import("@/views/companyApp/proforma/ProformaAction.vue"),
            name: 'addProforma',
            meta: {
              padding: 4, auth: true, title: 'route.addProforma', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/proforma',
            component: () => import("@/views/companyApp/proforma/index.vue"),
            name: 'Proforma',
            meta: {
              padding: 4, auth: true, title: 'route.proformaCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'proformaAction/:proforma_id?',
                component: () => import("@/views/companyApp/proforma/ProformaAction.vue"),
                name: 'ProformaAction',
                hidden: true,
                alias: ['editProforma/:proforma_id'],
                meta: {
                  auth: true, title: 'route.proformaAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addProforma',
                component: () => import("@/views/companyApp/proforma/ProformaAction.vue"),
                name: 'AddProforma',
                hidden: true,
                meta: {
                  auth: true, title: 'route.proformaAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/proformaReport',
            component: () => import("@/views/companyApp/proforma/ProformaReport.vue"),
            name: 'ProformaReport',
            meta: {
              padding: 4, auth: true, title: 'route.proformaReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ], 
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/delivery/DeliveryStepView.vue"),
        name: "DeliveryStepView",
        redirect: "/delivery",
        meta: {
          padding: 2, auth: true, title: 'route.delivery', icon: 'mdi-account-badge-outline'
        },
        children: [
          {
            path: '/addDelivery',
            component: () => import("@/views/companyApp/delivery/DeliveryAction.vue"),
            name: 'addDelivery',
            meta: {
              padding: 4, auth: true, title: 'route.addDelivery', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/delivery',
            component: () => import("@/views/companyApp/delivery/index.vue"),
            name: 'Delivery',
            meta: {
              padding: 4, auth: true, title: 'route.deliveryCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'deliveryAction/:delivery_id?',
                component: () => import("@/views/companyApp/delivery/DeliveryAction.vue"),
                name: 'DeliveryAction',
                hidden: true,
                alias: ['editDelivery/:delivery_id'],
                meta: {
                  auth: true, title: 'route.deliveryAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addDelivery',
                component: () => import("@/views/companyApp/delivery/DeliveryAction.vue"),
                name: 'AddDelivery',
                hidden: true,
                meta: {
                  auth: true, title: 'route.deliveryAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/deliveryReport',
            component: () => import("@/views/companyApp/delivery/DeliveryReport.vue"),
            name: 'DeliveryReport',
            meta: {
              padding: 4, auth: true, title: 'route.deliveryReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/invoice/InvoiceStepView.vue"),
        name: "InvoiceStepView",
        redirect: "/invoice",
        meta: {
          padding: 2, auth: true, title: 'route.invoice', icon: 'mdi-note-text-outline'
        },
        children: [
          {
            path: '/addInvoice',
            component: () => import("@/views/companyApp/invoice/InvoiceAction.vue"),
            name: 'addInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.addInvoice', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/invoice',
            component: () => import("@/views/companyApp/invoice/index.vue"),
            name: 'Invoice',
            meta: {
              padding: 4, auth: true, title: 'route.invoiceCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'invoiceAction/:invoice_id?',
                component: () => import("@/views/companyApp/invoice/InvoiceAction.vue"),
                name: 'InvoiceAction',
                hidden: true,
                alias: ['editInvoice/:invoice_id'],
                meta: {
                  auth: true, title: 'route.invoiceAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addInvoice',
                component: () => import("@/views/companyApp/invoice/InvoiceAction.vue"),
                name: 'AddInvoice',
                hidden: true,
                meta: {
                  auth: true, title: 'route.invoiceAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/invoiceReport',
            component: () => import("@/views/companyApp/invoice/InvoiceReport.vue"),
            name: 'InvoiceReport',
            meta: {
              padding: 4, auth: true, title: 'route.invoiceReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      }, 
      {
        path: "/",
        component: () => import("@/views/companyApp/receiptTax/ReceiptTaxStepView.vue"),
        name: "ReceiptTaxStepView",
        redirect: "/receiptTaxInvoice",
        meta: {
          padding: 2, auth: true, title: 'route.receiptTaxInvoice', icon: 'mdi-receipt-text-plus'
        },
        children: [
          {
            path: '/addReceiptTaxInvoice',
            component: () => import("@/views/companyApp/receiptTax/ReceiptTaxInvoiceAction.vue"),
            name: 'addReceiptTaxInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.addReceiptTaxInvoice', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/receiptTaxInvoice',
            component: () => import("@/views/companyApp/receiptTax/index.vue"),
            name: 'ReceiptTaxInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.receiptTaxInvoiceCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'receiptTaxInvoiceAction/:tax_id?',
                component: () => import("@/views/companyApp/receiptTax/ReceiptTaxInvoiceAction.vue"),
                name: 'ReceiptTaxInvoiceAction',
                hidden: true,
                alias: ['editReceiptTaxInvoice/:tax_id'],
                meta: {
                  auth: true, title: 'route.receiptTaxInvoiceAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addReceiptTaxInvoice',
                component: () => import("@/views/companyApp/receiptTax/ReceiptTaxInvoiceAction.vue"),
                name: 'AddReceiptTaxInvoice',
                hidden: true,
                meta: {
                  auth: true, title: 'route.receiptTaxInvoiceAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/receiptTaxInvoiceReport',
            component: () => import("@/views/companyApp/receiptTax/ReceiptTaxInvoiceReport.vue"),
            name: 'ReceiptTaxInvoiceReport',
            meta: {
              padding: 4, auth: true, title: 'route.receiptTaxInvoiceReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/creditInvoice/CreditInvoiceStepView.vue"),
        name: "CreditInvoiceStepView",
        redirect: "/creditInvoice",
        meta: {
          padding: 2, auth: true, title: 'route.creditInvoice', icon: 'mdi-cash-plus'
        },
        children: [
          {
            path: '/addCreditInvoice',
            component: () => import("@/views/companyApp/creditInvoice/CreditInvoiceAction.vue"),
            name: 'addCreditInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.addCreditInvoice', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/creditInvoice',
            component: () => import("@/views/companyApp/creditInvoice/index.vue"),
            name: 'CreditInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.creditInvoiceCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'creditInvoiceAction/:credit_invoice_id?',
                component: () => import("@/views/companyApp/creditInvoice/CreditInvoiceAction.vue"),
                name: 'CreditInvoiceAction',
                hidden: true,
                alias: ['editCreditInvoice/:credit_invoice_id'],
                meta: {
                  auth: true, title: 'route.creditInvoiceAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addCreditInvoice',
                component: () => import("@/views/companyApp/creditInvoice/CreditInvoiceAction.vue"),
                name: 'AddCreditInvoice',
                hidden: true,
                meta: {
                  auth: true, title: 'route.creditInvoiceAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/creditInvoiceReport',
            component: () => import("@/views/companyApp/creditInvoice/CreditInvoiceReport.vue"),
            name: 'CreditInvoiceReport',
            meta: {
              padding: 4, auth: true, title: 'route.creditInvoiceReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/returnCertificate/ReturnCertificateStepView.vue"),
        name: "ReturnCertificateStepView",
        redirect: "/returnCertificate",
        meta: {
          padding: 2, auth: true, title: 'route.returnCertificate', icon: 'mdi-cloud-upload-outline'
        },
        children: [
          {
            path: '/addReturnCertificate',
            component: () => import("@/views/companyApp/returnCertificate/ReturnCertificateAction.vue"),
            name: 'addReturnCertificate',
            meta: {
              padding: 4, auth: true, title: 'route.addReturnCertificate', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/returnCertificate',
            component: () => import("@/views/companyApp/returnCertificate/index.vue"),
            name: 'ReturnCertificate',
            meta: {
              padding: 4, auth: true, title: 'route.returnCertificateCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'returnCertificateAction/:tax_id?',
                component: () => import("@/views/companyApp/returnCertificate/ReturnCertificateAction.vue"),
                name: 'ReturnCertificateAction',
                hidden: true,
                alias: ['editReturnCertificate/:tax_id'],
                meta: {
                  auth: true, title: 'route.returnCertificateAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addReturnCertificate',
                component: () => import("@/views/companyApp/returnCertificate/ReturnCertificateAction.vue"),
                name: 'AddReturnCertificate',
                hidden: true,
                meta: {
                  auth: true, title: 'route.returnCertificateAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/returnCertificateReport',
            component: () => import("@/views/companyApp/returnCertificate/ReturnCertificateReport.vue"),
            name: 'ReturnCertificateReport',
            meta: {
              padding: 4, auth: true, title: 'route.returnCertificateReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: '/collectiveInvoice',
        component: () => import("@/views/companyApp/multiple/index.vue"),
        name: 'MultipleForm',
        noVisibleChildren: true,
        meta: {
          padding: 2,auth: true, title: 'route.createFromMultiple', icon: 'mdi-select-multiple', noCache: true, affix: true,
        },
        beforeEnter: beforeEnter,
      },
    ],
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    name: "Fund",
    redirect: '/receipt',
    meta: {
      auth: true, title: 'route.fund', icon: 'mdi-cash-register'
    },
    children: [
      {
        path: "/",
        component: () => import("@/views/companyApp/receipt/ReceiptStepView.vue"),
        name: "ReceiptStepView",
        redirect: "/receipt",
        meta: {
          padding: 2, auth: true, title: 'route.receipt', icon: 'mdi-receipt-outline'
        },
        children: [
          {
            path: '/addReceipt',
            component: () => import("@/views/companyApp/receipt/ReceiptAction.vue"),
            name: 'addReceipt',
            meta: {
              padding: 4, auth: true, title: 'route.addReceipt', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/receipt',
            component: () => import("@/views/companyApp/receipt/index.vue"),
            name: 'Receipt',
            meta: {
              padding: 4, auth: true, title: 'route.receiptCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'receiptAction/:receipt_id?',
                component: () => import("@/views/companyApp/receipt/ReceiptAction.vue"),
                name: 'ReceiptAction',
                hidden: true,
                alias: ['editReceipt/:receipt_id'],
                meta: {
                  auth: true, title: 'route.receiptAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addReceipt',
                component: () => import("@/views/companyApp/receipt/ReceiptAction.vue"),
                name: 'AddReceipt',
                hidden: true,
                meta: {
                  auth: true, title: 'route.receiptAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/receiptReport',
            component: () => import("@/views/companyApp/receipt/ReceiptReport.vue"),
            name: 'ReceiptReport',
            meta: {
              padding: 4, auth: true, title: 'route.receiptReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/deposit/DepositStepView.vue"),
        name: "DepositStepView",
        redirect: "/deposit",
        meta: {
          padding: 2, auth: true, title: 'route.deposit', icon: 'mdi-bank-transfer-in'
        },
        children: [
          {
            path: '/addDeposit',
            component: () => import("@/views/companyApp/deposit/DepositAction.vue"),
            name: 'addDeposit',
            meta: {
              padding: 4, auth: true, title: 'route.addDeposit', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/deposit',
            component: () => import("@/views/companyApp/deposit/index.vue"),
            name: 'Deposit',
            meta: {
              padding: 4, auth: true, title: 'route.depositCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'depositAction/:deposit_id?',
                component: () => import("@/views/companyApp/deposit/DepositAction.vue"),
                name: 'DepositAction',
                hidden: true,
                alias: ['editDeposit/:deposit_id'],
                meta: {
                  auth: true, title: 'route.depositAction', actionRole: 'show'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addDeposit',
                component: () => import("@/views/companyApp/deposit/DepositAction.vue"),
                name: 'AddDeposit',
                hidden: true,
                meta: {
                  auth: true, title: 'route.depositAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/depositReport',
            component: () => import("@/views/companyApp/deposit/DepositReport.vue"),
            name: 'DepositReport',
            meta: {
              padding: 4, auth: true, title: 'route.depositReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
    ]
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    name: "Purchase",
    redirect: "/purchaseOrder",
    meta: {
      auth: true, title: 'route.purchases', icon: 'mdi-shopping'
    },
    children: [
      {
        path: "/",
        component: () => import("@/views/companyApp/purchaseOrder/PurchaseOrderStepView.vue"),
        name: "PurchaseOrderStepView",
        redirect: "/purchaseOrder",
        meta: {
          padding: 2, auth: true, title: 'route.purchaseOrder', icon: 'mdi-cart-arrow-down'
        },
        children: [
          {
            path: '/addPurchaseOrder',
            component: () => import("@/views/companyApp/purchaseOrder/PurchaseOrderAction.vue"),
            name: 'addPurchaseOrder',
            meta: {
              padding: 4, auth: true, title: 'route.addPurchaseOrder', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/purchaseOrder',
            component: () => import("@/views/companyApp/purchaseOrder/index.vue"),
            name: 'PurchaseOrder',
            meta: {
              padding: 4, auth: true, title: 'route.purchaseOrderCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'purchaseOrderAction/:purchase_order_id?',
                component: () => import("@/views/companyApp/purchaseOrder/PurchaseOrderAction.vue"),
                name: 'PurchaseOrderAction',
                hidden: true,
                alias: ['editPurchaseOrder/:purchase_order_id'],
                meta: {
                  auth: true, title: 'route.purchaseOrderAction'
                },
                beforeEnter: beforeEnter,
              },
            ],
          },
          {
            path: '/purchaseOrderReport',
            component: () => import("@/views/companyApp/purchaseOrder/PurchaseOrderReport.vue"),
            name: 'PurchaseOrderReport',
            meta: {
              padding: 4, auth: true, title: 'route.purchaseOrderReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/supplierDeliveryNote/SupplierDeliveryNoteStepView.vue"),
        name: "SupplierDeliveryNoteStepView",
        redirect: "/supplierDeliveryNote",
        meta: {
          padding: 2, auth: true, title: 'route.supplierDeliveryNote', icon: 'mdi-truck-delivery'
        },
        children: [
          {
            path: '/addSupplierDeliveryNote',
            component: () => import("@/views/companyApp/supplierDeliveryNote/SupplierDeliveryNoteAction.vue"),
            name: 'addSupplierDeliveryNote',
            meta: {
              padding: 4, auth: true, title: 'route.addSupplierDeliveryNote', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/supplierDeliveryNote',
            component: () => import("@/views/companyApp/supplierDeliveryNote/index.vue"),
            name: 'SupplierDeliveryNote',
            meta: {
              padding: 4, auth: true, title: 'route.supplierDeliveryNoteCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'supplierDeliveryNoteAction/:supplier_delivery_note_id?',
                component: () => import("@/views/companyApp/supplierDeliveryNote/SupplierDeliveryNoteAction.vue"),
                name: 'SupplierDeliveryNoteAction',
                hidden: true,
                alias: ['editSupplierDeliveryNote/:supplier_delivery_note_id'],
                meta: {
                  auth: true, title: 'route.supplierDeliveryNoteAction'
                },
                beforeEnter: beforeEnter,
              },
            ],
          },
          {
            path: '/supplierDeliveryNoteReport',
            component: () => import("@/views/companyApp/supplierDeliveryNote/SupplierDeliveryNoteReport.vue"),
            name: 'SupplierDeliveryNoteReport',
            meta: {
              padding: 4, auth: true, title: 'route.supplierDeliveryNoteReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/supplierInvoice/SupplierInvoiceStepView.vue"),
        name: "SupplierInvoiceStepView",
        redirect: "/supplierInvoice",
        meta: {
          padding: 2, auth: true, title: 'route.supplierInvoice', icon: 'mdi-note-text-outline'
        },
        children: [
          {
            path: '/addSupplierInvoice',
            component: () => import("@/views/companyApp/supplierInvoice/SupplierInvoiceAction.vue"),
            name: 'addSupplierInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.addSupplierInvoice', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/supplierInvoice',
            component: () => import("@/views/companyApp/supplierInvoice/index.vue"),
            name: 'SupplierInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.supplierInvoiceCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'supplierInvoiceAction/:supplier_invoice_id?',
                component: () => import("@/views/companyApp/supplierInvoice/SupplierInvoiceAction.vue"),
                name: 'SupplierInvoiceAction',
                hidden: true,
                alias: ['editSupplierInvoice/:supplier_invoice_id'],
                meta: {
                  auth: true, title: 'route.supplierInvoiceAction'
                },
                beforeEnter: beforeEnter,
              },
            ],
          },
          {
            path: '/supplierInvoiceReport',
            component: () => import("@/views/companyApp/supplierInvoice/SupplierInvoiceReport.vue"),
            name: 'SupplierInvoiceReport',
            meta: {
              padding: 4, auth: true, title: 'route.supplierInvoiceReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: '/purchaseMerging',
        component: () => import("@/views/companyApp/purchaseMerging/index.vue"),
        name: 'PurchaseMerging',
        noVisibleChildren: true,
        meta: {
          padding: 2,auth: true, title: 'route.purchaseMerging', icon: 'mdi-select-multiple', noCache: true, affix: true,
        },
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/supplierCreditInvoice/SupplierCreditInvoiceStepView.vue"),
        name: "SupplierCreditInvoiceStepView",
        redirect: "/supplierCreditInvoice",
        meta: {
          padding: 2, auth: true, title: 'route.supplierCreditInvoice', icon: 'mdi-credit-card-outline'
        },
        children: [
          {
            path: '/addSupplierCreditInvoice',
            component: () => import("@/views/companyApp/supplierCreditInvoice/SupplierCreditInvoiceAction.vue"),
            name: 'addSupplierCreditInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.addSupplierCreditInvoice', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/supplierCreditInvoice',
            component: () => import("@/views/companyApp/supplierCreditInvoice/index.vue"),
            name: 'SupplierCreditInvoice',
            meta: {
              padding: 4, auth: true, title: 'route.supplierCreditInvoiceCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'supplierCreditInvoiceAction/:supplier_credit_invoice_id?',
                component: () => import("@/views/companyApp/supplierCreditInvoice/SupplierCreditInvoiceAction.vue"),
                name: 'SupplierCreditInvoiceAction',
                hidden: true,
                alias: ['editSupplierCreditInvoice/:supplier_credit_invoice_id'],
                meta: {
                  auth: true, title: 'route.supplierCreditInvoiceAction'
                },
                beforeEnter: beforeEnter,
              },
            ],
          },
          {
            path: '/supplierCreditInvoiceReport',
            component: () => import("@/views/companyApp/supplierCreditInvoice/SupplierCreditInvoiceReport.vue"),
            name: 'SupplierCreditInvoiceReport',
            meta: {
              padding: 4, auth: true, title: 'route.supplierCreditInvoiceReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/supplierPayment/SupplierPaymentStepView.vue"),
        name: "SupplierPaymentStepView",
        redirect: "/supplierPayment",
        meta: {
          padding: 2, auth: true, title: 'route.supplierPayment', icon: 'mdi-account-credit-card-outline'
        },
        children: [
          {
            path: '/addSupplierPayment',
            component: () => import("@/views/companyApp/supplierPayment/SupplierPaymentAction.vue"),
            name: 'addSupplierPayment',
            meta: {
              padding: 4, auth: true, title: 'route.addSupplierPayment', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/supplierPayment',
            component: () => import("@/views/companyApp/supplierPayment/index.vue"),
            name: 'SupplierPayment',
            meta: {
              padding: 4, auth: true, title: 'route.supplierPaymentCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'supplierPaymentAction/:supplier_receipt_id?',
                component: () => import("@/views/companyApp/supplierPayment/SupplierPaymentAction.vue"),
                name: 'SupplierPaymentAction',
                hidden: true,
                alias: ['editSupplierPayment/:supplier_receipt_id'],
                meta: {
                  auth: true, title: 'route.supplierPaymentAction'
                },
                beforeEnter: beforeEnter,
              },
            ],
          },
          {
            path: '/supplierPaymentReport',
            component: () => import("@/views/companyApp/supplierPayment/SupplierPaymentReport.vue"),
            name: 'SupplierPaymentReport',
            meta: {
              padding: 4, auth: true, title: 'route.supplierPaymentReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
    ]
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    name: "Reports",
    redirect: '/debit-report',
    meta: {
      auth: true, title: 'route.reports', icon: 'mdi-filter-cog-outline'
    },
    children: [
      {
        path: '/debit-report',
        component: () => import("@/views/companyApp/debitReport/index.vue"),
        name: 'DebitReport',
        meta: {
          debitQuery: false, padding: 2, auth: true, title: 'route.debitReport', icon: 'mdi-finance', actionRole: 'show'
        },
        children: [],
        beforeEnter: beforeEnter,
      },
      {
        path: '/sales-report',
        component: () => import("@/views/companyApp/salesReport/index.vue"),
        name: 'SalesReport',
        meta: {
          padding: 2, auth: true, title: 'route.salesReport', icon: 'mdi-chart-areaspline-variant', actionRole: 'financial'
        },
        beforeEnter: beforeEnter,
      },
      {
        path: '/supplier-debts-report',
        component: () => import("@/views/companyApp/supplierDebtsReport/index.vue"),
        name: 'SupplierDebtsReport',
        meta: {
          padding: 2, auth: true, title: 'route.supplierDebts', icon: 'mdi-truck-delivery', actionRole: 'financial'
        },
        beforeEnter: beforeEnter,
      },
      {
        path: '/purchase-report',
        component: () => import("@/views/companyApp/purchaseReport/index.vue"),
        name: 'PurchaseReport',
        meta: {
          padding: 2, auth: true, title: 'route.purchaseReport', icon: 'mdi-cart-arrow-down', actionRole: 'financial'
        },
        beforeEnter: beforeEnter,
      },
      {
        path: '/cash-flow',
        component: () => import("@/views/companyApp/cashFlow/index.vue"),
        name: 'CashFlow',
        meta: {
          padding: 2, auth: true, title: 'route.cashFlow', icon: 'mdi-account-cash', actionRole: 'financial'
        },
        beforeEnter: beforeEnter,
      },
      {
        path: '/general-evaluation',
        component: () => import("@/views/companyApp/generalEvaluation/index.vue"),
        name: 'GeneralEvaluation',
        meta: {
          debitQuery: false, padding: 2, auth: true, title: 'route.generalEvaluation', icon: 'mdi-file-find', actionRole: 'show'
        },
        beforeEnter: beforeEnter,
      },
    ],
    beforeEnter: beforeEnter,
  },
  {
    path: "/",
    component: () => import("@/views/layout/TheLayout.vue"),
    name: "Bookkeeping",
    redirect: "/income",
    meta: {
      auth: true, title: 'bookkeeping.bookKeeping', icon: 'mdi-book-open-page-variant-outline' 
    },
    children: [
      {
        path: "/",
        component: () => import("@/views/companyApp/bookkeepingSettings/Settings.vue"),
        name: "BookkeepingSettings",
        redirect: "/income",
        meta: {
          padding: 2, auth: true, title: 'bookkeeping.settings', icon: 'mdi-cog'
        },
        children: [
          {
            path: '/income',
            component: () => import("@/views/companyApp/income/index.vue"),
            name: "Income",
            meta: {
              padding: 4, auth: true, title: 'bookkeeping.income', actionRole: 'bookkeeping', icon: 'mdi-cash-check'
            },
            children: [
              {
                path: 'incomeAction/:income_id?',
                component: () => import("@/views/companyApp/income/IncomeAction.vue"),
                name: 'IncomeAction',
                hidden: true,
                alias: ['editIncome/:income_id'],
                meta: {
                  auth: true, title: 'bookkeeping.incomeAction', actionRole: 'edit'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addIncome',
                component: () => import("@/views/companyApp/income/IncomeAction.vue"),
                name: 'AddIncome',
                hidden: true,
                meta: {
                  auth: true, title: 'bookkeeping.incomeAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
            beforeEnter: beforeEnter
          },
          {
            path: '/expenses',
            component: () => import("@/views/companyApp/expenses/index.vue"),
            name: "Expenses",
            meta: {
              padding: 4, auth: true, title: 'expenses.expenses', actionRole: 'bookkeeping', icon: 'mdi-cart'
            },
            children: [
              {
                path: 'expensesAction/:expenses_id?',
                component: () => import("@/views/companyApp/expenses/ExpensesAction.vue"),
                name: 'ExpensesAction',
                hidden: true,
                alias: ['editExpenses/:expenses_id'],
                meta: {
                  auth: true, title: 'expenses.expensesAction', actionRole: 'edit'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addExpenses',
                component: () => import("@/views/companyApp/expenses/ExpensesAction.vue"),
                name: 'AddExpenses',
                hidden: true,
                meta: {
                  auth: true, title: 'expenses.expensesAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
            beforeEnter: beforeEnter
          },
          {
            path: '/funds',
            component: () => import("@/views/companyApp/funds/index.vue"),
            name: "Funds",
            meta: {
              padding: 4, auth: true, title: 'funds.funds', actionRole: 'bookkeeping', icon: 'mdi-treasure-chest'
            },
            children: [
              {
                path: 'fundsAction/:funds_id?',
                component: () => import("@/views/companyApp/funds/FundsAction.vue"),
                name: 'FundsAction',
                hidden: true,
                alias: ['editFunds/:funds_id'],
                meta: {
                  auth: true, title: 'funds.fundsAction', actionRole: 'edit'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addFunds',
                component: () => import("@/views/companyApp/funds/FundsAction.vue"),
                name: 'AddFunds',
                hidden: true,
                meta: {
                  auth: true, title: 'funds.fundsAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
            beforeEnter: beforeEnter
          },
          {
            path: '/bookkeeping_banks',
            component: () => import("@/views/companyApp/bookkeepingBank/index.vue"),
            name: "BookkeepingBanks",
            meta: {
              padding: 4, auth: true, title: 'bookkeepingBank.banks', actionRole: 'bookkeeping', icon: 'mdi-bank-check'
            },
            children: [
              {
                path: 'banksAction/:banks_id?',
                component: () => import("@/views/companyApp/bookkeepingBank/BanksAction.vue"),
                name: 'BookkeepingBanksAction',
                hidden: true,
                alias: ['editBanks/:banks_id'],
                meta: {
                  auth: true, title: 'bookkeepingBank.banksActions', actionRole: 'edit'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addBanks',
                component: () => import("@/views/companyApp/bookkeepingBank/BanksAction.vue"),
                name: 'BookkeepingAddBanks',
                hidden: true,
                meta: {
                  auth: true, title: 'bookkeepingBank.banksActions', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
            beforeEnter: beforeEnter
          },
          {
            path: '/other',
            component: () => import("@/views/companyApp/other/index.vue"),
            name: "Other",
            meta: {
              padding: 4, auth: true, title: 'other.other', actionRole: 'bookkeeping', icon: 'mdi-odnoklassniki'
            },
            children: [
              {
                path: 'otherAction/:other_id?',
                component: () => import("@/views/companyApp/other/OtherAction.vue"),
                name: 'OtherAction',
                hidden: true,
                alias: ['editOther/:other_id'],
                meta: {
                  auth: true, title: 'other.otherAction', actionRole: 'edit'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addOther',
                component: () => import("@/views/companyApp/other/OtherAction.vue"),
                name: 'AddOther',
                hidden: true,
                meta: {
                  auth: true, title: 'other.otherAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
            beforeEnter: beforeEnter
          },
          {
            path: '/miscellaneous',
            component: () => import("@/views/companyApp/miscellaneous/index.vue"),
            name: "Miscellaneous",
            meta: {
              padding: 4, auth: true, title: 'miscellaneous.miscellaneous', actionRole: 'bookkeeping', icon: 'mdi-alpha-m-box'
            },
            beforeEnter: beforeEnter
          },
        ],
        beforeEnter: beforeEnter
      },
      {
        path: "/",
        component: () => import("@/views/companyApp/journalEntry/JournalEntryStepView.vue"),
        name: "JournalEntryStepView",
        redirect: "/journal-entry",
        meta: {
          padding: 2, auth: true, title: 'route.journalEntry', icon: 'mdi-notebook-outline', actionRole: 'bookkeeping'
        },
        children: [
          {
            path: '/addJournalEntry',
            component: () => import("@/views/companyApp/journalEntry/JournalEntryAction.vue"),
            name: 'addJournalEntry',
            meta: {
              padding: 4, auth: true, title: 'route.addJournalEntry', icon: 'mdi-plus', actionRole: 'create'
            },
            beforeEnter: beforeEnter,
          },
          {
            path: '/journal-entry',
            component: () => import("@/views/companyApp/journalEntry/index.vue"),
            name: 'JournalEntry',
            meta: {
              padding: 4, auth: true, title: 'route.journalEntryCatalog', icon: 'mdi-view-list'
            },
            children: [
              {
                path: 'journalEntryAction/:invoice_id?',
                component: () => import("@/views/companyApp/journalEntry/JournalEntryAction.vue"),
                name: 'JournalEntryAction',
                hidden: true,
                alias: ['editJournalEntry/:invoice_id'],
                meta: {
                  auth: true, title: 'route.journalEntryAction'
                },
                beforeEnter: beforeEnter,
              },
              {
                path: 'addJournalEntry',
                component: () => import("@/views/companyApp/journalEntry/JournalEntryAction.vue"),
                name: 'AddJournalEntry',
                hidden: true,
                meta: {
                  auth: true, title: 'route.journalEntryAction', actionRole: 'create'
                },
                beforeEnter: beforeEnter,
              }
            ],
          },
          {
            path: '/journalEntryReport',
            component: () => import("@/views/companyApp/journalEntry/JournalEntryReport.vue"),
            name: 'JournalEntryReport',
            meta: {
              padding: 4, auth: true, title: 'route.journalEntryReport', icon: 'mdi-chart-box'
            },
            beforeEnter: beforeEnter,
          }
        ],
        beforeEnter: beforeEnter,
      },
      {
        path: '/accounting-card-reports',
        component: () => import("@/views/companyApp/accountingCard/index.vue"),
        name: 'AccountingCardReport',
        meta: {
          padding: 2, auth: true, title: 'accountingCardReport.accountingCardReport', icon: 'mdi-chart-line-stacked', noCache: true, affix: true, actionRole: 'bookkeeping'
        },
        beforeEnter: beforeEnter,
      },
      {
        path: '/trial-balance',
        component: () => import("@/views/companyApp/trialBalance/index.vue"),
        name: 'TrialBalance',
        meta: {
          padding: 2, auth: true, title: 'route.trialBalance', icon: 'mdi-wallet-outline', noCache: true, affix: true, actionRole: 'bookkeeping'
        },
        beforeEnter: beforeEnter,
      }
    ],
    beforeEnter: beforeEnter
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/period-closure',
        component: () => import("@/views/companyApp/periodClosure/index.vue"),
        name: 'PeriodClosure',
        noVisibleChildren: true,
        meta: {
          auth: true, title: 'route.periodClosure', icon: 'mdi-close-box-multiple', noCache: true, affix: true,
        }
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/activity-log',
        component: () => import("@/views/companyApp/activityLog/index.vue"),
        name: 'ActivityLog',
        noVisibleChildren: true,
        meta: {
          auth: true, title: 'route.activityLog', icon: 'mdi-pulse', noCache: true, affix: true,
        }
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/signin',
    name: 'login',
    hidden: true,
    component: () => import('@/views/companyApp/auth/SingIn.vue'),
    meta: {
      title: 'login.titleIn',
    },
    beforeEnter: beforeEnter,
  },
  // {
  //   path: '/singup',
  //   name: 'register',
  //   hidden: true,
  //   component: () => import('@/views/companyApp/auth/SingUp.vue'),
  //   beforeEnter: beforeEnter,
  // },
  {
    path: '/reset-password',
    name: "ResetPassword",
    hidden: true,
    component: () => import('@/views/companyApp/auth/ResetPassword.vue'),
    beforeEnter: beforeEnter,
    meta: {
      title: 'login.resetPassword',
    },
  },
  // {
  //   path: '/',
  //   component: () => import("@/views/layout/TheLayout.vue"),
  //   children: [
  //     {
  //       path: '/dbquery',
  //       component: () => import("@/views/companyApp/dbQuery/index.vue"),
  //       name: 'Query Database',
  //       meta: {
  //         auth: true, title: 'route.dbQuery', accessRoles: ['company', 'demo'], noCache: true, affix: true, icon: 'mdi-database',
  //       }
  //     },
  //   ],
  //   meta: {
  //     auth: true,
  //   },
  //   beforeEnter: beforeEnter,
  // },
  {
    path: '*',
    redirect: () => {
      return { path: '/' }
    },
    hidden: true,
  },
]

const companyRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

companyRouter.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.name === 'RolesAction' || to.name === 'EmployeeAction' || to.name === 'CustomerAction' || to.name === 'SupplierAction' || to.name === 'ProductAction' || to.name === 'BankAction' || to.name === 'QuotationAction' || to.name === 'OrderAction' || to.name === 'DepositAction' || to.name === 'IncomeAction' || to.name === 'ExpensesAction' || to.name === 'FundsAction' || to.name === 'BookkeepingBanksAction' || to.name === 'OtherAction') {
      document.title = i18n.t(`${to.meta.title}`) + '   ' + to.fullPath.split('/')[3] || 'Bina';
    } else {
      document.title = i18n.t(`${to.meta.title}`) || 'Bina';
    }
  });
});

export default companyRouter;
