export default {
  deposit: 'Deposit',
  depositActions: "Deposit Actions",
  editDeposit: "Edit Deposit",
  addDeposit: "Add Deposit",
  depositList: 'Deposit List',
  depositDate:"Deposit Date",
  depositItem: "Deposit Payment",
  customer: "Customer",
  agent: "Employee",
  branch: "Branch",
  id: "ID",
  action: "Action",
  save: "Save",
  depositDetail: "Deposit Detail",
  customerName: "Customer Name",
  customerNumber: 'Customer No',
  balance: "Balance",
  total: "Total",
  listOfPayment: "List Of Payment",
  from: "From",
  to: "To",
  amount: "Amount",
  type: "Type",
  paymentType: "Payment Type",
  bank: 'Bank',
  depositToBank: 'Deposit to Bank',
  chequeNumber: "Cheque No",
  accountNo: "Account No",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Deposit Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerGroup: "Customer Group",
  totalPerReport: "Total Per Report",
  totalPerPaymentType: "Total Payment",
  message: {
    depositSuccessfullyDeleted: "Deposit cancelled successfully",
    depositSuccessfullyAdded: "Deposit added successfully",
    depositSuccessfullyEdited: "Deposit edited successfully",
  },
  field: {
    accountUpdate: "Account",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    status: "Status",
    paymentDate:"Payment Date",
    employeeName: "Employee Name",
    bankId: "Bank Id",
    customer: "Customer",
    createdAt: "Created At",
    updatedAt: "Updated At",
  }
}