import Vue from 'vue';
const dbQuery = {
    state:{
        tableList:[],
        queryResult:null,
        // tableColumn: null
    },
    getters:{
        tableList:(state)=>state.tableList,
        // tableColumn:(state)=>state.tableColumn
    },
    mutations:{
        SET_TABLE_LIST:(state,payload)=>{
            state.tableList=payload;
        },
        SET_QUERY_RESULTS:(state,payload)=>{
            state.queryResult= payload;
        },
        // SET_COLUMN_BY_TABLE:(state,payload) => {
        //     state.tableColumn= payload;
        // }
    },
    actions:{
        GetTableList:async (context, params) => {
            try{
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.dbQuery.getDBTableList(params).then(({ data }) => {
                        context.commit("SET_TABLE_LIST", data);
                        resolve(data);
                    }).catch((error) => {
                        if(error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data})
                            context.dispatch('SetSnackBar', errorData, {root: true});
                        }
                        console.log(error)
                        reject(error)
                    });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        },
        GetColumnByTable(context, params) {
            try{
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.dbQuery.getColumnByTable(params).then(({ data }) => {
                        // context.commit("SET_COLUMN_BY_TABLE", data);
                        resolve(data);
                    }).catch((error) => {
                        if(error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data})
                            context.dispatch('SetSnackBar', errorData, {root: true});
                        }
                        console.log(error)
                        reject(error)
                    });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        },
        UploadExcel(context, params) {
            try{
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.dbQuery.uploadExcel(params).then(({ data }) => {
                        let successData = Object.assign({ success: 'dbQuery.message.importSuccessfully'})
                        context.dispatch('SetSnackBar', successData, {root: true});
                        resolve(data);
                    }).catch((error) => {
                        if(error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data})
                            context.dispatch('SetSnackBar', errorData, {root: true});
                        }
                        console.log(error)
                        reject(error)
                    });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        },
        FireQuery:async (context,params) => {
            try{
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.dbQuery.postDBQuery(params).then(({ data }) => {
                        context.commit("SET_QUERY_RESULTS", data);
                        resolve(data);
                    }).catch((error) => {
                        if(error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data})
                            context.dispatch('SetSnackBar', errorData, {root: true});
                        }
                        console.log(error)
                        reject(error)
                    });
                });
            } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
        }
    },
    namespaced: true,
}

export default dbQuery;