import { settings as defaultSettings, version } from '@/config';
import { setVuetifyLocale } from '@/plugins/vuetify';
import { setLocale } from '@/locale';
const getDefaultSettings = () => {
  const settings = JSON.parse(JSON.stringify(defaultSettings));
  return settings;
};

const settings = {
  state: {
    ...getDefaultSettings(),
    welcomeDialog: true,
    selectedBarChartType: false,
    bookkeepingConfirmation: null,
    alertConfirmation: null
  },

  getters: {
    version: () => version,
    locale: (state) => state.locale,
    welcomeDialog: (state) => state.welcomeDialog,
    navbarShow: (state) => state.navbar.show,
    toolbarDense: (state) => state.dense,
    selectedBarChartType: (state) => state.selectedBarChartType,
    bookkeepingConfirmation: (state) => state.bookkeepingConfirmation,
    alertConfirmation: (state) => state.alertConfirmation
  },
  mutations: {
    SET_WELCOME_DIALOG: (state, payload) => {
      state.welcomeDialog = payload;
    },
    SET_LOCALE: (state, payload) => {
      state.locale = payload.locale;
      setVuetifyLocale(state.locale);
    },
    NAVBAR_TOGGLE: (state) => {
      state.navbar.show = !state.navbar.show;
    },
    NAVBAR_STATE: (state, payload) => {
      state.navbar.show = payload.state;
    },
    SET_CHART_TYPE: (state, payload) => {
      state.selectedBarChartType = payload;
    },
    SET_BOOKKEEPING_CONFIRMATION: (state, payload)  => {
      state.bookkeepingConfirmation = payload;
    },
    SET_ALERT_CONFIRMATION: (state, payload) => {
      state.alertConfirmation = payload;
    }
  },
  actions: {
    SetLocale: async (context, payload) => {
      context.commit('SET_LOCALE', payload);
      await setLocale(payload.locale);
    },
    NavbarToggle: async (context, payload) => {
      context.commit('NAVBAR_TOGGLE', payload);
    },
    NavbarState: async (context, payload) => {
      context.commit('NAVBAR_STATE', payload);
    },
    SetWelcomeDialog: async (context, payload) => {
      context.commit('SET_WELCOME_DIALOG', payload);
    },
  }
}
export default settings;