import Vue from 'vue';
const auxiliarytable = {
    state: {
      auxiliarytableNameList: [],
      auxiliarytableById: {},
    },
  
    getters: {
      auxiliarytableNameList: (state) => state.auxiliarytableNameList,
      auxiliarytableById: (state) => state.auxiliarytableById,
    },
    mutations: {
      SET_AUXILIARYTABLE: (state, payload) => {
        state.auxiliarytableNameList = payload;
      },
      SET_AUXILIARYTABLE_BY_ID: (state, payload) => {
        if(payload) {
          state.auxiliarytableById = payload;
        } else {
          state.auxiliarytableById = {};
        }
      },
    },
    actions: {
      // ztabeleName CRUD apis
      GetAuxiliarytableName: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.auxiliarytable
              .getAuxiliaryZtableName(payload)
              .then(({ data }) => {
                context.commit("SET_AUXILIARYTABLE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetAuxiliarytable: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auxiliarytable
            .addAuxiliaryZtableName(payload)
            .then((res) => {
              context.dispatch('GetAuxiliarytableName');
              let successData = Object.assign({ success: 'auxiliaryTable.message.auxiliaryTableNameSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditAuxiliarytable: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auxiliarytable
            .editAuxiliaryZtableName(payload.ztable_id, payload.tableValue)
            .then((res) => {
              context.dispatch('GetAuxiliarytableName');
              let successData = Object.assign({ success: 'auxiliaryTable.message.auxiliaryTableNameSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeleteAuxiliarytableNameById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auxiliarytable
            .deleteAuxiliaryZtableName(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'auxiliaryTable.message.auxiliaryTableNameSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              context.dispatch('GetAuxiliarytableName');
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetAuxiliarytableById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.auxiliarytable
              .getAuxiliaryZtableNameId(payload)
              .then(({ data }) => {
                context.commit('SET_AUXILIARYTABLE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      // ztabele CRUD apis
      SetAuxiliarytableValue: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auxiliarytablevalue
            .addAuxiliaryZtable(payload)
            .then((res) => {
              context.dispatch('GetAuxiliarytableName');
              let successData = Object.assign({ success: 'auxiliaryTable.message.auxiliaryTableValueSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditAuxiliarytableValue: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auxiliarytablevalue
            .editAuxiliaryZtable(payload.table_id, payload.tableValue)
            .then((res) => {
              context.dispatch('GetAuxiliarytableName');
              let successData = Object.assign({ success: 'auxiliaryTable.message.auxiliaryTableValueSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeleteAuxiliarytableValueById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auxiliarytablevalue
            .deleteAuxiliaryZtable(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'auxiliaryTable.message.auxiliaryTableValueSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              context.dispatch('GetAuxiliarytableName');
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default auxiliarytable;