export default {
  detailed: {
    date: "Date",
    type: "Type",
    paymentType: "Payment Type",
    no: "No.",
    bank: "Bank",
    accountNo: "Account No.",
    name: "Name",
    reference: "Reference",
    credit: "Credit",
    debit: "Debit",
    balance: "Balance",
  },
  monthly: {
    month: "Month",
    incoming: "Incoming",
    outgoing: "Outgoing",
    totalMonthly: "Total monthly",
    balance: "Balance",
  },
  focused: {
    incoming: "Incoming",
    outgoing: "Outgoing",
    balance: "Balance",
  },
  daily: {
    date: "Date",
    day: "Day",
    credit: "Credit",
    debit: "Debit",
    dailyBalance: "Daily balance",
    balance: "Balance",
  },
  days: {
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  },
  totalcredit: "Total Credit",
  totaldebit: "Total Debit",
  receipt_payment: "Receipt",
  receipt_tax_invoice_payment: "Receipt Tax ",
  supplier_receipt_payment: "Supplier Receipt",
  withoutCustomer: "Without Customer",
  withoutSupplier: "Without Supplier",
};
