export default {
    id: "קוד",
    journalEntry: "יומן",
    year: "שנה",
    referenceOne: "אסמכתא 1",
    referenceOneDate: "תאריך אסמכתא 1",
    referenceTwo: "אסמכתא 2",
    referenceTwoDate: "תאריך אסמכתא 2",
    documentDetails: "פרטים",
    title: "כותרת",
    employee: "עובד",
    debitAccount: "חובה",
    creditAccount: "זכות",
    amount: "סכום",
    invoice: "חשבונית",
    creditinvoice: "חשבונית זיכוי",
    receipt: "קבלה",
    deposit: "פיקדון",
    receipttaxinvoice: "חשבונית/קבלה",
    createdAt: "נוצר בתאריך",
}