export default {
  invoice: 'חשבונית',
  invoiceActions: "פעולות חשבונית",
  editInvoice: "ערוך חשבונית",
  addInvoice: "הוסף חשבונית",
  save:"שמור",
  invoiceList: "רשימת חשבוניות",
  id: "קוד",
  action: "פעילות",
  no: "מס' שורה",
  product: "פריט",
  invoiceItem: 'פריט חשבונית',
  quantity: "כמות",
  unitPrice: "מחיר יחידה",
  lineDiscount: "הנחה",
  itemDescription: "תאור פריט",
  total: 'סה"כ',
  invoiceDetail: "פרטי חשבונית",
  createReceipt: "לִיצוֹר קבלה",
  customerDetail: "מידע לקוח",
  additionalCustomerDetail: "פרטי לקוח נוספים",
  listOfItems: "רשימת פריטים",
  from: "מ",
  to: "עד",
  fromDelivery: ":מ תעודת משלוח",
  fromOrder: ":מ הזמנה",
  createFromMultiple: "צור מרובה",
  prepareInvoice: "הכן חשבונית",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח חשבונית",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerGroup: "קבוצה של לקוחות",
  invoiceStatus: "סטטוס חשבוניות",
  empty: "ריק",
  tech: "טק",
  qutation: "הצעת מחיר",
  order: 'הזמנה', 
  proformainvoice: 'חשבון פרופורמה',
  recipttaxinvoice: 'חשבונית/קבלה',
  creditinvoice: "חשבונית זיכוי",
  returncertificate: 'תעודת החזרה',
  delivery: "תעודת משלוח",
  createDate: "יצירת תאריך מסמך",
  message: {
    invoiceSuccessfullyDeleted: "החשבונית נמחקה בהצלחה",
    invoiceSuccessfullyAdded: "החשבונית נוספה בהצלחה",
    invoiceSuccessfullyEdited: "החשבונית נערכה בהצלחה",
    periodClosureSuccessfullyAdded: "סגירת התקופה נערך בהצלחה",
    periodClosureSuccessfullyDeleted: "סגירת התקופה בוטלה בהצלחה"
  },
  field: {
    customer: "לקוח",
    customerName: "שם לקוח",
    invoiceDate: "תאריך חשבונית",
    deliveryDate: "תאריך משלוח",
    paymentDate:"תאריך תשלום",
    title: "כותרת לחשבונית",
    address: "כתובת לקוח",
    town: "עיר",
    country: "מדינה",
    tel1: "טל. נוסף 1",
    tel2: "טל. נוסף 2",
    cel: "נוסף סלולרי",
    fax: "נוסף פקס",
    email: "אי מייל",
    authorizedDealerNo: "מספר עוסק מורשה",
    contact: "איש קשר",
    agent: "סוכן",
    remarks: "הערות",
    timePrinted: "שעת הדפסה",
    orderNo: "מספר הזמנה",
    status: "מצב חשבונית",
    total: 'סה"כ',
    discountPrecentage: "אחוז הנחה",
    discount: "הנחה",
    afterDiscount: "לאחר הנחה",
    vat: 'מע"מ',
    vatPrecentage: 'אחוז מע"מ',
    grandTotal: 'סה"כ סופי',
    all: "כולם",
    open: "פתוח",
    finished: "סגור",
    canceled: "מבוטל",
    customerOrderNo: "הזמנת לקוח",
    quotationNo: "מספר הצעת מחיר",
    receiptNo: "קבלה מספר",
    invoiceNo: "חשבונית מס.",
    invoiceYear: "שנת חשבונית",
    carNo: "מספר רכב",
    order: "להזמין",
    deliveryCompany: "חברת שליחויות",
    balance: "יתרה",
    accountUpdate: 'הנה"ח',
    year: "שָׁנָה",
    contactId: "זיהוי איש קשר",
    customerId: "מספר לקוח",
    employeeId: "כרטיס עובד",
    quotationId: "זיהוי הצעת מחיר",
    orderId: "מספר הזמנה",
    proformaInvoiceId: "זיהוי חשבונית פרופורמה",
    deliveryNoteId: "מזהה תעודת משלוח",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    proformaNo: "לא. חשבון פרופורמה",
    deliveryNo: "לא. תעודת משלוח",
  }
}