export default {
    accountingCardReport: "דוח כרטיס הנהלת חשבונות",
    adjusted: "מוּתאָם",
    accountNo: 'מספר הנה"ח',
    accountName: 'שם חשבון',
    documentDate: "תאריך מסמך",
    valueDate: "תאריך ערך",
    details: "פרטים",
    journalEntry: "יומן",
    debit: "חובה",
    credit: "זכות",
    balance: "יתרה",
    execute: "בצע",
    reset: "אתחול",
    filters: "מסננים",
    resetFilter: "אפס מסנן",
    totalCreditAmount: "סכום אשראי כולל:",
    totalDebitAmount: "סכום חיוב כולל:",
    difference: "הֶבדֵל:",
    fromReference1Date: "מתאריך התייחסות 1",
    toReference1Date: "לתאריך התייחסות 1",
    fromReference2Date: "מתאריך התייחסות 2",
    toReference2Date: "לתאריך התייחסות 2",
    Invoice: "חשבונית",
    Receipt: "קבלה",
    Deposit: "הפקדה",
    CreditInvoice: "חשבונית זיכוי",
    fromDate: "מתאריך",
    toDate: "עד היום",
    fromAccountNumber: "ממספר חשבון",
    toAccountNumber: "למספר חשבון",
    accountNumber: "מספר חשבון",
    balanceSheetSection: "מדור מאזן חשבון",
    excel: "Excel",
    Income: "הכנסות",
    Expenses: "הוצאות",
    Funds: "כספים",
    Banks: "בנק",
    Others: "אחרים",
    Employee: "עובד",
    Customer: "לקוח",
    Supplier: "ספק",
    Item: "פריט",
    Bank: "בנק",
    VATExemptedIncome: 'פטורות מע"מ',
    OverseasIncome: 'מע"מ עסקאות',
    VAT: 'מע"מ',
    CustomerWithholdingTax: 'מס במקור',
    PurchasesVAT: 'מע"מ תשומות',
    SupplierWithholdingTax: "מס במקור ספקים",
    EquipmentPurchaseVAT: 'מע"מ לרכישת ציוד',
    Cheque: "צ'קים",
    Cash: "כסף מזומן",
    CashFunds: "כספים במזומן",
    ChequeFunds: "קופות צ'קים",
    IncomeVAT: 'מע"מ הכנסה',
    groupBy: "קבוצה לפי",
    ReceiptTaxtInvoice: "חשבונית/קבלה",
    ReceiptTaxInvoice: "חשבונית/קבלה",
    showOpeningBalance: "יתרת פתיחה"
}