<template>
  <v-app id="app">
    <router-view/>
    <SnackBar :snackbar.sync="snackbar" :message="message" />
  </v-app>
</template>
<script>
import SnackBar from "@/components/SnackBar"
import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    SnackBar
  },
  data() {
    return {
      snackbar: false
    }
  },
  computed: {
    ...mapGetters([
      'showMessageBox',
      'message'
    ])
  },
  watch: {
    message() {
      this.snackbar = true
    }
  }
};
</script>

<style>
.asterisk label:after {
  content:" *";
  color: primary;
}

.hourglass-icon {
  animation: rotateHourglass 2s linear infinite;
}

@keyframes rotateHourglass {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>