export default {
  remarks: 'הערות',
  save: "שמור",
  remarksList: "רשימת הערותים",
  date: 'תאריך',
  time: 'שעה',
  employee: 'עובד',
  action: 'פעולה',
  message: {
    bankSuccessfullyDeleted: "הערות נמחק בהצלחה",
    bankSuccessfullyAdded: "הערות נוסף בהצלחה",
    bankSuccessfullyEdited: "הערות נערך בהצלחה",
    remarksSuccessfullySaved: "הערות שמור בהצלחה",
    personalMenuSuccessfullyEdited: "התפריט האישי נערך בהצלחה"
  },
}