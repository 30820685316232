export default {
  supplierCreditInvoice: "חשבונית זיכוי ספק",
  supplierCreditInvoiceList: "רשימת חשבונית זיכוי ספק",
  supplierCreditInvoiceDate: "תאריך חשבונית זיכוי ספק",
  supplierGroup: "קבוצת ספקים",
  supplierCreditInvoiceItems: "פריטי חשבונית זיכוי ספק",
  supplierCreditInvoiceAction: "פעולת חשבונית זיכוי ספק",
  supplierCreditInvoiceDetail: "פרטים חשבונית זיכוי ספק",
  getSupplierInvoices: "קבל חשבוניות של ספקים",
  message: {
    supplierCreditInvoiceSuccessfullyDeleted: "חשבונית זיכוי ספק נמחק בהצלחה",
    supplierCreditInvoiceSuccessfullyAdded: "חשבונית זיכוי ספק נוסף בהצלחה",
    supplierCreditInvoiceSuccessfullyEdited: "חשבונית זיכוי ספק נערך בהצלחה"
  }
}