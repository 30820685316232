export default {
    calendar: "לוּחַ שָׁנָה",
    customerName: "שם לקוח",
    address: "כתובת",
    startTime: "שעת התחלה",
    endTime: "שעת סיום",
    title: "כותרת האירוע",
    details: "תיאור האירוע",
    startDate: "תאריך תחילת האירוע",
    endDate: "תאריך סיום האירוע",
    employee: "עוֹבֵד",
    google: "Google",
    outlook: "Outlook",
    priority: "עדיפות",
    high: "גָבוֹהַ",
    medium: "בינוני",
    low: "נָמוּך",
    eventType: "סוג אירוע",
    time: "זְמַן",
    date: "תַאֲרִיך",
    calendarEvents: "אירועי לוח שנה",
    delete: "מחק את האירוע",
    open: "פתוח",
    done: "סגור",
    canceled: "מבוטל",
    message: {
        calendarSuccessfullyAdded: "לוח השנה נוסף בהצלחה",
        calendarSuccessfullyDeleted: "לוּחַ שָׁנָהבוטל בהצלחה ",
        calendarSuccessfullyEdited: "היומן נערך בהצלחה"
    },
    error: {
        dateIssue : "תאריך התחלה לא יכול להיות גדול מתאריך סיום.",
    }
}