import Vue from 'vue';
const customer = {
    state: {
      customerList: [],
      customerById: {},
      customerQuery: null,
      customerReportList: [],
      customerReportQuery: null,
      obligation: {},
      turnover: {},
      getObligationDialogData: [],
      rowIndex: null,
    },
  
    getters: {
      customerList: (state) => state.customerList,
      customerById: (state) => state.customerById,
      customerQuery: (state) => state.customerQuery,
      customerReportList: (state) => state.customerReportList,
      customerReportQuery: (state) => state.customerReportQuery,
      obligation: (state) => state.obligation,
      turnover: (state) => state.turnover,
      getObligationDialogData: (state) => state.getObligationDialogData,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_CUSTOMER_QUERY: (state, payload) => {
        state.customerQuery = payload;
      },
      SET_CUSTOMER: (state, payload) => {
        state.customerList = payload;
      },
      SET_CUSTOMER_BY_ID: (state, payload) => {
        if(payload) {
          state.customerById = payload;
        } else {
          state.customerById = {};
        }
      },
      SET_CUSTOMER_REPORT_QUERY: (state, payload) => {
        state.customerReportQuery = payload;
      },
      SET_CUSTOMER_REPORT: (state, payload) => {
        state.customerReportList = payload;
      },
      SET_OBLIGATION: (state, payload) => {
        state.obligation = payload;
      },
      SET_TURN_OVER: (state, payload) => {
        state.turnover = payload;
      },
      SET_OBLIGATION_DIALOG_DATA: (state, payload) => {
        state.getObligationDialogData = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetObligation: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customer
              .getObligation(params)
              .then(({ data }) => {
                context.commit("SET_OBLIGATION", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetTurnOver: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customer
              .getTurnOver(params)
              .then(({ data }) => {
                context.commit("SET_TURN_OVER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetObligationDialogData: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customer
              .getObligationDialogData(params)
              .then(({ data }) => {
                context.commit("SET_OBLIGATION_DIALOG_DATA", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetCustomer: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customer
              .getCustomer(params)
              .then(({ data }) => {
                context.commit("SET_CUSTOMER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetCustomerReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customer
              .getCustomer(params)
              .then(({ data }) => {
                context.commit("SET_CUSTOMER_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetCustomer: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.customer
            .addCustomer(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'customer.message.customerSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditCustomer: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.customer
            .editCustomer(payload.customer_id, payload.customer)
            .then((res) => {
              let successData = Object.assign({ success: 'customer.message.customerSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetCustomerById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customer
              .getCustomerById(payload)
              .then(({ data }) => {
                context.dispatch('contact/GetContact', {page: 1, limit: 10, where_and: 'record_type_number,record_type|'+payload+',customers'}, {root: true});
                context.commit('SET_CUSTOMER_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteCustomerItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.customer
            .deleteCustomer(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'customer.message.customerSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetCustomer', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default customer;