export default {
  receipt: 'Receipt',
  receiptActions: "Receipt Actions",
  editReceipt: "Edit Receipt",
  addReceipt: "Add Receipt",
  save: "Save",
  getInvoices: "Get Invoices",
  receiptList: "Receipt List",
  receiptItem: "Receipt Payment",
  id: "ID",
  action: "Action",
  no: "No",
  paymentType: "Payment Type",
  chequeNumber: "Cheque Number",
  bankNo: "Bank No",
  accountNo: "Account No",
  branch: "Branch",
  amount: "Amount",
  total: "Total",
  receiptDetail: "Receipt Detail",
  customerDetail: "Customer Detail",
  additionalCustomerDetail: "Additional Customer Details",
  listOfItems: "List Of Items",
  listOfPayment: "List Of Payment",
  from: "From",
  to: "To",
  originalAmount: "Original Amount",
  originalBalance: "Original Balance",
  amountPaid: "Amount Paid",
  updatedDocuments: "Updated documents",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Receipt Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerGroup: "Customer Group",
  totalPerReport: "Total Per Report",
  totalPerPaymentType: "Total Payment",
  bank: "Bank",
  newUpdate: "New update",
  receiptStatus: "Reciepts status",
  message: {
    receiptSuccessfullyDeleted: "Receipt cancelled successfully",
    receiptSuccessfullyAdded: "Receipt added successfully",
    receiptSuccessfullyEdited: "Receipt edited successfully",
  },
  field: {
    customer: "Customer",
    customerName: "Customer Name",
    receiptDate:"Receipt Date",
    paymentDate:"Payment Date",
    address: "Address",
    town: "Town",
    country: "Country",
    tel1: "Tel 1",
    tel2: "Tel 2",
    cel: "Cel",
    fax: "Fax",
    email: "E-mail",
    authorizedDealerNo: "Authorized dealer number",
    agent: "Employee",
    remarks: "Remarks",
    receiptYear: "Receipt year",
    status: "Status",
    total: "Total",
    discount: "Withholding tax",
    afterDiscount: "After discount",
    grandTotal: "Grand total",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    balance: "Balance",
    accountUpdate: "Account",
    year: "Year",
    customerId: "Customer Id",
    employeeId: "Employee Id",
    withHoldingTax: "With Holding Tax",
    createdAt: "Created At",
    updatedAt: "Updated At",
    receiptCINo: "Receipt/CI No."
  }
}