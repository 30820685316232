export default {
    trialBalance: "מאזן בוחן",
    trialBalanceReport: "דוח מאזן בוחן",
    accountNo: 'מספר הנה"ח',
    credit: "יומן",
    debit: "חובה",
    accountName: "שם חשבון",
    difference: "הֶבדֵל",
    totalCreditAmount: "סכום אשראי כולל:",
    totalDebitAmount: "סכום חיוב כולל:",
    totalDifference: "הֶבדֵל:",
    execute: "בצע",
    reset: "אתחול",
    filters: "מסננים",
    resetFilter: "אפס מסנן",
    excel: "Excel",
    fromDate: "מתאריך",
    toDate: "עד היום",
}