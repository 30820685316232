export default {
    id: "ID",
    funds: "Funds",
    description: "Description",
    accountNumber: "Account No.",
    fundsAction: "Funds Actions",
    creditCard: "Credit Card",
    actions: "Actions",
    options: "Options",
    copy: "Copy & Create New",
    activityLog: "Activity Log",
    save: "Save",
    addFunds: "Add Funds",
    fundsList: "Funds List",
    relativeNumber: "Relative Number",
    cash: "Cash",
    checks: "Checks",
    message: {
        fundsSuccessfullyDeleted: "Funds cancelled successfully",
        fundsSuccessfullyAdded: "Funds added successfully",
        fundsSuccessfullyEdited: "Funds edited successfully",
    },
}