const orderReport = {
    state: {
        showHeaders: [],
        pageChange: {}
    },
    getters: {
        showHeaders: (state) => state.showHeaders,
        pageChange: (state) => state.pageChange
    },
    mutations: {
        SHOW_HEADERS: (state, payload) => {
            state.showHeaders = payload;
        },
        SHOW_PAGE_CHANGE: (state, payload) => {
            state.pageChange = payload;
        }
    },
    namespaced: true,
}

export default orderReport;