import Vue from 'vue';
const invoice = {
    state: {
      invoiceList: [],
      invoiceById: {},
      invoiceQuery: null,
      invoiceReportList: [],
      invoiceReportQuery: null,
      totalBalanceOfCustomer: {},
      rowIndex: null,
      receiptRecords: []
    },
  
    getters: {
      invoiceList: (state) => state.invoiceList,
      invoiceById: (state) => state.invoiceById,
      invoiceQuery: (state) => state.invoiceQuery,
      invoiceReportList: (state) => state.invoiceReportList,
      invoiceReportQuery: (state) => state.invoiceReportQuery,
      totalBalanceOfCustomer: (state) => state.totalBalanceOfCustomer,
      rowIndex: (state) => state.rowIndex,
      receiptRecords: (state) => state.receiptRecords,
    },
    mutations: {
      SET_INVOICE_QUERY: (state, payload) => {
        state.invoiceQuery = payload;
      },
      SET_INVOICE: (state, payload) => {
        state.invoiceList = payload;
      },
      SET_INVOICE_BY_ID: (state, payload) => {
        if(payload) {
          state.invoiceById = payload;
        } else {
          state.invoiceById = {};
        }
      },
      SET_INVOICE_REPORT_QUERY: (state, payload) => {
        state.invoiceReportQuery = payload;
      },
      SET_INVOICE_REPORT: (state, payload) => {
        state.invoiceReportList = payload;
      },
      SET_TOTAL_BALANCE: (state, paylaod) => {
        state.totalBalanceOfCustomer = paylaod;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      },
      SET_RECEIPT_RECORDS: (state, payload) => {
        state.receiptRecords = payload;
      }
    },
    actions: {
      GetInvoice: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.invoice
              .getInvoice(params)
              .then(({ data }) => {
                context.commit("SET_INVOICE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetChartInvoice: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.invoice
              .getChartInvoice(params)
              .then(({ data }) => {
                context.commit("SET_INVOICE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetTotalBalanceOfCustomer: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.invoice
              .getTotalBalanceOfCustomer(params)
              .then(({ data }) => {
                context.commit("SET_TOTAL_BALANCE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetReceiptRecords: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.invoice
              .getReceiptData(payload)
              .then(({ data }) => {
                context.commit('SET_RECEIPT_RECORDS', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetInvoiceReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_INVOICE_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetInvoice: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.invoice
            .addInvoice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'invoice.message.invoiceSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditInvoice: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.invoice
            .editInvoice(payload.invoice_id, payload.invoice)
            .then((res) => {
              if (res.data.type === 'confirmation') {
                context.commit('SET_BOOKKEEPING_CONFIRMATION', res.data, {root: true});
              }
              let successData = Object.assign({ success: 'invoice.message.invoiceSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetInvoiceById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.invoice
              .getInvoiceById(payload)
              .then(({ data }) => {
                context.commit('SET_INVOICE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteInvoiceItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.invoice
            .deleteInvoice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'invoice.message.invoiceSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetInvoice', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default invoice;