import Vue from "vue";
const supplierCreditInvoice = {
  state: {
    supplierCreditInvoiceList: [],
    supplierCreditInvoiceQuery: null,
    supplierCreditInvoiceReportList: [],
    supplierCreditInvoiceReportQuery: null,
    supplierCreditInvoiceById: {},
    rowIndex: null,
    receiptRecords: []
  },
  getters: {
    supplierCreditInvoiceList: (state) => state.supplierCreditInvoiceList,
    supplierCreditInvoiceQuery: (state) => state.supplierCreditInvoiceQuery,
    supplierCreditInvoiceReportList: (state) => state.supplierCreditInvoiceReportList,
    supplierCreditInvoiceReportQuery: (state) => state.supplierCreditInvoiceReportQuery,
    supplierCreditInvoiceById: (state) => state.supplierCreditInvoiceById,
    rowIndex: (state) => state.rowIndex,
    receiptRecords: (state) => state.receiptRecords,
  },
  mutations: {
    SET_SUPPLIER_CREDIT_INVOICE_QUERY: (state, payload) => {
      state.supplierCreditInvoiceQuery = payload;
    },
    SET_SUPPLIER_CREDIT_INVOICE: (state, payload) => {
      state.supplierCreditInvoiceList = payload;
    },
    SET_SUPPLIER_CREDIT_INVOICE_REPORT_QUERY: (state, payload) => {
      state.supplierCreditInvoiceReportQuery = payload;
    },
    SET_SUPPLIER_CREDIT_INVOICE_REPORT: (state, payload) => {
      state.supplierCreditInvoiceReportList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
    SET_SUPPLIER_CREDIT_INVOICE_BY_ID: (state, payload) => {
      if (payload) {
        state.supplierCreditInvoiceById = payload;
      } else {
        state.supplierCreditInvoiceById = {};
      }
    },
    SET_RECEIPT_RECORDS: (state, payload) => {
      state.receiptRecords = payload;
    }
  },
  actions: {
    GetSupplierCreditInvoice: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierCreditInvoice
            .getSupplierCreditInvoice(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_CREDIT_INVOICE", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] Getorder", err);
      }
    },
    GetReceiptRecords: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierCreditInvoice
            .getReceiptData(payload)
            .then(({ data }) => {
              context.commit('SET_RECEIPT_RECORDS', data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetRoles', err);
      }
    },
    SetUpdateSupplierCreditInvoice: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierCreditInvoice
          .updateSupplierCreditInvoice(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data })
              context.dispatch('SetSnackBar', errorData, { root: true });
            }
            console.log(error)
            reject(error)
          });
      });
    },
    GetSupplierCreditInvoiceReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.salesReport
            .getSalesReport(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_CREDIT_INVOICE_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    GetSupplierCreditInvoiceById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierCreditInvoice
            .getSupplierCreditInvoiceById(payload)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_CREDIT_INVOICE_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetOrder", err);
      }
    },
    DeleteSupplierCreditInvoiceById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierCreditInvoice
          .deleteSupplierCreditInvoice(payload)
          .then((res) => {
            if (res?.data?.type !== "delete") {
              let successData = Object.assign({
                success: "supplierCreditInvoice.message.supplierCreditInvoiceSuccessfullyDeleted",
              });
              context.dispatch("SetSnackBar", successData, { root: true });
            }
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    SetSupplierCreditInvoice: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierCreditInvoice
          .addSupplierCreditInvoice(payload)
          .then((res) => {
            let successData = Object.assign({
              success: "supplierCreditInvoice.message.supplierCreditInvoiceSuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (!error.response?.data?.type) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditSupplierCreditInvoice: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierCreditInvoice
          .editSupplierCreditInvoice(payload.supplier_credit_invoice_id, payload.supplierCreditInvoice)
          .then((res) => {
            let successData = Object.assign({
              success: "supplierCreditInvoice.message.supplierCreditInvoiceSuccessfullyEdited",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (!error.response?.data?.type) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
export default supplierCreditInvoice;
