export default {
    activityLog: 'Activity Log',
    loginLog: 'Login Log',
    activityLogList: 'User Activity Logs',
    log: "Logs",
    save: "Save",
    logType: "{type}",
    id: "ID",
    action: "Action",
    no: "No",
    from: "From date",
    to: "To date",
    activityLogDetailsModal: {
        header: "Log Action Details",
        previous: "Old Details",
        new: "New Details",
        property: "Property",
        user: "User",
        date: "Date",
    },
    field: {
        logName: "Log Name",
        description: "Description",
        subjectType: "Subject Type",
        subjectId: 'Subject ID',
        userId: 'User ID',
        date: 'Date',
    },
}