import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      key: "users",
      paths: ["user", "settings","bankReport", "customerReport", "productReport", "employeeReport", "supplierReport", "quotationReport", "orderReport", "proformaReport", "deliveryReport", "invoiceReport", "creditInvoiceReport", "receiptReport", "depositReport", "accessDbInfo", "personalMenu", "accountingCardPagination", "receiptTaxInvoiceReport", "trialBalancePagination", "returnCertificateReport", "journalEntryReport", "purchaseOrderReport", "supplierDeliveryNoteReport", "supplierInvoiceReport", "supplierPaymentReport", "supplierCreditInvoiceReport"],
    }),
  ],
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
