export default {
  debitReport: 'דו"ח חייבים',
  invoiceDate: "תאריך מסמך",
  paymentDate:"תאריך תשלום",
  group: "קבוצה לקוח",
  customer: 'לקוח',
  agent: "סוכן",
  branch: "סניף/תוקף",
  customerName: "שם לקוח",
  invoiceNumber: 'מספר מסמך',
  customerNumber: 'מספר לקוח',
  originalAmount: "סכום מקורי",
  cumulativeAmount: "סכום מצטבר",
  balance: "יתרה",
  area: "קבוצה אזור",
  tel1: "1 טלפון",
  cel1: "1 סלולר",
  total: 'סה"כ',
  listOfItems: "רשימת פריטים",
  from: "מ",
  to: "עד",
  amount: "סכום",
  filters: "מסננים",
  resetFilter: "אפס מסנן",
  fromInvoiceDate:"מתאריך מסמך",
  toInvoiceDate:"לתיעוד תאריך",
  fromPaymentDate:"מ תאריך תשלום",
  toPaymentDate:"עד תאריך תשלום",
  focused: "מְרוּכָּז",
  reset: "אתחול",
  execute: 'בצע',
  excel: "Excel",
  type: "סוּג",
  creditinvoice: "חשבונית זיכוי",
  receipts: "קבלה"
}