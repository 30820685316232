export default {
  creditInvoice: 'חשבונית זיכוי',
  creditInvoiceActions: "פעולות חשבונית זיכוי",
  editCreditInvoice: "ערוך חשבונית זיכוי",
  addCreditInvoice: "הוסף חשבונית זיכוי",
  save:"שמור",
  creditInvoiceList: "רשימת חשבוניות",
  creditInvoiceItem: "פריט חשבונית זיכוי",
  id: "קוד",
  action: "פעילות",
  no: "מס' שורה",
  product: "פריט",
  quantity: "כמות",
  unitPrice: "מחיר יחידה",
  lineDiscount: "הנחה",
  itemDescription: "תאור פריט",
  total: 'סה"כ',
  creditInvoiceDetail: "פרטי חשבונית זיכוי",
  customerDetail: "מידע לקוח",
  additionalCustomerDetail: "פרטי לקוח נוספים",
  listOfItems: "רשימת פריטים",
  from: "מ",
  to: "עד",
  fromDelivery: ":מ תעודת משלוח",
  fromOrder: ":מ הזמנה",
  createFromMultiple: "צור מרובה",
  prepareInvoice: "הכן חשבונית זיכוי",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "חשבונית זיכוי",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerGroup: "קבוצה של לקוחות",
  creditInvoiceStatus: 'סטטוס חשבוניות זיכוי',
  message: {
    creditInvoiceSuccessfullyDeleted: "החשבונית זיכוי נמחקה בהצלחה",
    creditInvoiceSuccessfullyAdded: "החשבונית זיכוי נוספה בהצלחה",
    creditInvoiceSuccessfullyEdited: "החשבונית זיכוי נערכה בהצלחה",
    updateInvoiceTotal: "התשלום הכולל של החשבונית צריך להיות נמוך מהסכום הכולל",
    updateReceiptTaxInvoiceTotal: "סכום התשלום חייב להיות זהה לסכום החשבונית.",
  },
  field: {
    customer: "לקוח",
    customerName: "שם לקוח",
    creditInvoiceDate: "תאריך חשבונית זיכוי",
    paymentDate:"תאריך תשלום",
    title: "כותרת לחשבונית זיכוי",
    address: "כתובת לקוח",
    town: "עיר",
    country: "מדינה",
    tel1: "טל. נוסף 1",
    tel2: "טל. נוסף 2",
    cel: "נוסף סלולרי",
    fax: "נוסף פקס",
    email: "אי מייל",
    authorizedDealerNo: "מספר עוסק מורשה",
    contact: "איש קשר",
    agent: "סוכן",
    remarks: "הערות",
    timePrinted: "שעת הדפסה",
    orderNo: "מספר הזמנה",
    status: "מצב חשבונית זיכוי",
    total: 'סה"כ',
    discountPrecentage: "אחוז הנחה",
    discount: "הנחה",
    afterDiscount: "לאחר הנחה",
    vat: 'מע"מ',
    vatPrecentage: 'אחוז מע"מ',
    grandTotal: 'סה"כ סופי',
    all: "כולם",
    open: "פתוח",
    finished: "סגור",
    canceled: "מבוטל",
    customerOrderNo: "הזמנת לקוח",
    invoiceNo: "חשבונית זיכוי מס.",
    invoiceYear: "שנת חשבונית זיכוי",
    carNo: "מספר רכב",
    deliveryCompany: "חברת שליחויות",
    balance: "יתרה",
    accountUpdate: 'הנה"ח',
    year: "שָׁנָה",
    contactId: "זיהוי איש קשר",
    customerId: "מספר לקוח",
    employeeId: "כרטיס עובד",
    quotationId: "זיהוי הצעת מחיר",
    orderId: "מספר הזמנה",
    proformaInvoiceId: "זיהוי חשבונית פרופורמה",
    deliveryNoteId: "מזהה תעודת משלוח",
    deliveryDate: "תאריך משלוח",
    invoiceDate: "תאריך חשבונית",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
  }
}