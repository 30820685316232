export default {
  remarks: 'Remarks',
  save: "Save",
  remarksList: "Remarks List",
  date: 'Date',
  time: 'Time',
  employee: 'Employee',
  action: 'Action',
  message: {
    remarksSuccessfullyDeleted: "Remarks cancelled successfully",
    remarksSuccessfullyAdded: "Remarks added successfully",
    remarksSuccessfullyEdited: "Remarks edited successfully",
    remarksSuccessfullySaved: "Remarks saved successfully",
    personalMenuSuccessfullyEdited: "Personal menu edited successfully"
  },
}