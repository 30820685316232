import Vue from 'vue';
const creditInvoice = {
    state: {
      creditInvoiceList: [],
      creditInvoiceById: {},
      creditInvoiceQuery: null,
      creditInvoiceReportList: [],
      creditInvoiceReportQuery: null,
      rowIndex: null,
    },
    getters: {
      creditInvoiceList: (state) => state.creditInvoiceList,
      creditInvoiceById: (state) => state.creditInvoiceById,
      creditInvoiceQuery: (state) => state.creditInvoiceQuery,
      creditInvoiceReportList: (state) => state.creditInvoiceReportList,
      creditInvoiceReportQuery: (state) => state.creditInvoiceReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_CREDIT_INVOICE_QUERY: (state, payload) => {
        state.creditInvoiceQuery = payload;
      },
      SET_CREDIT_INVOICE: (state, payload) => {
        state.creditInvoiceList = payload;
      },
      SET_CREDIT_INVOICE_BY_ID: (state, payload) => {
        if(payload) {
          state.creditInvoiceById = payload;
        } else {
          state.creditInvoiceById = {};
        }
      },
      SET_CREDIT_INVOICE_REPORT_QUERY: (state, payload) => {
        state.creditInvoiceReportQuery = payload;
      },
      SET_CREDIT_INVOICE_REPORT: (state, payload) => {
        state.creditInvoiceReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetCreditInvoice: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.creditInvoice
              .getCreditInvoice(params)
              .then(({ data }) => {
                context.commit("SET_CREDIT_INVOICE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetCreditInvoiceReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_CREDIT_INVOICE_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetCreditInvoice: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.creditInvoice
            .addCreditInvoice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'creditInvoice.message.creditInvoiceSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditCreditInvoice: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.creditInvoice
            .editCreditInvoice(payload.credit_invoice_id, payload.creditInvoice)
            .then((res) => {
              if (res.data.type === 'confirmation') {
                context.commit('SET_BOOKKEEPING_CONFIRMATION', res.data, {root: true});
              }
              let successData = Object.assign({ success: 'creditInvoice.message.creditInvoiceSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetCreditInvoiceById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.creditInvoice
              .getCreditInvoiceById(payload)
              .then(({ data }) => {
                context.commit('SET_CREDIT_INVOICE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteCreditInvoiceItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.creditInvoice
            .deleteCreditInvoice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'creditInvoice.message.creditInvoiceSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetInvoice', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default creditInvoice;