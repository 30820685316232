export default {
  role: "הרשאות",
  rolesActions: "הרשאות",
  editRole: "עריכה",
  addRole: "הוספה",
  addRoles: "הוספת הרשאות",
  roleName: "הוספת שמות",
  roleHeName: "שם עברי",
  description: "תאור",
  save: "שמור",
  rolesList: "רשימת תפקידים",
  id: "קוד",
  action: "פעולה",
  rolePermissions: "הרשאות תפקיד",
  create: "רישום חדש",
  show: "הצגה",
  edit: "עריכה",
  delete: "מחיקה/ביטול",
  financial: "מידע כספי",
  bookkeeping: 'הנה”ח',
  Employee: "עובד",
  message: {
    roleSuccessfullyDeleted: "התפקיד נמחק בהצלחה",
    roleSuccessfullyAdded: "התפקיד נוסף בהצלחה",
    roleSuccessfullyEdited: "התפקיד נערך בהצלחה",
  }
}