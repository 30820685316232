export default {
  auxiliaryTable: "קטגוריה",
  description: "תאור",
  days: "ימים",
  months: "חודשים",
  current: "שוטף",
  orderBy: "מיין לפי",
  save:"שמור",
  noDataFound: "אי מידע",
  listOfTables: "רשימת קטגוריות",
  selectTable: "בחר טבלה",
  addTableName: "הוספת שם טבלה",
  editTableName: "עריכת שם טבלה",
  tableName: "שם הטבלה",
  editValue: "ערוך",
  addNewValue: "הוסף",
  confirm: "אשר",
  alert: "עֵרָנִי",
  cancel: "לְבַטֵל",
  confirmMessage: "?האם לבטל ערך זה",
  message: {
    auxiliaryTableNameSuccessfullyDeleted: "שם טבלה נמחק בהצלחה",
    auxiliaryTableNameSuccessfullyAdded: "שם טבלה נוסף בהצלחה",
    auxiliaryTableNameSuccessfullyEdited: "שם טבלה נערך בהצלחה",
    auxiliaryTableValueSuccessfullyDeleted: "הערך בטבלה נמחק בהצלחה",
    auxiliaryTableValueSuccessfullyAdded: "הערך בטבלה נוסף בהצלחה",
    auxiliaryTableValueSuccessfullyEdited: "הערך בטבלה נערך בהצלחה",
  },
}