import Vue from "vue";
const journalEntry = {
  state: {
    journalEntryList: [],
    journalEntryQuery: null,
    journalEntryById: {},
    journalEntryReportList: [],
    journalEntryReportQuery: {},
  },
  getters: {
    journalEntryList: (state) => state.journalEntryList,
    journalEntryQuery: (state) => state.journalEntryQuery,
    journalEntryById: (state) => state.journalEntryById,
    journalEntryReportList: (state) => state.journalEntryReportList,
    journalEntryReportQuery: (state) => state.journalEntryReportQuery,
  },
  mutations: {
    SET_JOURNAL_ENTRY: (state, payload) => {
      state.journalEntryList = payload;
    },
    SET_JOURNAL_ENTRY_QUERY: (state, payload) => {
      state.journalEntryQuery = payload;
    },
    SET_JOURNAL_ENTRY_BY_ID: (state, payload) => {
      if (payload) {
        state.journalEntryById = payload;
      } else {
        state.journalEntryById = {};
      }
    },
    SET_JOURNAL_ENTRY_REPORT: (state, payload) => {
      state.journalEntryReportList = payload;
    },
    SET_JOURNAL_ENTRY_REPORT_QUERY: (state, payload) => {
      state.journalEntryReportQuery = payload;
    },
  },
  actions: {
    GetJournalEntry: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.journalEntry
            .getJournalEntry(params)
            .then(({ data }) => {
              context.commit("SET_JOURNAL_ENTRY", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] Getorder", err);
      }
    },
    JournalEntryReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.salesReport
            .getSalesReport(params)
            .then(({ data }) => {
              context.commit("SET_JOURNAL_ENTRY_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetRoles', err);
      }
    },
    GetJournalEntryById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.journalEntry
            .getJournalEntryById(payload)
            .then(({ data }) => {
              context.commit("SET_JOURNAL_ENTRY_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetOrder", err);
      }
    },
    SetJournalEntry: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.journalEntry
          .addJournalEntry(payload)
          .then((res) => {
            let successData = Object.assign({
              success: "journalEntry.message.journalEntrySuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditJournalEntry: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.journalEntry
          .editJournalEntry(payload.invoice_id, payload.journalEntry)
          .then((res) => {
            let successData = Object.assign({
              success: "journalEntry.message.journalEntrySuccessfullyEdited",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    StatusClosedJournalEntry: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.journalEntry
          .statusClosed(payload.invoice_id, payload.journalEntry)
          .then((res) => {
            let successData = Object.assign({
              success: "journalEntry.message.journalEntrySuccessfullyEdited",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};

export default journalEntry;
