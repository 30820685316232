export default {
  testByBina: 'Test Software By BINA',
  companyAddress: "POBox 274 Givat Shmuel 5410102 Israel",
  fax: "Fax",
  tel: "Tel.",
  vatRegNo: "VAT Reg. No.",
  customerInvoiceNo: "Invoice Doc Number.",
  To: "To",
  att: "Att.",
  date: "Date",
  ourNumber: "Our Number",
  yourOrder: "Your order",
  validTtill: "Delivery date",
  printedAt: "Printed at",
  printingHr: "Printing hr",
  page: "Page",
  of: "Of",
  no: "No",
  product: "Item",
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lineDiscount: "Line Discount",
  itemDescription: "Item Description",
  total: "Total",
  totalPerReport: "Total Per Report",
  totalPerPaymentType: "Total Per Payment Type",
  withRegards: "With Regards",
  generatePDF: "Generate PDF",
  previewPDF: "Preview PDF",
  time: "Time",
  original: "Original",
  copy: "Copy",
  cancelled: "Cancelled",
  draft: "Draft",
  paymentDetails: "Payment Details",
  message: {
    generatingPdf: "Generating PDF",
  },
  field: {
    total: "Total",
    discountPrecentage: "Discount percentage",
    discount: "Discount",
    afterDiscount: "After discount",
    vat: "VAT",
    vatPrecentage: "Vat percentage",
    grandTotal: "Grand total",
  }
}
