import Vue from 'vue';
const documentSeries = {
    state: {
      documentSeriesList: [],
      documentSeriesQuery: null
    },
    getters: {
      documentSeriesList: (state) => state.documentSeriesList,
      documentSeriesQuery: (state) => state.documentSeriesQuery
    },
    mutations: {
      SET_SERIES_DOCUMENT_QUERY: (state, payload) => {
        state.documentSeriesQuery = payload;
      },
      SET_SERIES_DOCUMENT: (state, payload) => {
        state.documentSeriesList = payload;
      },
    },
    actions: {
      GetDocumentSeries: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.documentSeries
              .getDocumentSeries(params)
              .then(({ data }) => {
                context.commit("SET_SERIES_DOCUMENT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      SetDocumentSeries: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.documentSeries
            .addDocumentSeries(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'documentSeries.message.documentSeriesSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeleteSeriesDocument:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.documentSeries
            .deleteDocumentSeries(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'documentSeries.message.documentSeriesSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default documentSeries;