import Vue from 'vue';
const roles = {
    state: {
      rolesList: [],
      roleById: {},
      roleQuery: null,
      allPermissions: [],
    },
  
    getters: {
      rolesList: (state) => state.rolesList,
      roleById: (state) => state.roleById,
      roleQuery: (state) => state.roleQuery,
      allPermissions: (state) => state.allPermissions,
    },
    mutations: {
      SET_ROLE_QUERY: (state, payload) => {
        state.roleQuery = payload;
      },
      GET_ALL_ROLES: (state, payload) => {
        state.rolesList = payload;
      },
      GET_ALL_PERMISSIONS: (state, payload) => {
        state.allPermissions = payload;
      },
      SET_ROLE_BY_ID: (state, payload) => {
        if(payload) {
          state.roleById = payload;
        } else {
          state.roleById = {};
        }
      },
    },
    actions: {
      GetRoles: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.role
              .getRoles(params)
              .then(({ data }) => {
                context.commit("GET_ALL_ROLES", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetAllPermissions: async (context) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.permission
              .getPermission()
              .then(({ data }) => {
                context.commit("GET_ALL_PERMISSIONS", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      EditRoles: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.role
            .editRoles(payload.role_id, payload.role)
            .then(async (res) => {
              if(payload.addNewPermissions.length > 0){
                await context.dispatch('SetItemPermissionById', {role_id: payload.role_id, permission_ids: payload.addNewPermissions});
              }
              if (payload.deletePermissions.length > 0) {
                await context.dispatch('DeleteItemPermissionById', {id: payload.role_id, permission_ids: payload.deletePermissions});
              }
              let successData = Object.assign({ success: 'roles.message.roleSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      SetRoles: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.role
            .addRoles(payload.role)
            .then(async (res) => {
              await context.dispatch('SetItemPermissionById', {role_id: res.data.id, permission_ids: payload.permission_ids});
              let successData = Object.assign({ success: 'roles.message.roleSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      SetItemPermissionById:async (_, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.role
            .addRolesPermissions(payload.role_id, {permission_ids:  payload.permission_ids})
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            });
        });
      },
      GetRoleById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.role
              .getRolesById(payload)
              .then(({ data }) => {
                context.commit('SET_ROLE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteItemById:async (context, payload) => {
        if(payload.permission_ids.length > 0) {
          context.dispatch('DeleteItemPermissionById', payload);
        }
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.role
            .deleteRoles(payload.id)
            .then((res) => {
              let successData = Object.assign({ success: 'roles.message.roleSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData);
              // context.dispatch('GetRoles', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeleteItemPermissionById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.role
            .deleteRolesPermissions(payload.id, payload.permission_ids)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            });
        });
      },
    },
  }
  export default roles;