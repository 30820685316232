export default {
    returnCertificate: "Return Certificate",
    returnCertificateList: "Return Certificate List",
    debitReport: "Return Certificate Report",
    returnCertificateTime: "Return Certificate Time",
    returnCertificateDate: "Return Certificate Date",
    returnCertificateCompany: "Return Certificate Company",
    returnCertificateNo: "Return Certificate No",
    returnCertificateStatus: "Return Certificate Status",
    docNumber: "Return Certificate Doc Number.",
    returnCertificateAction: "Return Certificate Action",
    addReturnCertificate: "Add Return Certificate",
    createCreditInvoice: "Save & Create Credit Invoice",
    deliveryNo: "Delivery no.",
    returnCertificateDetail: "Return Certificate Detail",
    returnCertificateItems: "Return Certificate Items",
    createReturnCertificate:"Create Return Certificate",
    message: {
        returnCertificateSuccessfullyAdded: "Return Certificate Successfully Added",
        returnCertificateSuccessfullyEdited: "Return Certificate Successfully Edited",
        returnCertificateSuccessfullyDeleted: "Return Certificate Successfully Deleted",
    }
}