export default {
    id: "ID",
    other: "Others",
    description: "Description",
    accountNumber: "Account No.",
    otherAction: "Other Action",
    addOther: "Add Other",
    relativeNumber: "Relative Number",
    actions: "Actions",
    options: "Options",
    copy: "Copy & Create New",
    activityLog: "Activity Log",
    save: "Save",
    balanceSheetSection: "Balance Sheet Section",
    grossProfit: "Gross Profit",
    otherList: "Other List",
    message: {
        otherSuccessfullyDeleted: "Others cancelled successfully",
        otherSuccessfullyAdded: "Others added successfully",
        otherSuccessfullyEdited: "Others edited successfully",
    },
}