export default {
    calendar: "Calendar",
    customerName: "Customer Name",
    address: "Address",
    startTime: "Start Time",
    endTime: "End Time",
    title: "Event Title",
    details: "Event Description",
    startDate: "Event Start Date",
    endDate: "Event End Date",
    employee: "Empolyee",
    google: "Google",
    outlook: "Outlook",
    priority: "Priority",
    high: "High",
    medium: "Medium",
    low: "Low",
    eventType: "Event Type",
    time: "Time",
    date: "Date",
    calendarEvents: "Calendar Events",
    delete: "Delete Event",
    open: "Open",
    done: "Done",
    canceled: "Cancel",
    message: {
        calendarSuccessfullyAdded: "Calendar added successfully",
        calendarSuccessfullyDeleted: "Calendar cancelled successfully",
        calendarSuccessfullyEdited: "Calendar edited successfully"
    },
    error: {
        dateIssue : "Start date cannot be greater than end date.",
    }
}