export default {
  purchaseOrderList: "רשימת הזמנת רכש",
  supplier: "ספק",
  supplierGroup: "קבוצה ספק",
  orderDate: "תאריך הזמנה",
  requiredDeliveryDate: "תאריך אספקה",
  actualDeliveryDate: "תאריך אספקה בפועל",
  supplierName: "שם ספק",
  purchaseOrderAction: "פעולת הזמנת רכש",
  purchaseOrderItems: "פריטי הזמנה לרכישה",
  purchaseOrderDetail: "פרטי הזמנת רכש",
  purchaseOrderStatus: "מצב הזמנה",
  quotationNo: "מספר הצעת מחיר",
  supplierDetails: "פרטי ספק",
  orderNo: "מספר הזמנה",
  supplierNo: "קוד ספק",
  address: "כתובת ספק",
  town: "עיר",
  country: "מדינה",
  tel1: "טל. נוסף 1",
  tel2: "טל. נוסף 2",
  cel: "נוסף סלולרי",
  fax: "נוסף פקס",
  authorisedDealorNo: "מספר עוסק מורשה",
  agent: "סוכן",
  title: "כותרת להזמנה",
  remarks: "הערות",
  customerOrderNo: "מספר הזמנת לקוח",
  status: "מצב",
  total: 'סה"כ',
  discountPercentage: "אחוז הנחה",
  discount: "הנחה",
  afterDiscount: "לאחר הנחה",
  vat: 'מע"מ',
  vatPercentage: 'אחוז מע"מ',
  grandTotal: 'סה"כ סופי',
  recordId: "מספר רשומה",
  remainder: "נותר",
  purchaseOrderNumber: "מספר הזמנת רכש",
  message: {
    purchaseOrderSuccessfullyDeleted: "הזמנת הרכש בוטלה בהצלחה",
    purchaseOrderSuccessfullyAdded: "הזמנת רכש נוספה בהצלחה",
    purchaseOrderSuccessfullyEdited: "הזמנת רכש נערכה בהצלחה",
  },
};
