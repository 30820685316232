export default {
    id: "ID",
    miscellaneous: "Miscellaneous",
    save: "Save",
    relativeNumber: "Relative Number",
    vatDebitCredit: "VAT debit/credit",
    balanceCancelation: "Balance Cancelation",
    employeePrefix: "Prefix for employee",
    customerPrefix: "Prefix for customer",
    supplierPrefix: "Prefix for Supplier",
    referenceDateLimitFrom: "Reference date limit from",
    referenceDateLimitUntill: "Reference date limit untill",
    referenceTwoDateLimitFrom: "Reference2 date limit from",
    referenceTwoDateLimitUntill: "Reference2 date limit until",
    bankNotesDateLimitFrom: "Bank notes date limit from",
    bankNotesDateLimitUntill: "Bank notes date limit untill",
    basicNumbers: "Basic Numbers",
    prefixNumbers: "Prefix Numbers",
    referenceDates: "Reference Dates"
};