import Vue from "vue";

const multiple = {
  state: {
    periodClosureConfirmation: null
  },
  getters: {
    periodClosureConfirmation: (state) => state.periodClosureConfirmation
  },
  mutations: {
    SET_PERIOD_CLOSURE: (state, payload) => {
      state.periodClosureConfirmation = payload;
    },
  },
  actions: {
    GetQuotationItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getQuotationItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetOrderItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getOrderItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetProformaItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getProformaItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetDeliveryItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getDeliveryItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetInvoiceItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getInvoiceItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetCreditInvoiceItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getCreditInvoiceItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetReceiptTaxItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getReceiptTaxInvoiceItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetReturnCertificateItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getReturnCertfifcateItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetPurchaseOrderItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getPurchaseOrderItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetSupplierDeliveryNoteItems: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.multiple
          .getSupplierDeliveryNoteItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};

export default multiple;
