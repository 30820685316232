export default {
  proforma: 'Proforma Invoice',
  proformaActions: "Proforma Invoice Actions",
  editProforma: "Edit Proforma Invoice",
  addProforma: "Add Proforma Invoice",
  save: "Save",
  proformaList: "Proforma Invoice List",
  id: "ID",
  action: "Action",
  no: "No",
  product: "Item",
  proformaItem: 'Proforma Invoice Item',
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lineDiscount: "Line Discount",
  itemDescription: "Item Description",
  total: "Total",
  proformaDetail: "Proforma Invoice Detail",
  createReceipt: "Create Receipt",
  customerDetail: "Customer Detail",
  additionalCustomerDetail: "Additional Customer Details",
  listOfItems: "List Of Items",
  from: "From",
  to: "To",
  createFromMultiple: "Create from multiple",
  prepareProforma: "Prepare proforma",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Proforma Invoice Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerGroup: "Customer Group",
  message: {
    proformaSuccessfullyDeleted: "Proforma invoice cancelled successfully",
    proformaSuccessfullyAdded: "Proforma invoice added successfully",
    proformaSuccessfullyEdited: "Proforma invoice edited successfully",
  },
  field: {
    customer: "Customer",
    customerName: "Customer Name",
    proformaDate:"Proforma Invoice Date",
    deliveryDate:"Delivery Date",
    paymentDate:"Payment Date",
    title: "Title",
    address: "Address",
    town: "Town",
    country: "Country",
    tel1: "Tel 1",
    tel2: "Tel 2",
    cel: "Cel",
    fax: "Fax",
    email: "E-mail",
    authorizedDealerNo: "Authorized dealer number",
    contact: "Contact",
    agent: "Employee",
    remarks: "Remarks",
    timePrinted: "Time printed",
    orderNo: "Order no.",
    proformaNo: "Proforma invoice no.",
    proformaYear: "Proforma invoice year",
    status: "Status",
    total: "Total",
    discountPrecentage: "Discount percentage",
    discount: "Discount",
    afterDiscount: "After discount",
    vat: "VAT",
    vatPrecentage: "Vat percentage",
    grandTotal: "Grand total",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    customerOrderNo: "Customer Order No.",
    quotationNo: "Quotation No.",
    receiptNo: "Receipt No.",
    carNo: "Car No.",
    order: "Order",
    deliveryCompany: "Delivery Company",
    balance: "Balance",
    accountUpdate: "Account",
    year: "Year",
    contactId: "Contact Id",
    customerId: "Customer Id",
    employeeId: "Employee Id",
    orderId: "Order Id",
    deliveryNoteId: "Delivery Note Id",
    createdAt: "Created At",
    updatedAt: "Updated At",
    proformaStatus: "Proforma invoices status"
  }
}