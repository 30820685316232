import Vue from 'vue';
const employee = {
    state: {
      employeeList: [],
      employeeById: {},
      employeeQuery: null,
      employeeReportList: [],
      employeeReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      employeeList: (state) => state.employeeList,
      employeeById: (state) => state.employeeById,
      employeeQuery: (state) => state.employeeQuery,
      employeeReportList: (state) => state.employeeReportList,
      employeeReportQuery: (state) => state.employeeReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_EMPLOYEE_QUERY: (state, payload) => {
        state.employeeQuery = payload;
      },
      SET_EMPLOYEE: (state, payload) => {
        state.employeeList = payload;
      },
      SET_EMPLOYEE_BY_ID: (state, payload) => {
        if(payload) {
          state.employeeById = payload;
        } else {
          state.employeeById = {};
        }
      },
      SET_EMPLOYEE_REPORT_QUERY: (state, payload) => {
        state.employeeReportQuery = payload;
      },
      SET_EMPLOYEE_REPORT: (state, payload) => {
        state.employeeReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetEmployee: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.employee
              .getEmployee(params)
              .then(({ data }) => {
                context.commit("SET_EMPLOYEE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetEmployeeReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.employee
              .getEmployee(params)
              .then(({ data }) => {
                context.commit("SET_EMPLOYEE_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetEmployee: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.employee
            .addEmployee(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'employee.message.employeeSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditEmployee: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.employee
            .editEmployee(payload.employee_id, payload.employee)
            .then((res) => {
              let successData = Object.assign({ success: 'employee.message.employeeSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetEmployeeById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.employee
              .getEmployeeById(payload)
              .then(({ data }) => {
                context.commit('SET_EMPLOYEE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteEmployeeItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.employee
            .deleteEmployee(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'employee.message.employeeSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetEmployee', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default employee;