import Vue from "vue";
const supplierDebts = {
  state: {
    supplierDebtsList: [],
    supplierDebtsQuery: null,
    rowIndex: null,
  },
  getters: {
    supplierDebtsList: (state) => state.supplierDebtsList,
    supplierDebtsQuery: (state) => state.supplierDebtsQuery,
    rowIndex: (state) => state.rowIndex,
  },
  mutations: {
    SET_SUPPLIER_DEBTS_QUERY: (state, payload) => {
      state.supplierDebtsQuery = payload;
    },
    SET_SUPPLIER_DEBTS: (state, payload) => {
      state.supplierDebtsList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
  },
  actions: {
    GetSupplierDebtsReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierDebtsReport
            .getSupplierDebtsReport(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_DEBTS", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
  },
  namespaced: true,
};
export default supplierDebts;