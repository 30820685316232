import Vue from 'vue';
const supplier = {
    state: {
      supplierList: [],
      supplierById: {},
      supplierQuery: null,
      supplierReportList: [],
      supplierReportQuery: null,
      rowIndex: null,
    },
    getters: {
      supplierList: (state) => state.supplierList,
      supplierById: (state) => state.supplierById,
      supplierQuery: (state) => state.supplierQuery,
      supplierReportList: (state) => state.supplierReportList,
      supplierReportQuery: (state) => state.supplierReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_SUPPLIER_QUERY: (state, payload) => {
        state.supplierQuery = payload;
      },
      SET_SUPPLIER: (state, payload) => {
        state.supplierList = payload;
      },
      SET_SUPPLIER_BY_ID: (state, payload) => {
        if(payload) {
          state.supplierById = payload;
        } else {
          state.supplierById = {};
        }
      },
      SET_SUPPLIER_REPORT_QUERY: (state,payload) => {
        state.supplierReportQuery = payload;
      },
      SET_SUPPLIER_REPORT: (state, payload) => {
        state.supplierReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetSupplier: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.supplier
              .getSupplier(params)
              .then(({ data }) => {
                context.commit("SET_SUPPLIER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetSupplierReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.supplier
              .getSupplier(params)
              .then(({ data }) => {
                context.commit("SET_SUPPLIER_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetSupplier: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplier
            .addSupplier(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'supplier.message.supplierSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditSupplier: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplier
            .editSupplier(payload.supplier_id, payload.supplier)
            .then((res) => {
              let successData = Object.assign({ success: 'supplier.message.supplierSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetSupplierById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.supplier
              .getSupplierById(payload)
              .then(({ data }) => {
                context.dispatch('contact/GetContact', {page: 1, limit: 10, where_and: 'record_type_number,record_type|'+payload+',suppliers'}, {root: true});
                context.commit('SET_SUPPLIER_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteSupplierItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplier
            .deleteSupplier(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'supplier.message.supplierSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetSupplier', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default supplier;