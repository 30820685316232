export default {
    documentSeries: "Document Series",
    series: "Series",
    from: "From No.",
    description: "Description",
    year: "Year",
    invoice: "Invoice",
    creditInvoice: "Credit Invoice",
    proformaInvoice: "Proforma Invoice",
    receipt: "Receipt",
    receiptTaxInvoice: "Receipt Tax Invoice",
    delivery: "Delivery",
    returnCertificate: "Return Certificate",
    action: "Action",
    docNumber: "Doc Number",
    selectNumber: "Select Document Series Number",
    index: "Index",
    message: {
        documentSeriesSuccessfullyAdded: "Document Series added successfully",
        documentSeriesSuccessfullyDeleted: "Document Series cancelled successfully",
        confirm: "Please open a series number for the requested year."
    }
}