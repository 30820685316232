export default {
    id: "ID",
    bookKeeping: "Bookkeeping",
    income: "Income",
    settings: "Settings",
    incomeAction: "Income Actions",
    description: "Description",
    actions: "Actions",
    options: "Options",
    copy: "Copy & Create New",
    activityLog: "Activity Log",
    addIncome: "Add Income",
    accountNumber: "Account No.",
    vatExemptedIncome: "VAT Exempted Income",
    overseasIncome: "Overseas Income",
    vat: "VAT",
    customerWithHoldingTax: "Customer Withholding Tax",
    incomeList: "Income List",
    save: "Save",
    relativeNumber: "Relative Number",
    balanceSheetSection: "Balance Sheet Section",
    grossProfit: "Gross Profit",
    message: {
        bookkeepingSuccessfullyDeleted: "Income cancelled successfully",
        bookkeepingSuccessfullyAdded: "Income added successfully",
        bookkeepingSuccessfullyEdited: "Income edited successfully",
        complusoryNumberSuccessfullyAdded: "Complusory number added successfully",
        complusoryNumberSuccessfullyEdited: "Complusory number edited successfully",
    },
};