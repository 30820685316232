import Vue from 'vue';

const news = {
    state: {
      newsList: [],
      newsById: {},
      newsQuery: null
    },
    getters: {
      newsList: (state) => state.newsList,
      newsById: (state) => state.newsById,
      newsQuery: (state) => state.newsQuery
    },
    mutations: {
      SET_NEWS_QUERY: (state, payload) => {
        state.newsQuery = payload;
      },
      SET_NEWS: (state, payload) => {
        state.newsList = payload;
      },
      SET_NEWS_BY_ID: (state, payload) => {
        if(payload) {
          state.newsById = payload;
        } else {
          state.newsById = {};
        }
      },
    },
    actions: {
      GetNews: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.news
              .getNewsList(params)
              .then(({ data }) => {
                context.commit("SET_NEWS", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetNews: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.news
            .addNews(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'news.message.newsSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditNews: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.news
            .editNews(payload.company_id, payload.company)
            .then((res) => {
              let successData = Object.assign({ success: 'news.message.newsSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetNewsById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.news
              .getNewsById(payload)
              .then(({ data }) => {
                context.commit('SET_NEWS_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteNewsItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.news
            .deleteNews(payload.id)
            .then((res) => {
              let successData = Object.assign({ success: 'news.message.newsSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
  }
  export default news;