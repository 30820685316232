export default {
    id: 'ID',
    expenses: 'Expenses',
    description: "Description",
    accountNumber: "Account No.",
    expensesList: "Expenses List",
    expensesAction: 'Expenses Action',
    purchasesVat: 'Purchases VAT',
    supplierWithHoldingTax: 'Supplier Withholding Tax',
    equipmentPurchaseVat: 'Equipment Purchase VAT',
    addExpenses: 'Add Expenses',
    save: "Save",
    actions: "Actions",
    options: "Options",
    copy: "Copy & Create New",
    relativeNumber: "Relative Number",
    activityLog: "Activity Log",
    grossProfit: "Gross Profit",
    balanceSheetSection: "Balance Sheet Section",
    message: {
        expensesSuccessfullyDeleted: "Expenses cancelled successfully",
        expensesSuccessfullyAdded: "Expenses added successfully",
        expensesSuccessfullyEdited: "Expenses edited successfully",
        complusoryNumberSuccessfullyAdded: "Complusory number added successfully",
        complusoryNumberSuccessfullyEdited: "Complusory number edited successfully",
    },
}