export default {
    journalEntry: "Journal Entry",
    journalEntryList: "Journal Entry List",
    id: "ID",
    opposite: "Opposite",
    referenceOne: "Reference",
    referenceTwo: "Reference 2",
    referenceThree: "Document Date",
    valueDate: "Value Date",
    details: "Details",
    debit: "Debit",
    credit: "Credit",
    amount: "Balance",
    no: "No.",
    action: "Action",
    journalEntryDetail: "Journal Entry Detail",
    listOfJournalEntry: "List Of Journal Entry",
    title: "Title",
    remarks: "Remarks",
    closeAndUpdate: "Close and Update",
    save: "Save",
    status: "Status",
    totalDifference: "Total Difference",
    message: {
        journalEntrySuccessfullyEdited: "Journal Entry edited successfully",
        journalEntrySuccessfullyAdded: "Journal Entry added successfully",
        pdfTemplateSuccessfullyAdded: "Pdf Template added successfully",
        pdfTemplateSuccessfullyDeleted: "Pdf Template cancelled successfully",
        pdfTemplateSuccessfullyEdited: "Pdf Template edited successfully"
    }
}
