export default {
  home: "Home",
  about: "About",
  dashboard: 'Dashboard',
  roles: "Roles",
  companies: "Companies",
  employee: "Employee",
  customer: "Customer",
  customerCatalog: "Customer Catalog",
  customerReport: "Customer Report",
  employeeCatalog: "Employee Catalog",
  employeeReport: "Employee Report",
  bankCatalog: "Bank Catalog",
  bankReport: "Bank Report",
  productCatalog: "Item Catalog",
  productReport: "Item Report",
  supplierCatalog: "Supplier Catalog",
  supplierReport: "Supplier Report",
  accounts: "Indexes",
  documentation: 'Documentation',
  rolesAction: "Roles Action",
  employeeAction: "Employee Action",
  companyAction: "Company Action",
  customerAction: "Customer Action",
  auxiliaryTable: "Category",
  settings: 'Account Settings',
  companySetting: "Company Settings",
  product: "Item",
  fund: "Funds",
  productAction: "Item Action",
  supplier: "Supplier",
  supplierAction: "Supplier Action",
  orderManagement: "Sales",
  quotation: "Quotation",
  quotationAction: "Quotation Action",
  quotationCatalog: "Quotation Catalog",
  quotationReport: "Quotation Report",
  delivery: "Delivery",
  deliveryAction: "Delivery Action",
  deliveryCatalog: "Delivery Catalog",
  deliveryReport: "Delivery Report",
  order: "Order",
  orderAction: "Order Action",
  orderCatalog: "Order Catalog",
  orderReport: "Order Report",
  invoice: "Invoice",
  invoiceAction: "Invoice Action",
  invoiceCatalog: "Invoice Catalog",
  invoiceReport: "Invoice Report",
  receipt: "Receipt",
  receiptAction: "Receipt Action",
  receiptCatalog: "Receipt Catalog",
  receiptReport: "Receipt Report",
  bank: "Bank",
  bankAction: "Bank Action",
  deposit: "Deposit",
  depositAction: "Deposit Action",
  depositCatalog: "Deposit Catalog",
  depositReport: "Deposit Report",
  creditInvoice: "Credit Invoice",
  creditInvoiceAction: "Credit Invoice Action",
  creditInvoiceCatalog: "Credit Invoice Catelog",
  creditInvoiceReport: "Credit Invoice Report",
  debitReport: "Customer Debts",
  salesReport: "Sales Report",
  activityLog: "Activity Logs",
  dbQuery:'DB Query',
  proforma: "Proforma Invoice",
  proformaAction: "Proforma Action",
  proformaCatalog: "Proforma In. Catalog",
  proformaReport: "Proforma In. Report",
  reports: 'Reports',
  personalMenu: "Personal menu for",
  bookKeeping: "Bookkeeping",
  setting: "Settings",
  income: "Income",
  expenses: "Expenses",
  funds: "Funds",
  banks: "Banks",
  other: "Others",
  miscellaneous: "Miscellaneous",
  accountingCardReport: "Accounting Card Report",
  today: "Today",
  addEmployee: "Add Employee",
  addCustomer: "Add Customer",
  addSupplier: "Add Supplier",
  addItem: "Add Item",
  addBank: "Add Bank",
  addQuotation: "Add Quotation",
  addOrder: "Add Order",
  addProforma: "Add Proforma Invoice",
  addDelivery: "Add Delivery",
  addInvoice: "Add Invoice",
  addCreditInvoice: "Add Credit Invoice",
  addReceipt: "Add Receipt",
  addDeposit: "Add Deposit",
  receiptTaxInvoice: "Receipt Tax Invoice",
  addReceiptTaxInvoice: "Add Receipt Tax Invoice",
  receiptTaxInvoiceCatalog: "Receipt Tax Invoice Catalog",
  receiptTaxInvoiceAction: "Receipt Tax Invoice Action",
  receiptTaxInvoiceReport: "Receipt Tax Invoice Report",
  empty: "Empty",
  trialBalance: "Trial Balance",
  priceList: "Price list",
  quotationIncludingVat: "Quotation Including VAT",
  invoiceIncludingVat: "Invoice Including VAT",
  orderIncludingVat: "Order Including VAT",
  creditInvoiceIncludingVat: "Credit Invoice Including VAT",
  proformaIncludingVat: "Proforma Invoice Including VAT",
  receiptTaxInvoiceIncludingVat: "Receipt Tax Invoice Including VAT",
  deliveryIncludingVat: "Delivery Including VAT",
  returnCertificateVat: "Return Certificate VAT",
  news: "News",
  newsAction: "News Action",
  returnCertificate: "Return Certificate",
  addReturnCertificate: "Add Return Certificate",
  returnCertificateCatalog: "Return Certificate Catalog",
  returnCertificateAction: "Return Certificate Action",
  returnCertificateReport: "Return Certificate Report",
  createFromMultiple: "Merging Documents",
  createNewDocument: "Create New Document",
  supplied: "Supplied",
  periodClosure: "Period Closure",
  journalEntry: "Journal Entry",
  addJournalEntry: "Add Journal Entry",
  journalEntryAction: "Journal Entry Action",
  journalEntryCatalog: "Journal Entry Catalog",
  journalEntryReport: "Journal Entry Report",
  purchases: "Purchases",
  purchaseOrder: "Purchase Order",
  addPurchaseOrder: "Add Purchase Order",
  purchaseOrderAction: "Purchase Order Action",
  purchaseOrderCatalog: "Purchase Order Catalog",
  purchaseOrderReport: "Purchase Order Report",
  supplierDeliveryNote: "Supplier Delivery Note",
  addSupplierDeliveryNote: "Add Supplier Delivery Note",
  supplierDeliveryNoteAction: "Supplier Delivery Note Action",
  supplierDeliveryNoteCatalog: "Supplier Delivery Note Catalog",
  supplierDeliveryNoteReport: "Supplier Delivery Note Report",
  supplierInvoice: "Supplier Invoice",
  addSupplierInvoice: "Add Supplier Invoice",
  supplierInvoiceAction: "Supplier Invoice Action",
  supplierInvoiceCatalog: "Supplier Invoice Catalog",
  supplierInvoiceReport: "Supplier Invoice Report",
  supplierCreditInvoice: "Supplier Credit Invoice",
  addSupplierCreditInvoice: "Add Supplier Credit Invoice",
  supplierCreditInvoiceAction: "Supplier Credit Invoice Action",
  supplierCreditInvoiceCatalog: "Supplier Credit Invoice Catalog",
  supplierCreditInvoiceReport: "Supplier Credit Invoice Report",
  purchaseReport: "Purchase Report",
  supplierDebts: "Supplier Debts",
  supplierPayment: "Supplier Payment",
  addSupplierPayment: "Add Supplier Payment",
  supplierPaymentAction: "Supplier Payment Action",
  supplierPaymentCatalog: "Supplier Payment Catalog",
  supplierPaymentReport: "Supplier Payment Report",
  purchaseMerging: "Purchase Merging",
  cashFlow: "Cash Flow Report",
  generalEvaluation: "General Evaluation"
};
