import adminApp from "./adminApp";
import companyApp from "./companyApp";

const files = require.context(".", false, /\.js$/);
const modules = {...adminApp,...companyApp};

files.keys().forEach((key) => {
  if (key === "./index.js") return;
  modules[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;
});

export default modules;
