import Vue from 'vue';
const accessDbInfo = {
    state: {
        isAuthenticated: false
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated
    },
    mutations: {
        SET_AUTHENTICATED: (state,payload) => {
            state.isAuthenticated = payload;
        }
    },
    actions: {
        AccessDbInfo: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.dbQuery
                    .accessDbInfo(payload)
                    .then((res) => {
                        if (res.data.type === "success") {
                            context.commit('SET_AUTHENTICATED', true)
                            let successData = Object.assign({ success: 'dbQuery.message.verifyCode' })
                            context.dispatch('SetSnackBar', successData, { root: true });
                            resolve(res);
                        } else {
                            let errorData = Object.assign({ error: res.data.message })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                            resolve(res);
                        }
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
    },
    namespaced: true,
}
export default accessDbInfo;