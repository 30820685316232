import Vue from 'vue';

const accountingReport = {
    state: {
        accountingCardReportList: [],
        accountingCardReportQuery: null,
        accountingCardNumberList: [],
        accountingCardNumberQuery: null,
        rowIndex: null,
    },
    getters: {
        accountingCardReportList: (state) => state.accountingCardReportList,
        accountingCardReportQuery: (state) => state.accountingCardReportQuery,
        accountingCardNumberList: (state) => state.accountingCardNumberList,
        accountingCardNumberQuery: (state) => state.accountingCardNumberQuery,
        rowIndex: (state) => state.rowIndex,
    },
    mutations: {
        SET_ACCOUNTINGCARD_REPORT_QUERY: (state, payload) => {
            state.accountingCardReportQuery = payload;
        },
        SET_ACCOUNTINGCARD_REPORT: (state, payload) => {
            state.accountingCardReportList = payload;
        },
        SET_ACCOUNTINGCARD_NUMBER_QUERY: (state, payload) => {
            state.accountingCardNumberQuery = payload;
        },
        SET_ACCOUNTINGCARD_NUMBER: (state, payload) => {
            state.accountingCardNumberList = payload;
        },
        SET_ROWINDEX: (state, payload) => {
            state.rowIndex = payload;
        }
    },
    actions: {
        GetAccountingCardReport: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.accountingCardReport
                        .getAccountReport(params)
                        .then(({ data }) => {
                            context.commit("SET_ACCOUNTINGCARD_REPORT", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        },
        GetAccountCardNumber: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.accountingCardReport
                        .getAccountCardNumber(params)
                        .then(({ data }) => {
                            context.commit("SET_ACCOUNTINGCARD_NUMBER", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRoles', err);
            }
        }
    },
    namespaced: true,
}
export default accountingReport;