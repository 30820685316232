import Vue from 'vue';
const media = {
    actions: {
      SetMedia: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.media
            .addMedia(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      SendPdfByEmail: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.media
            .sendPdfByEmail(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'login.emailHasBeenSent'});
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditMedia: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.media
            .editMedia(payload.media_id, payload.formData)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeleteMediaById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.media
            .deleteMedia(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default media;