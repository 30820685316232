import Vue from "vue";
const supplierInvoice = {
  state: {
    supplierInvoiceList: [],
    supplierInvoiceQuery: null,
    supplierInvoiceReportList: [],
    supplierInvoiceReportQuery: null,
    rowIndex: null,
    supplierInvoiceById: {},
  },
  getters: {
    supplierInvoiceList: (state) => state.supplierInvoiceList,
    supplierInvoiceQuery: (state) => state.supplierInvoiceQuery,
    supplierInvoiceReportList: (state) => state.supplierInvoiceReportList,
    supplierInvoiceReportQuery: (state) => state.supplierInvoiceReportQuery,
    rowIndex: (state) => state.rowIndex,
    supplierInvoiceById: (state) => state.supplierInvoiceById,
  },
  mutations: {
    SET_SUPPLIER_INVOICE_QUERY: (state, payload) => {
      state.supplierInvoiceQuery = payload;
    },
    SET_SUPPLIER_INVOICE: (state, payload) => {
      state.supplierInvoiceList = payload;
    },
    SET_SUPPLIER_INVOICE_REPORT_QUERY: (state, payload) => {
      state.supplierInvoiceReportQuery = payload;
    },
    SET_SUPPLIER_INVOICE_REPORT: (state, payload) => {
      state.supplierInvoiceReportList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
    SET_SUPPLIER_INVOICE_BY_ID: (state, payload) => {
      if (payload) {
        state.supplierInvoiceById = payload;
      } else {
        state.supplierInvoiceById = {};
      }
    },
  },
  actions: {
    GetSupplierInvoice: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierInvoice
            .getSupplierInvoice(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_INVOICE", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] Getorder", err);
      }
    },
    GetSupplierInvoiceReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.salesReport
            .getSalesReport(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_INVOICE_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    GetSupplierInvoiceById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierInvoice
            .getSupplierInvoiceById(payload)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_INVOICE_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetOrder", err);
      }
    },
    DeleteSupplierInvoiceById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierInvoice
          .deleteSupplierInvoice(payload)
          .then((res) => {
            if (res?.data?.type !== "delete") {
              let successData = Object.assign({
                success:
                  "supplierInvoice.message.supplierInvoiceSuccessfullyDeleted",
              });
              context.dispatch("SetSnackBar", successData, { root: true });
            }
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    SetSupplierInvoice: async (context, payload) => {
      context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierInvoice
          .addSupplierInvoice(payload)
          .then((res) => {
            let successData = Object.assign({
              success:
                "supplierInvoice.message.supplierInvoiceSuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (error.response?.data?.type === 'error') {
                context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
              } else if (error.response?.data?.type === 'delete') {
                // console.log("")
              } else {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditSupplierInvoice: async (context, payload) => {
      context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierInvoice
          .editSupplierInvoice(
            payload.supplier_invoice_id,
            payload.supplierInvoice
          )
          .then((res) => {
            let successData = Object.assign({
              success:
                "supplierInvoice.message.supplierInvoiceSuccessfullyEdited",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (error.response?.data?.type === 'error') {
                context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
              } else if (error.response?.data?.type === 'delete') {
                // console.log("")
              } else {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
export default supplierInvoice;
