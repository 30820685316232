export default {
    id: "Id",
    journalEntry: "Journal entry",
    year: "Year",
    referenceOne: "Reference 1",
    referenceOneDate: "Reference 1 date",
    referenceTwo: "Reference 2",
    referenceTwoDate: "Reference 2 date",
    documentDetails: "Details",
    title: "Title",
    employee: "Employee",
    debitAccount: "Debit",
    creditAccount: "Credit",
    amount: "Amount",
    invoice: "Invoice",
    creditinvoice: "Credit Invoice",
    receipt: "Receipt",
    deposit: "Deposit",
    receipttaxinvoice: "Receipt Tax Invoice",
    createdAt: "Created at",
}