export default {
  debitReport: 'Customer Debts',
  invoiceDate:"Doc Date",
  paymentDate:"Payment Date",
  group: "Customer group",
  customer: "Customer",
  agent: "Employee",
  branch: "Branch",
  customerName: "Customer Name",
  invoiceNumber: 'Doc Number',
  customerNumber: 'Customer Number',
  originalAmount: "Original Amount",
  cumulativeAmount: "Cumulative Amount",
  balance: "Balance",
  area: "Region group",
  tel1: "Tel 1",
  cel1: "Cel 1",
  total: "Total",
  listOfItems: "List Of Items",
  from: "From",
  to: "To",
  amount: "Amount",
  filters: "Filters",
  resetFilter: "Reset filter",
  fromInvoiceDate:"From doc date",
  toInvoiceDate:"To doc date",
  fromPaymentDate:"From payment date",
  toPaymentDate:"To payment date",
  focused: "Focused",
  reset: "Reset",
  execute: 'Execute',
  excel: "Excel",
  type: "Type",
  creditinvoice: "Credit Invoice",
  receipts: "Receipt"
}