export default {
    accountingCardReport: "Accounting Card Report",
    adjusted: "Adjusted",
    accountNo: "Account No.",
    accountName: "Account Name",
    documentDate: "Document Date",
    valueDate: "Value Date",
    details: "Details",
    journalEntry: "Journal Entry",
    debit: "Debit",
    credit: "Credit",
    balance: "Balance",
    execute: "Execute",
    reset: "Reset",
    filters: "Filters",
    resetFilter: "Reset filter",
    totalCreditAmount: "Total Credit Amount:",
    totalDebitAmount: "Total Debit Amount:",
    difference: "Difference:",
    Invoice: "Invoice",
    Receipt: "Receipt",
    Deposit: "Deposit",
    CreditInvoice: "CreditInvoice",
    fromDate: "From date",
    toDate: "To date",
    fromAccountNumber: "From Account Number",
    toAccountNumber: "To Account Number",
    accountNumber: "Account Number",
    balanceSheetSection: "Account Balance Sheet Section",
    excel: "Excel",
    Income: "Income",
    Expenses: "Expenses",
    Funds: "Funds",
    Banks: "Banks",
    Others: "Others",
    Employee: "Employee",
    Customer: "Customer",
    Supplier: "Supplier",
    Item: "Item",
    Bank: "Bank",
    VATExemptedIncome: "VAT Exempted Income",
    OverseasIncome: "Overseas Income",
    VAT: "VAT",
    CustomerWithholdingTax: "Customer Withholding Tax",
    PurchasesVAT: "Purchases VAT",
    SupplierWithholdingTax: "Supplier Withholding Tax",
    EquipmentPurchaseVAT: "Equipment Purchase VAT",
    Cheque: "Cheque",
    Cash: "Cash",
    CashFunds: "Cash Funds",
    ChequeFunds: "Checks Funds",
    IncomeVAT: "Income VAT",
    groupBy: "Group By",
    ReceiptTaxtInvoice: "Receipt Tax Invoice",
    ReceiptTaxInvoice: "Receipt Tax Invoice",
    showOpeningBalance: "Opening Balance"
}