export default {
  delivery: 'Delivery',
  deliveryActions: "Delivery Actions",
  editDelivery: "Edit Delivery",
  addDelivery: "Add Delivery",
  save: "Save",
  deliveryList: "Delivery List",
  id: "ID",
  action: "Action",
  no: "No",
  product: "Item",
  deliveryItem: 'Delivery Item',
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lineDiscount: "Line Discount",
  itemDescription: "Item Description",
  total: "Total",
  deliveryDetail: "Delivery Detail",
  customerDetail: "Customer Detail",
  additionalCustomerDetail: "Additional Customer Details",
  listOfItems: "List Of Items",
  from: "From",
  to: "To",
  createInvoice: "Save & Create Invoice",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Delivery Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerGroup: "Customer Group",
  message: {
    deliverySuccessfullyDeleted: "Delivery cancelled successfully",
    deliverySuccessfullyAdded: "Delivery added successfully",
    deliverySuccessfullyEdited: "Delivery edited successfully",
  },
  field: {
    customer: "Customer",
    customerName: "Customer Name",
    deliveryDate:"Delivery Date",
    deliveryTime: "Delivery Time",
    title: "Title",
    address: "Address",
    town: "Town",
    country: "Country",
    tel1: "Tel 1",
    tel2: "Tel 2",
    cel: "Cel",
    fax: "Fax",
    email: "E-mail",
    authorizedDealerNo: "Authorized dealer number",
    contact: "Contact",
    agent: "Employee",
    remarks: "Remarks",
    timePrinted: "Time printed",
    orderNo: "Order no.",
    invoiceNo: "Invoice no.",
    invoiceYear: "Invoice year",
    status: "Status",
    total: "Total",
    discountPrecentage: "Discount percentage",
    discount: "Discount",
    afterDiscount: "After discount",
    vat: "VAT",
    vatPrecentage: "Vat percentage",
    grandTotal: "Grand total",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    noInvoiced: "Without Invoice",
    customerOrderNo: "Customer Order No.",
    quotationNo: "Quotation No.",
    deliveryNo: "Delivery No.",
    carNo: "Car No.",
    order: "Order",
    deliveryCompany: "Delivery Company",
    hour: "HH:mm",
    year: "Year",
    contactId: "Contact Id",
    customerId: "Customer Id",
    employeeId: "Employee Id",
    quotationId: "Quotation Id",
    orderId: "Order Id",
    proformaInvoiceId: "Proforma Invoice Id",
    createdAt: "Created At",
    updatedAt: "Updated At",
    deliveryStatus: "Delivery notes status",
    warehouse: "Warehouse Name",
  }
}