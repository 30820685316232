import _axios from "axios";
import store from "../store";
const host = window.location.host;
const parts = host.split('.');
const domainLength = 3;

let API_BASE_URL = "";

if (parts.length === (domainLength - 1) || parts[0] === 'www') {
  API_BASE_URL = process.env.VUE_APP_API_BASE;
} else if (parts[0]) {
  API_BASE_URL = "http://" + parts[0] + "." + process.env.VUE_APP_API_BASE_DOMAIN;
} else {
  // If you want to do something else just comment the line below
  API_BASE_URL = process.env.VUE_APP_API_BASE;
}
export const API_BASE = API_BASE_URL;

// export const API_BASE = process.env.VUE_APP_API_BASE;

export const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "POST",
    // "Access-Control-Allow-Headers": "Content-Type, Authorization"
  },
});

export default () => {
  axios.interceptors.request.use(async (config) => {
    config.headers["Accept-Language"] = store.state.settings.locale;
    if (store.state.user.token) {
      config.headers["Authorization"] = "Bearer " + store.state.user.token.token;
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status == 401) {
        console.log(error.response.status);
        store.dispatch('LogOut');
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );

  return {
    auth: {
      login: (params) => axios.post("/api/login", params),
      forgot: (params) => axios.post("/api/forgot-password", params),
      reset: (params) => axios.post("/api/reset-password", params),
      change: (params) => axios.post("/api/user/change-password", params),
      register: (params) => axios.post("/api/register", params),
      getUserDetail: (username) => axios.get(`api/user/${username}`),
    },
    role: {
      getRoles: (params) => axios.get(`/api/roles?relation=permissions`, { params }),
      getRolesById: (role_id) => axios.get(`/api/roles/${role_id}?relation=permissions,user`),
      addRoles: (params) => axios.post("/api/roles", params),
      editRoles: (role_id, params) => axios.put(`api/roles/${role_id}`, params),
      deleteRoles: (role_id) => axios.delete(`api/roles/${role_id}`),
      addRolesPermissions: (role_id, params) => axios.post(`/api/role/${role_id}/attach-permissions`, params),
      deleteRolesPermissions: (role_id, permission_ids) => axios.delete(`/api/role/${role_id}/detach-permissions`, { headers: { 'Content-Type': 'application/json' }, data: JSON.stringify({ permission_ids: permission_ids }) }),
    },
    permission: {
      getPermission: () => axios.get("/api/permissions")
    },
    company: {
      getCompany: (params) => axios.get("/api/companies", { params }),
      getCompanyById: (company_id) => axios.get(`/api/companies/${company_id}?relation=topLogo,bottomLogo`),
      addCompany: (params) => axios.post("/api/companies", params),
      editCompany: (company_id, params) => axios.put(`api/companies/${company_id}`, params),
      deleteCompany: (company_id) => axios.delete(`api/companies/${company_id}`),
    },
    customer: {
      getCustomer: (params) => axios.get("/api/customers?relation=group,paymentTerms", { params }),
      getCustomerById: (customer_id) => axios.get(`/api/customers/${customer_id}?relation=contacts,paymentTerms,group,status,region,employee,user`),
      addCustomer: (params) => axios.post("/api/customers", params),
      editCustomer: (customer_id, params) => axios.put(`api/customers/${customer_id}`, params),
      deleteCustomer: (customer_id) => axios.delete(`api/customers/${customer_id}`),
      getObligation: (customer_id) => axios.get(`/api/get-obligation-table-data/${customer_id}`),
      getTurnOver: (customer_id) => axios.get(`/api/get-turn-over-data/${customer_id}`),
      getObligationDialogData: (params) => axios.get("/api/get-obligation-turn-over-data", { params })
    },
    contact: {
      getContact: (params) => axios.get("/api/contact", { params }),
      getContactById: (contact_id) => axios.get(`/api/contact/${contact_id}?relation=user`),
      addContact: (params) => axios.post("/api/contact", params),
      editContact: (contact_id, params) => axios.put(`api/contact/${contact_id}`, params),
      deleteContact: (contact_id) => axios.delete(`api/contact/${contact_id}`),
    },
    auxiliarytable: {
      getAuxiliaryZtableName: (params) => axios.get(`/api/ztablename`, { params }),
      // getAuxiliaryZtableValueByName: (table_name) => axios.get(`/api/ztablename?relation=ztables&where=table_name,${table_name}`),
      getAuxiliaryZtableNameId: (ztable_id) => axios.get(`/api/ztablename/${ztable_id}?relation=ztables`),
      addAuxiliaryZtableName: (params) => axios.post("/api/ztablename", params),
      editAuxiliaryZtableName: (ztable_id, params) => axios.put(`api/ztablename/${ztable_id}`, params),
      deleteAuxiliaryZtableName: (ztable_id) => axios.delete(`api/ztablename/${ztable_id}`),
    },
    auxiliarytablevalue: {
      addAuxiliaryZtable: (params) => axios.post("/api/ztable", params),
      editAuxiliaryZtable: (table_id, params) => axios.put(`api/ztable/${table_id}`, params),
      deleteAuxiliaryZtable: (table_id) => axios.delete(`api/ztable/${table_id}`),
    },
    employee: {
      getEmployee: (params) => axios.get(`/api/employees?relation=group,user,role`, { params }),
      getEmployeeById: (employee_id) => axios.get(`/api/employees/${employee_id}?relation=media,group,user,multipleUser`),
      addEmployee: (params) => axios.post("/api/employees", params),
      editEmployee: (employee_id, params) => axios.put(`api/employees/${employee_id}`, params),
      deleteEmployee: (employee_id) => axios.delete(`api/employees/${employee_id}`),
    },
    media: {
      addMedia: (formData) => axios.post("/api/media", formData, { headers: { 'Content-Type': "multipart/form-data" } }),
      editMedia: (media_id, formData) => axios.put(`api/media/${media_id}`, formData, { headers: { 'Content-Type': "multipart/form-data" } }),
      deleteMedia: (media_id) => axios.delete(`api/media/${media_id}`),
      sendPdfByEmail: (formData) => axios.post("/api/sendDocumentByEmail", formData, { headers: { 'Content-Type': "multipart/form-data" } }),
    },
    product: {
      getProduct: (params) => axios.get(`/api/item?relation=group`, { params }),
      getProductById: (product_id) => axios.get(`/api/item/${product_id}?relation=media,group,user`),
      addProduct: (params) => axios.post("/api/item", params),
      editProduct: (product_id, params) => axios.put(`api/item/${product_id}`, params),
      deleteProduct: (product_id) => axios.delete(`api/item/${product_id}`),
      getProductListByPost: (params) => axios.post(`api/dropdown-item-list`, params),
      getItemDiscountOrPrice: (id, params) => axios.get(`api/get-item-discount/${id}/${params}`),
      getSupplierItemDiscountOrPrice: (id, params) => axios.get(`api/get-supplier-item-discount/${id}/${params}`),
    },
    supplier: {
      getSupplier: (params) => axios.get("/api/suppliers?relation=group,status", { params }),
      getSupplierById: (supplier_id) => axios.get(`/api/suppliers/${supplier_id}?relation=contacts,paymentTerms,group,status,region,user`),
      addSupplier: (params) => axios.post("/api/suppliers", params),
      editSupplier: (supplier_id, params) => axios.put(`api/suppliers/${supplier_id}`, params),
      deleteSupplier: (supplier_id) => axios.delete(`api/suppliers/${supplier_id}`),
    },
    profile: {
      getCompanyById: () => axios.get(`/api/company/me?relation=topLogo,bottomLogo,user`),
      editCompany: (params) => axios.put(`/api/company/me`, params),
      addMedia: (formData) => axios.post("/api/company/media", formData, { headers: { 'Content-Type': "multipart/form-data" } }),
      editMedia: (media_id, formData) => axios.put(`/api/company/media/${media_id}`, formData, { headers: { 'Content-Type': "multipart/form-data" } }),
      deleteMedia: (media_id) => axios.delete(`/api/company/media/${media_id}`),
    },
    quotation: {
      getQuotation: (params) => axios.get(`/api/quotation?relation=customer,quotationItems`, { params }),
      getChartQuotation: (params) => axios.get(`/api/quotation`, { params }),
      getQuotationById: (quotation_id) => axios.get(`/api/quotation/${quotation_id}?relation=contact,customer,employee,quotationItems,relatedDocument,relatedInvoices,user,quotationStatus`),
      addQuotation: (params) => axios.post("/api/quotation", params),
      editQuotation: (quotation_id, params) => axios.put(`api/quotation/${quotation_id}`, params),
      deleteQuotation: (quotation_id) => axios.delete(`api/quotation/${quotation_id}`),
    },
    order: {
      getOrder: (params) => axios.get(`/api/order?relation=customer,orderItems`, { params }),
      getChartOrder: (params) => axios.get(`/api/order`, { params }),
      getOrderById: (order_id) => axios.get(`/api/order/${order_id}?relation=contact,customer,employee,orderItems,quotation,relatedDocument,relatedInvoices,user,relatedProformaInvoice,orderStatus`),
      addOrder: (params) => axios.post("/api/order", params),
      editOrder: (order_id, params) => axios.put(`api/order/${order_id}`, params),
      deleteOrder: (order_id) => axios.delete(`api/order/${order_id}`),
    },
    delivery: {
      getDelivery: (params) => axios.get("/api/deliverynote?relation=customer,deliveryNoteItems", { params }),
      getDeliveryById: (delivery_id) => axios.get(`/api/deliverynote/${delivery_id}?relation=customer,contact,employee,quotation,order,deliveryNoteItems,relatedInvoices,user,relatedProformaInvoicesOrder,relatedQuotations,deliveryNoteStatus`),
      addDelivery: (params) => axios.post("/api/deliverynote", params),
      editDelivery: (delivery_id, params) => axios.put(`api/deliverynote/${delivery_id}`, params),
      deleteDelivery: (delivery_id) => axios.delete(`api/deliverynote/${delivery_id}`),
    },
    invoice: {
      getInvoice: (params) => axios.get("/api/invoice?relation=customer,invoiceItems", { params }),
      getChartInvoice: (params) => axios.get("/api/invoice", { params }),
      getInvoiceById: (invoice_id) => axios.get(`/api/invoice/${invoice_id}?relation=customer,contact,employee,order,deliveryNote,invoiceItems,relatedDeliveryNote,relatedOrder,relatedQuotation,relatedReceipts,relatedCreditInvoices,user,relatedProformaInvoice,invoiceStatus`),
      addInvoice: (params) => axios.post("/api/invoice", params),
      editInvoice: (invoice_id, params) => axios.put(`api/invoice/${invoice_id}`, params),
      deleteInvoice: (invoice_id) => axios.delete(`api/invoice/${invoice_id}`),
      getTotalBalanceOfCustomer: (customer_id) => axios.get(`/api/get-total-balance/${customer_id}`),
      getReceiptData: (invoice_id) => axios.get(`/api/get-invoice-receipt-CI/${invoice_id}`),
    },
    activityLog: {
      getActivityLog: (params) => axios.get("/api/activitylog/?relation=user", { params }),
    },
    receipt: {
      getReceipt: (params) => axios.get("/api/receipt?relation=customer", { params }),
      getChartReceipt: (params) => axios.get("/api/receipt", { params }),
      getReceiptById: (receipt_id) => axios.get(`/api/receipt/${receipt_id}?relation=customer,employee,receiptInvoices,receiptPayment,user,receiptStatus`),
      addReceipt: (params) => axios.post("/api/receipt", params),
      editReceipt: (receipt_id, params) => axios.put(`api/receipt/${receipt_id}`, params),
      deleteReceipt: (receipt_id) => axios.delete(`api/receipt/${receipt_id}`),
    },
    bank: {
      getBank: (params) => axios.get("/api/bank", { params }),
      getBankById: (bank_id) => axios.get(`/api/bank/${bank_id}?relation=user`),
      addBank: (params) => axios.post("/api/bank", params),
      editBank: (bank_id, params) => axios.put(`api/bank/${bank_id}`, params),
      deleteBank: (bank_id) => axios.delete(`api/bank/${bank_id}`),
    },
    deposit: {
      getPayment: (params) => axios.get("/api/receipts-payment?relation=receipts,paymentTypes", { params }),
      getReceiptTaxInvoicePayments: (params) => axios.get("/api/receipts-tax-invoice-payment?relation=receiptTaxInvoices,paymentTypes", { params }),
      getDeposit: (params) => axios.get("/api/deposit-payment?relation=depositSub,bank,paymentTypes", { params }),
      getDepositById: (deposit_id) => axios.get(`/api/deposit-payment/${deposit_id}?relation=depositSub,bank,paymentTypes,user`),
      addDeposit: (params) => axios.post("/api/deposit-payment", params),
      editDeposit: (deposit_id, params) => axios.put(`api/deposit-payment/${deposit_id}`, params),
      deleteDeposit: (deposit_id) => axios.delete(`api/deposit-payment/${deposit_id}`),
    },
    creditInvoice: {
      getCreditInvoice: (params) => axios.get("/api/credit-invoice?relation=customer", { params }),
      getCreditInvoiceById: (credit_invoice_id) => axios.get(`/api/credit-invoice/${credit_invoice_id}?relation=customer,contact,employee,creditInvoiceItems,creditInvoicePayments,user,creditInvoiceStatus,relatedReturnCertificate`),
      addCreditInvoice: (params) => axios.post("/api/credit-invoice", params),
      editCreditInvoice: (credit_invoice_id, params) => axios.put(`api/credit-invoice/${credit_invoice_id}`, params),
      deleteCreditInvoice: (credit_invoice_id) => axios.delete(`api/credit-invoice/${credit_invoice_id}`),
    },
    docPagination: {
      getDocumentPagination: (params) => axios.post("/api/documentPagination", params)
    },
    dbQuery: {
      getDBTableList: (params) => axios.get(`/api/getAllTables?${params}`),
      getColumnByTable: (params) => axios.post('/api/get-table-column-name', params),
      postDBQuery: (params) => axios.post("/api/dbtool", params),
      uploadExcel: (params) => axios.post("/api/upload-excel", params),
      accessDbInfo: (params) => axios.post("/api/verify-security-code", params)
    },
    multiUserManagement: {
      postDocumentOpenAction: (params) => axios.post('/api/update-status', params)
    },
    proforma: {
      getProforma: (params) => axios.get("/api/proform-invoice?relation=customer,proformaInvoiceItems", { params }),
      getProformaById: (proforma_id) => axios.get(`/api/proform-invoice/${proforma_id}?relation=customer,contact,employee,proformaInvoiceItems,relatedDeliveryNote,relatedOrder,user,relatedInvoices,proformaInvoicesStatus`),
      addProforma: (params) => axios.post("/api/proform-invoice", params),
      editProforma: (proforma_id, params) => axios.put(`api/proform-invoice/${proforma_id}`, params),
      deleteProforma: (proforma_id) => axios.delete(`api/proform-invoice/${proforma_id}`),
    },
    remarks: {
      getRemarks: (params) => axios.get("/api/remarks?relation=user", { params }),
      addRemarks: (params) => axios.post("/api/remarks", params),
    },
    debitReport: {
      getDebitReport: (params) => axios.get("/api/debit-report?order_by=customer_id|desc", { params }),
    },
    mainSalesReport: {
      getMainSalesReport: (params) => axios.get("/api/main-sales-report", { params })
    },
    salesReport: {
      getSalesReport: (params) => axios.get("/api/sales-report?relation=employee,contact", { params })
    },
    accountingCardReport: {
      getAccountReport: (params) => axios.get("/api/account-card-report", { params }),
      getAccountCardNumber: (params) => axios.get("/api/accounting-card-report?relation=balanceSheetSection", { params })
    },
    receiptSalesReport: {
      getReceiptSalesReport: (params) => axios.get("/api/sales-report?relation=receiptStatus", { params })
    },
    depositSalesReport: {
      getDepositSalesReport: (params) => axios.get("/api/sales-report?relation=paymentTypes,bank", { params })
    },
    bookkeeping: {
      getComplusoryNumbers: (params) => axios.get('/api/bookkeeping', { params }),
      editComplusoryNumbers: (params) => axios.post('/api/bookkeeping/compulsory', params),
      getIncome: (params) => axios.get("/api/bookkeeping-three?relation=balanceSheetSection", { params }),
      getIncomeById: (income_id) => axios.get(`/api/bookkeeping-three/${income_id}`),
      addIncome: (params) => axios.post("/api/bookkeeping", params),
      editIncome: (income_id, params) => axios.put(`api/bookkeeping/${income_id}`, params),
      deleteIncome: (income_id) => axios.delete(`api/bookkeeping/${income_id}`),
    },
    getUserList: {
      getUsers: (params) => axios.get('/api/get-loggedin-user', { params }),
      getUserUpdate: (user_id) => axios.put(`/api/update-user-status/${user_id}`),
      getMenuList: (user_id) => axios.get(`/api/personal-menu/${user_id}`),
      addMenuList: (params) => axios.post('/api/personal-menu', params),
      updateMenuList: (params) => axios.put(`api/personal-menu/${params.data[0].userId}`, params)
    },
    getEmployeeList: {
      getEmployeeListForSignin: (params) => axios.get('/api/get-employee-list', { params })
    },
    getBookkeeping: {
      getBookkeepingRecords: (params) => axios.get(`/api/bookkeeping-main/${params.id}?type=${params.type}`)
    },
    updatedDocuments: {
      updateReceipt: (params) => axios.post('/api/update-documents-receipt', params),
      updateCreditInvoice: (params) => axios.post('/api/update-documents', params)
    },
    receiptTaxInvoice: {
      getReceiptTaxInvoice: (params) => axios.get('/api/receipt_tax_invoice?relation=customer,receiptTaxInvoiceItems,receiptTaxInvoicePayments,employee,order,user', { params }),
      addReceiptTaxInvoice: (params) => axios.post("/api/receipt_tax_invoice", params),
      getReceiptTaxInvoiceById: (tax_id) => axios.get(`/api/receipt_tax_invoice/${tax_id}?relation=customer,receiptTaxInvoiceItems,receiptTaxInvoicePayments,employee,order,user,relatedOrder,relatedQuotation,receiptTaxInvoiceStatus`),
      editReceiptTaxInvoice: (tax_id, params) => axios.put(`api/receipt_tax_invoice/${tax_id}`, params),
      deleteReceiptTaxInvoice: (tax_id) => axios.delete(`/api/receipt_tax_invoice/${tax_id}`),
    },
    attachedDocuments: {
      getAttachedDocuments: (params) => axios.get(`/api/show-attach-file/${params}`),
      addAttachedDocuments: (formData) => axios.post("/api/attach-file", formData, { headers: { 'Content-Type': "multipart/form-data" } }),
      updateAttachedDocuments: (formData) => axios.post("api/update-file", formData, { headers: { 'Content-Type': "multipart/form-data" } }),
      deleteAttachedDocuments: (params) => axios.delete(`api/delete-attach-file/${params}`)
    },
    trialBalance: {
      getTrialBalanceReport: (params) => axios.get("/api/trail-balance-report", { params }),
      getAccountCardData: (params) => axios.get(`/api/get-by-account?account_no|${params}`)
    },
    customerPrice: {
      getCustomerPrice: (params) => axios.get(`/api/customer-price/${params}`),
      addCustomerPrice: (params) => axios.post('api/customer-price', params),
      editCustomerPrice: (id, params) => axios.put(`api/customer-price/${id}`, params),
      deleteCustomerPrice: (id) => axios.delete(`api/customer-price/${id}`),
      checkCustomerIsExist: (params) => axios.post('api/check-pricedata', params)
    },
    supplierPrice: {
      getSupplierPrice: (params) => axios.get(`/api/supplier-price/${params}`),
      addSupplierPrice: (params) => axios.post('api/supplier-price', params),
      editSupplierPrice: (id, params) => axios.put(`api/supplier-price/${id}`, params),
      deleteSupplierPrice: (id) => axios.delete(`api/supplier-price/${id}`),
      checkSupplierIsExist: (params) => axios.post('api/check-supplier-pricedata', params)
    },
    priceList: {
      getPriceList: (params) => axios.get('/api/price-list?relation=pricegroupid,itemgroupid', { params }),
      addPriceList: (params) => axios.post('api/price-list', params),
      editPriceList: (id, params) => axios.put(`api/price-list/${id}`, params),
      deletePriceList: (id) => axios.delete(`api/price-list/${id}`),
      checkListDataIsExist: (params) => axios.post('api/check-customer-price-list', params)
    },
    news: {
      getNewsList: (params) => axios.get("/api/news-slider", { params }),
      getNewsById: (company_id) => axios.get(`/api/news-slider/${company_id}`),
      addNews: (params) => axios.post("/api/news-slider", params),
      editNews: (company_id, params) => axios.put(`api/news-slider/${company_id}`, params),
      deleteNews: (id) => axios.delete(`/api/news-slider/${id}`)
    },
    documentSeries: {
      getDocumentSeries: (params) => axios.get(`api/document-numbering${params}`),
      addDocumentSeries: (params) => axios.post("api/add-document-numbering", params),
      deleteDocumentSeries: (series_id) => axios.delete(`/api/document-numbering/${series_id}`),
    },
    calendar: {
      getCalendarEvents: (params) => axios.get("api/calander-event", { params }),
      getCalendarById: (params) => axios.get(`api/calander-event/${params}&&relation=CalanderEventDetails`),
      addCalendarEvent: (params) => axios.post("api/calander-event", params),
      editCalendarEvent: (event_id, status_one) => axios.put(`api/calander-event/${event_id}`, { status_one }),
      deleteCalendarEvent: (event_id) => axios.delete(`api/calander-event/${event_id}`),
      googleLogin: (params) => axios.get(`api/goggle-login${ params }`),
      outlookLogin: (params) => axios.get(`api/outlook-login${ params }`),
      refreshTokenCheck: (params) => axios.get(`api/check-user-refresh-token${ params }`),
      outlookRefreshToken: (params) => axios.get(`api/check-user-outlook-refresh-token${ params }`),
    },
    returnCertificate: {
      getReturnCertificate: (params) => axios.get('/api/return-certificate?relation=customer,ReturnCertificateItems,employee,user', { params }),
      addReturnCertificate: (params) => axios.post("/api/store-return-certificate", params),
      getReturnCertificateById: (tax_id) => axios.get(`/api/return-certificate/${tax_id}?relation=customer,ReturnCertificateItems,employee,user,returnCertificateStatus,relatedDeliveryNote`),
      editReturnCertificate: (tax_id, params) => axios.put(`api/update-return-certificate/${tax_id}`, params),
      deleteReturnCertificate: (tax_id) => axios.delete(`/api/return-certificate/${tax_id}`),
    },
    multiple: {
      // sales merging
      getQuotationItems: (params) => axios.post('/api/qutation-items', params),
      getOrderItems: (params) => axios.post('/api/order-items', params),
      getProformaItems: (params) => axios.post('/api/proforma-invoice-items', params),
      getInvoiceItems: (params) => axios.post('/api/invoice-items', params),
      getDeliveryItems: (params) => axios.post('/api/delivery-note-items', params),
      getReceiptTaxInvoiceItems: (params) => axios.post('/api/recipt-tax-invoice-items', params),
      getCreditInvoiceItems: (params) => axios.post('/api/credit-invoice-items', params),
      getReturnCertfifcateItems: (params) => axios.post('/api/return-certificate-items', params),
      // purchase merging
      getPurchaseOrderItems: (params) => axios.post('/api/purchase-order-items', params),
      getSupplierDeliveryNoteItems: (params) => axios.post('/api/supplier-delivery-note-items', params),
    },
    periodClosure: {
      getPeriodClosure: () => axios.get('/api/closing-periods'),
      setPeriodClosure: (params) => axios.post('/api/store-closing-periods', params),
      deletePeriodClosure: (id) => axios.delete(`/api/closing-periods/${id}`)
    },
    journalEntry: {
      getJournalEntry: (params) => axios.get('/api/journal-entry', { params }),
      getJournalEntryById: (invoice_id) => axios.get(`/api/journal-entry/${invoice_id}`),
      addJournalEntry: (params) => axios.post("/api/journal-entry", params),
      editJournalEntry: (invoice_id, params) => axios.put(`/api/journal-entry/${invoice_id}`, params),
      statusClosed: (invoice_id, params) => axios.put(`/api/update-journal-entry-status/${invoice_id}`, params),
    },
    pdfTemplate: {
      getPdfTemplate: (params) => axios.get('/api/pdf-dynamic', { params }),
      addPdfTemplate: (params) => axios.post("/api/pdf-dynamic", params),
      editPdfTemplate: (id, params) => axios.put(`/api/pdf-dynamic/${id}`, params),
      deletePdfTemplate: (id) => axios.delete(`/api/pdf-dynamic/${id}`)
    },
    checkRemainingQty: {
      checkRemainingQty: (params) => axios.post("/api/check-remaining-quantity", params),
    },
    purchaseOrder: {
      getPurchaseOrder: (params) => axios.get(`/api/purchase-order?relation=supplier,purchaseOrderItems`, { params }),
      getPurchaseOrderById: (purchase_order_id) => axios.get(`/api/purchase-order/${purchase_order_id}?relation=supplier,employee,purchaseOrderItems,relatedSupplierDeliveryItems,relatedSupplierInvoiceItems`),
      addPurchaseOrder: (params) => axios.post("/api/purchase-order", params),
      editPurchaseOrder: (purchase_order_id, params) => axios.put(`api/purchase-order/${purchase_order_id}`, params),
      deletePurchaseOrder: (purchase_order_id) => axios.delete(`api/purchase-order/${purchase_order_id}`),
    },
    supplierDeliveryNote: {
      getSupplierDeliveryNote: (params) => axios.get(`/api/supplier-delivery-note?relation=employee,supplier,purchaseorder,supplierDeliveryNoteItems`, { params }),
      getSupplierDeliveryNoteById: (supplier_delivery_note_id) => axios.get(`/api/supplier-delivery-note/${supplier_delivery_note_id}?relation=employee,supplier,purchaseorder,supplierDeliveryNoteItems,relatedPurchaseOrderItems,relatedSupplierInvoiceItems`),
      addSupplierDeliveryNote: (params) => axios.post("/api/supplier-delivery-note", params),
      editSupplierDeliveryNote: (supplier_delivery_note_id, params) => axios.put(`api/supplier-delivery-note/${supplier_delivery_note_id}`, params),
      deleteSupplierDeliveryNote: (supplier_delivery_note_id) => axios.delete(`api/supplier-delivery-note/${supplier_delivery_note_id}`),
    },
    supplierInvoice: {
      getSupplierInvoice: (params) => axios.get(`/api/supplier-invoice?relation=employee,supplier,supplierInvoiceItems`, { params }),
      getSupplierInvoiceById: (supplier_invoice_id) => axios.get(`/api/supplier-invoice/${supplier_invoice_id}?relation=employee,supplier,supplierInvoiceItems,relatedPurchaseOrderItems,relatedSupplierDeliveryItems`),
      addSupplierInvoice: (params) => axios.post("/api/supplier-invoice", params),
      editSupplierInvoice: (supplier_invoice_id, params) => axios.put(`api/supplier-invoice/${supplier_invoice_id}`, params),
      deleteSupplierInvoice: (supplier_invoice_id) => axios.delete(`api/supplier-invoice/${supplier_invoice_id}`),
    },
    supplierCreditInvoice: {
      getSupplierCreditInvoice: (params) => axios.get(`/api/supplier-credit-invoice?relation=employee,supplier,supplierCreditInvoiceItems`, { params }),
      getSupplierCreditInvoiceById: (supplier_credit_invoice_id) => axios.get(`/api/supplier-credit-invoice/${supplier_credit_invoice_id}?relation=employee,supplier,supplierCreditInvoiceItems,supplierCreditInvoicePayments`),
      addSupplierCreditInvoice: (params) => axios.post("/api/supplier-credit-invoice", params),
      editSupplierCreditInvoice: (supplier_credit_invoice_id, params) => axios.put(`api/supplier-credit-invoice/${supplier_credit_invoice_id}`, params),
      deleteSupplierCreditInvoice: (supplier_credit_invoice_id) => axios.delete(`api/supplier-credit-invoice/${supplier_credit_invoice_id}`),
      getReceiptData: (supplier_invoice_id) => axios.get(`/api/get-supplier-invoice-receipt-CI/${supplier_invoice_id}`),
      updateSupplierCreditInvoice: (params) => axios.post('/api/update-supplier-credit-balance', params)
    },
    purchaseReport: {
      getPurchaseReport: (params) => axios.get("/api/purchase-sales-report?relation=employee,contact", { params })
    },
    supplierDebtsReport: {
      getSupplierDebtsReport: (params) => axios.get("/api/supplier-debit-report?relation=employee,contact", { params })
    },
    supplierPayment: {
      getSupplierPayment: (params) => axios.get(`/api/supplier-receipt?relation=supplier`, { params }),
      getSupplierPaymentById:  (supplier_receipt_id) => axios.get(`/api/supplier-receipt/${supplier_receipt_id}?relation=supplier,employee,supplierReceiptInvoices,supplierReceiptPayment,user`),
      addSupplierPayment: (params) => axios.post("/api/supplier-receipt", params),
      editSupplierPayment: (supplier_receipt_id, params) => axios.put(`api/supplier-receipt/${supplier_receipt_id}`, params),
      deleteSupplierPaymentById: (supplier_receipt_id) => axios.delete(`api/supplier-receipt/${supplier_receipt_id}`) ,
      updateSupplierReceipt: (params) => axios.post('/api/update-documents-supplier-receipt', params),
    },
    cashFlow: {
      getCashFlowReport: (params) => axios.get("/api/cash-flow-report", { params })
    },
    generalEvaluation: {
      getObligationData: (params) => axios.get(`/api/general-evalution${params}`),
      getDoubleClickData: (params) => axios.get(`/api/general-evalution-detailsReport${params}`),
    }
  };
};
