import Vue from 'vue';
const common = {
    state: {
      showMessageBox: false,
      message: {},
      moreThanOneUserLogout: false,
      userList: [],
      employeeListSignin: [],
      getBookkeepingRecords: [],
    },
  
    getters: {
      showMessageBox: (state) => state.showMessageBox,
      message: (state) => state.message,
      moreThanOneUserLogout: (state) => state.moreThanOneUserLogout,
      userList: (state) => state.userList,
      employeeListSignin: (state) => state.employeeListSignin,
      getBookkeepingRecords: (state) => state.getBookkeepingRecords,
    },
    mutations: {
      SET_SNACK_BAR: (state, payload) => {
        state.showMessageBox = true;
        state.message = payload;
      },
      SET_USER_LOGOUT: (state, payload) => {
        state.moreThanOneUserLogout = payload;
      },
      SET_USERLIST: (state, payload) => {
        state.userList = payload;
      },
      SET_EMPLOYEE_LIST_SIGNIN: (state, payload) => {
        state.employeeListSignin = payload;
      },
      SET_BOOKKEEPING_RECORDS: (state, payload) => {
        state.getBookkeepingRecords = payload;
      }
    },
    actions: {
      GetBookkeepingRecords: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.getBookkeeping
              .getBookkeepingRecords(payload)
              .then(({ data }) => {
                context.commit('SET_BOOKKEEPING_RECORDS', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetUpdateUser: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.getUserList
            .getUserUpdate(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data })
                context.dispatch('SetSnackBar', errorData, { root: true });
              }
              console.log(error)
              reject(error)
            })
        })
      },
      GetUserList: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.getUserList
              .getUsers(params)
              .then(({ data }) => {
                context.commit("SET_USERLIST", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetEmployeeListSignIn: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.getEmployeeList
              .getEmployeeListForSignin(params)
              .then(({ data }) => {
                context.commit("SET_EMPLOYEE_LIST_SIGNIN", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetSnackBar: async (context, payload) => {
        context.commit('SET_SNACK_BAR', payload);
      },
      GetDocumentPagination: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.docPagination
              .getDocumentPagination(params)
              .then(({ data }) => {
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      PostDocumentOpenAction: async (context,params) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.multiUserManagement.postDocumentOpenAction(params)
              .then(({ data }) => {
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      }
    }
  }
  export default common;