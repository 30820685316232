import Vue from 'vue';
const delivery = {
    state: {
      deliveryList: [],
      deliveryById: {},
      deliveryQuery: null,
      deliveryReportList: [],
      deliveryReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      deliveryList: (state) => state.deliveryList,
      deliveryById: (state) => state.deliveryById,
      deliveryQuery: (state) => state.deliveryQuery,
      deliveryReportList: (state) => state.deliveryReportList,
      deliveryReportQuery: (state) => state.deliveryReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_DELIVERY_QUERY: (state, payload) => {
        state.deliveryQuery = payload;
      },
      SET_DELIVERY: (state, payload) => {
        state.deliveryList = payload;
      },
      SET_DELIVERY_BY_ID: (state, payload) => {
        if(payload) {
          state.deliveryById = payload;
        } else {
          state.deliveryById = {};
        }
      },
      SET_DELIVERY_REPORT_QUERY: (state, payload) => {
        state.deliveryReportQuery = payload;
      },
      SET_DELIVERY_REPORT: (state, payload) => {
        state.deliveryReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetDelivery: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.delivery
              .getDelivery(params)
              .then(({ data }) => {
                context.commit("SET_DELIVERY", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data.message})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetDeliveryReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_DELIVERY_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetDelivery: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.delivery
            .addDelivery(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'delivery.message.deliverySuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data.message})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditDelivery: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.delivery
            .editDelivery(payload.delivery_id, payload.delivery)
            .then((res) => {
              let successData = Object.assign({ success: 'delivery.message.deliverySuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data.message})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetDeliveryById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.delivery
              .getDeliveryById(payload)
              .then(({ data }) => {
                context.commit('SET_DELIVERY_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data.message})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteDeliveryItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.delivery
            .deleteDelivery(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'delivery.message.deliverySuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetDelivery', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data.message})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default delivery;