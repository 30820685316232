export default {
  creditInvoice: 'Credit Invoice',
  creditInvoiceActions: "Credit Invoice Actions",
  editCreditInvoice: "Edit Credit Invoice",
  addCreditInvoice: "Add Credit Invoice",
  save: "Save",
  creditInvoiceList: "Credit Invoice List",
  invoiceList: "Invoice List",
  id: "ID",
  action: "Action",
  no: "No",
  product: "Item",
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lineDiscount: "Line Discount",
  itemDescription: "Item Description",
  total: "Total",
  creditInvoiceDetail: "Credit Invoice Detail",
  creditInvoiceItem: "Credit Invoice Item",
  customerDetail: "Customer Detail",
  additionalCustomerDetail: "Additional Customer Details",
  listOfItems: "List Of Items",
  from: "From",
  to: "To",
  createFromMultiple: "Create from multiple",
  prepareInvoice: "Prepare invoice",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Credit Invoice Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  creditInvoiceStatus: "Credit Invoices status",
  customerGroup: "Customer Group",
  message: {
    creditInvoiceSuccessfullyDeleted: "Credit Invoice cancelled successfully",
    creditInvoiceSuccessfullyAdded: "Credit Invoice added successfully",
    creditInvoiceSuccessfullyEdited: "Credit Invoice edited successfully",
    updateInvoiceTotal: "The total payment of the invoice should be less than the total amount.",
    updateReceiptTaxInvoiceTotal: "The payment amount must be the same as the invoice amount.",
  },
  field: {
    customer: "Customer",
    customerName: "Customer Name",
    creditInvoiceDate:"Credit Invoice Date",
    title: "Title",
    address: "Address",
    town: "Town",
    country: "Country",
    tel1: "Tel 1",
    tel2: "Tel 2",
    cel: "Cel",
    fax: "Fax",
    email: "E-mail",
    authorizedDealerNo: "Authorized dealer number",
    contact: "Contact",
    agent: "Employee",
    remarks: "Remarks",
    timePrinted: "Time printed",
    status: "Status",
    total: "Total",
    discountPrecentage: "Discount percentage",
    discount: "Discount",
    afterDiscount: "After discount",
    vat: "VAT",
    vatPrecentage: "Vat percentage",
    grandTotal: "Grand total",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    customerOrderNo: "Customer Order No.",
    carNo: "Car No.",
    balance: "Balance",
    accountUpdate: "Account",
    year: "Year",
    contactId: "Contact Id",
    customerId: "Customer Id",
    employeeId: "Employee Id",
    name: "Name",
    userId: "User Id",
    orderId: "Order Id",
    quotationId: "Quotation Id",
    paymentDate: "Payment Date",
    invoiceDate: "Invoice Date",
    proformaInvoiceId: "Proforma Invoice Id",
    deliveryNoteId: "Delivery Note Id",
    deliveryCompany: "Delivery Company",
    deliveryDate: "Delivery Date",
    createdAt: "Created At",
    updatedAt: "Updated At"
  }
}