import Vue from "vue";

const calendar = {
  state: {
    calendarList: [],
    calendarQuery: null,
    calendarById: {},
    googleLogin: [],
    outlookLogin: [],
    refreshTokenCheck: [],
    outlookRefreshToken: [],
  },
  getters: {
    calendarList: (state) => state.calendarList,
    calendarQuery: (state) => state.calendarQuery,
    calendarById: (state) => state.calendarById,
    googleLogin: (state) => state.googleLogin,
    outlookLogin: (state) => state.outlookLogin,
    refreshTokenCheck: (state) => state.refreshTokenCheck,
    outlookRefreshToken: (state) => state.outlookRefreshToken,
  },
  mutations: {
    SET_CALENDAR_QUERY: (state, payload) => {
      state.calendarQuery = payload;
    },
    SET_CALENDAR: (state, payload) => {
      state.calendarList = payload;
    },
    SET_CALENDAR_BY_ID: (state, payload) => {
      if (payload) {
        state.calendarById = payload;
      } else {
        state.calendarById = {};
      }
    },
    SET_GOOGLE_LOGIN: (state, payload) => {
      state.googleLogin = payload;
    },
    SET_OUTLOOK_LOGIN: (state, payload) => {
      state.outlookLogin = payload;
    },
    SET_REFRESH_TOKEN_CHECK: (state, payload) => {
      state.refreshTokenCheck = payload;
    },
    SET_OUTLOOK_REFRESH_TOKEN_CHECK: (state, payload) => {
      state.outlookRefreshToken = payload;
    }
  },
  actions: {
    GetCalendar: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.calendar
            .getCalendarEvents(params)
            .then(({ data }) => {
              context.commit("SET_CALENDAR", data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetBank', err);
      }
    },
    RefreshTokenCheck: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.calendar
            .refreshTokenCheck(params)
            .then(({ data }) => {
              context.commit("SET_REFRESH_TOKEN_CHECK", data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetBank', err);
      }
    },
    GoogleLogin: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.calendar
            .googleLogin(params)
            .then(({ data }) => {
              context.commit("SET_GOOGLE_LOGIN", data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetBank', err);
      }
    },
    OutlookLogin: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.calendar
            .outlookLogin(params)
            .then(({ data }) => {
              context.commit("SET_OUTLOOK_LOGIN", data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetBank', err);
      }
    },
    OutlookRefreshTokenCheck: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.calendar
            .outlookRefreshToken(params)
            .then(({ data }) => {
              context.commit("SET_OUTLOOK_REFRESH_TOKEN_CHECK", data);
              resolve(data);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] GetBank', err);
      }
    },
    SetCalendar: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.calendar
          .addCalendarEvent(payload)
          .then((res) => {
            let successData = Object.assign({success: "calendar.message.calendarSuccessfullyAdded",});
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditCalendar: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.calendar
          .editCalendarEvent(payload.event_id, payload.status_one)
          .then((res) => {
            let successData = Object.assign({ success: 'calendar.message.calendarSuccessfullyEdited'})
            context.dispatch('SetSnackBar', successData, {root: true});
            resolve(res);
          })
          .catch((error) => {
            if(error.response?.data) {
              let errorData = Object.assign({ error: error.response.data})
              context.dispatch('SetSnackBar', errorData, {root: true});
            }
            console.log(error)
            reject(error)
          });
      });
    },
    GetCalendarById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.calendar
            .getCalendarById(payload)
            .then(({ data }) => {
              context.commit("SET_CALENDAR_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetBank", err);
      }
    },
    DeleteCalendarById:async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.calendar
          .deleteCalendarEvent(payload)
          .then((res) => {
            let successData = Object.assign({ success: 'calendar.message.calendarSuccessfullyDeleted'})
            context.dispatch('SetSnackBar', successData, {root: true});
            resolve(res);
          })
          .catch((error) => {
            if(error.response?.data) {
              let errorData = Object.assign({ error: error.response.data})
              context.dispatch('SetSnackBar', errorData, {root: true});
            }
            console.log(error)
            reject(error)
          });
      });
    },
  },
  namespaced: true,
};

export default calendar;