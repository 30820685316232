export default {
  deposit: 'הפקדה',
  depositActions: "פעולות הפקדה",
  editDeposit: "ערוך הפקדה",
  addDeposit: "הוסף הפקדה",
  depositList: 'רשימת הפקדה',
  depositDate:"תאריך הפקדה",
  depositItem: "הפקדה תשלומים",
  customer: "לקוח",
  agent: "סוכן",
  branch: "סניף/תוקף",
  id: "קוד",
  action: "פעילות",
  save:"שמור",
  depositDetail: "פרטי הפקדה",
  customerName: "שם לקוח",
  customerNumber: 'מספר לקוח',
  balance: "איזון",
  total: 'סה"כ',
  listOfPayment: "רשימת תשלומים",
  from: "מ",
  to: "עד",
  amount: "סכום",
  type: "סוג הפקדה",
  paymentType: "סוג תשלום",
  bank: 'בנק',
  depositToBank: 'הפקדה לבנק',
  chequeNumber: "מספר המחאה/אישור",
  accountNo: "מספר חשבון/כרטיס",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח הפקדה",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerGroup: "קבוצה של לקוחות",
  totalPerReport: "סך הכל לדוח",
  totalPerPaymentType: "סך התשלום",
  message: {
    depositSuccessfullyDeleted: "הפקדה נמחקה בהצלחה",
    depositSuccessfullyAdded: "הפקדה נוספה בהצלחה",
    depositSuccessfullyEdited: "הפקדה נערכה בהצלחה",
  },
  field: {
    accountUpdate: 'הנה"ח',
    all: "כולם",
    open: "פתוח",
    finished: "סגור",
    canceled: "מבוטל",
    status: "מצב הצעה",
    paymentDate:"תאריך תשלום",
    employeeName: "שם העובד",
    bankId: "זיהוי בנק",
    customer: "צרכן",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
  }
}