export default {
  testByBina: 'Test Software By BINA',
  companyAddress: "POBox 274 Givat Shmuel 5410102 Israel",
  fax: "Fax",
  tel: "Tel.",
  vatRegNo: "VAT Reg. No.",
  receiptNo: "Receipt no.",
  To: "To",
  att: "Att.",
  date: "Date",
  ourNumber: "Our Number",
  page: "Page",
  of: "Of",
  no: "No",
  total: "Total",
  withRegards: "With Regards",
  generatePDF: "Generate PDF",
  previewPDF: "Preview PDF",
  paymentType: "Payment Type",
  chequeNumber: "Cheque No",
  bankNo: "Bank No",
  accountNo: "Account No",
  branch: "Branch",
  amount: "Amount",
  paymentDate: "Payment Date",
  original: "Original",
  copy: "Copy",
  cancelled: "Cancelled",
  invoices: "Invoices",
  message: {
    generatingPdf: "Generating PDF",
  },
  field: {
    total: "Total",
    discount: "Withholding tax",
    grandTotal: "Grand total",
  }
}