export default {
  contact: 'Contact',
  contactActions: "Contact Actions",
  editContact: "Edit Contact",
  addContact: "Add Contact",
  addContacts: "Add Contact",
  contactName: "Contact Name",
  save: "Save",
  contactsList: "Contact List",
  id: "ID",
  action: "Action",
  basicDetails: "Basic Details",
  otherDetails: "Other Details",
  displayText: "Communication",
  communication: "Communication",
  extension: "Extension",
  no: "No.",
  areaCode: "Area Code",
  country: "Country",
  tel: "Tel",
  cel: "Cel",
  fax: "Fax",
  message: {
    contactSuccessfullyDeleted: "Contact cancelled successfully",
    contactSuccessfullyAdded: "Contact added successfully",
    contactSuccessfullyEdited: "Contact edited successfully",
  },
  field: {
    jobTitle: "Job / title",
    email: "E-mail",
    site: "Site",
    remarks: "Remarks",
    recordType: "Record type",
    sms: "SMS",
    countryPrefix0: "Country prefix",
    dialingArea0: "Area prefix",
    phones0: "Phones",
    extension0: "Extension",
    countryPrefix1: "Country prefix 1",
    dialingArea1: "Dialing area 1",
    phones1: "Phones 1",
    extension1: "Extension 1",
    countryPrefix2: "Country prefix 2",
    dialingArea2: "Dialing area 2",
    phones2: "Phones 2",
    extension2: "Extension 2",
    countryPrefix3: "Country prefix 3",
    dialingArea3: "Dialing area 3",
    cellular3: "Cellular 3",
    countryPrefix4: "Country prefix 4",
    dialingArea4: "Dialing area 4",
    cellular4: "Cellular 4",
    countryPrefix5: "Country prefix 5",
    dialingArea5: "Dialing area 5",
    cellular5: "Cellular 5",
  }
}