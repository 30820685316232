export default {
  customer: 'לקוח',
  customerActions: "פעילות לקוח",
  editCustomer: "ערוך לקוח",
  addCustomer: "הוסף לקוח",
  addCustomers: "הוסף לקוחות",
  customerName: "שם לקוח",
  customerName2: "שם לקוח נוסף",
  save:"שמור",
  saveAndContinue: "שמור והמשך",
  customersList: "רשימת לקוחות",
  id: "קוד",
  action: "פעילות",
  group: "קבוצה",
  basicDetails: "מידע בסיסי",
  otherDetails: "מידע נוסף",
  notes: "הערות",
  displayText: "תקשורת",
  communication: "תקשורת",
  extension: "שלוחה",
  no: "מספר",
  areaCode: "קידומת",
  country: "מדינה",
  tel: "טלפון",
  cel: "סלולר",
  fax: "פקס",
  customerGroup: "קבוצת לקוחות",
  area: "קבוצת אזור",
  status: "סטטוס לקוח",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח לקוח",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  obligation: "אובליגו לקוח",
  turnOver: "מַחזוֹר",
  financial: "מידע כספי",
  documentNo: "מסמך מס '",
  documentType: "סוג מסמך",
  customerOrderNo: "הזמנת לקוח",
  from: "מ",
  paymentDate: "תאריך תשלום",
  originalAmount: "סכום מקורי",
  balance: "יתרה",
  agent: "עובד",
  amount: "סכום",
  paymentType: "סוג תשלום",
  originalDate: "תאריך מקורי",
  Invoices: "חשבונית",
  ReceiptTaxInvoices: "חשבונית/קבלה",
  CreditInvoices: 'חשבונית זיכוי',
  message: {
    customerSuccessfullyDeleted: "לקוח נמחק בהצלחה",
    customerSuccessfullyAdded: "לקוח נוסף בהצלחה",
    customerSuccessfullyEdited: "לקוח נערך בהצלחה",
  },
  field: {
    salesRepresentative: "נציג מכירות",
    emailInvoice: "אי מייל עבור חשבונית",
    termsOfPayment: "תנאי תשלום",
    group: "קבוצה לקוח",
    area: "אזור",
    status: "סטטוס",
    address1: "כתובת",
    pobox1: ".ת.ד",
    postalCode1: "מיקוד",
    town1: "עיר",
    address2: "כתובת 2",
    pobox2: "ת.ד. 2",
    postalCode2: "מיקוד 2",
    town2: "עיר 2",
    state: "מדינה",
    country: "ארץ",
    neighborhood: "שכונה",
    floor: "קומה",
    customerOpeningDate: "תאריך פתיחת הלקוח",
    customerEndDate: "תאריך סיום הלקוח",
    email: "אי מייל",
    site: "אתר",
    authorizedDealerNo: "מספר עוסק מורשה",
    bookKipingNo: 'מספר הנה"ח',
    vatCharge: 'חיוב מע"מ',
    customerOrderNoRequired: "הזמנת לקוח חובה",
    proposalNote: "הערה",
    deliveryNote: "הערת ת.משלוח",
    orderNote: "הערת הזמנה",
    invoiceNote: "הערת חשבונית",
    warningNote: "הערת התראה",
    generalRemarks: "הערות כלליות",
    countryPrefix0: "קידומת מדינה",
    dialingArea0: "קידומת",
    phones0: "טלפון",
    extension0: "שלוחה",
    countryPrefix1: "קידומת מדינה ",
    dialingArea1: "קידומת",
    cellular1: "סלולר",
    phones1: "טלפון",
    extension1: "שלוחה",
    countryPrefix2: "קידומת מדינה",
    dialingArea2: "קידומת",
    cellular2: "סלולר",
    phones2: "טלפון",
    extension2: "שלוחה",
    countryPrefix3: "קידומת מדינה",
    dialingArea3: "קידומת",
    cellular3: "סלולר",
    countryPrefix4: "קידומת מדינה",
    dialingArea4: "קידומת",
    cellular4: "סלולר",
    countryPrefix5: "קידומת מדינה",
    dialingArea5: "קידומת",
    cellular5: "סלולר",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    invoices: "חשבונית",
    futureChecks: "צ'קים עתידיים",
    proformaInvoices: "חשבון עסקה",
    deliveryNotes: "הערת ת.משלוח",
    customerOrders: "הזמנות לקוח",
    totalObligations: 'סה"כ אובליגו לקוח',
    thisYear: "השנה",
    lastYear: "שנה שעברה",
    before: "לפני",
    totalTurnOver: 'סה"כ לְהִתְהַפֵּך',
    maximumCredit: "אשראי מקסימלי",
    checkCredit: "בדיקת אשראי",
    checkPaymentDates: "בדיקת תאריך פירעון",
    restrictedCustomer: "מעוכב שרות",
    fixedDiscount: "הנחה קבועה %"
  }
}