import Vue from 'vue';
const customerPrice = {
    state: {
      customerPriceListData: [],
      customerPriceQuery: null,
      checkPriceData: null
    },
    getters: {
      customerPriceListData: (state) => state.customerPriceListData,
      customerPriceQuery: (state) => state.customerPriceQuery,
      checkPriceData: (state) => state.checkPriceData,
    },
    mutations: {
      SET_CUSTOMER_PRICE_QUERY: (state, payload) => {
        state.customerPriceQuery = payload;
      },
      SET_CUSTOMER_PRICE: (state, payload) => {
        state.customerPriceListData = payload;
      },
      SET_CUSTOMER_IS_EXIST: (state, payload) => {
        state.checkPriceData = payload;
      }
    },
    actions: {
      GetCustomerPriceData: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customerPrice
              .getCustomerPrice(params)
              .then(({ data }) => {
                context.commit("SET_CUSTOMER_PRICE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      SetCustomerPriceData: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.customerPrice
            .addCustomerPrice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.customerPriceSuccessfullyAdded'})
              context.dispatch('GetCustomerPriceData', "?where=item_id|" + payload.item_id)
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditCustomerPrice: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.customerPrice
            .editCustomerPrice(payload.id, payload.customerPrice)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.customerPriceSuccessfullyEdited'})
              context.dispatch('GetCustomerPriceData', "?where=item_id|" + payload.customerPrice.item_id)
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      CustomerIsExist: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.customerPrice
              .checkCustomerIsExist(params)
              .then(({ data }) => {
                context.commit("SET_CUSTOMER_IS_EXIST", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      DeleteCustomerPriceDataById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.customerPrice
            .deleteCustomerPrice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.customerPriceSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default customerPrice;