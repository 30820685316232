export default {
    id: "ID",
    banks: "Banks",
    description: "Description",
    accountNumber: "Account No.",
    banksActions: "Banks Actions",
    actions: "Actions",
    options: "Options",
    copy: "Copy & Create New",
    activityLog: "Activity Log",
    save: "Save",
    addBanks: "Add Banks",
    banksList: "Banks List",
    message: {
        banksSuccessfullyDeleted: "Banks cancelled successfully",
        banksSuccessfullyAdded: "Banks added successfully",
        banksSuccessfullyEdited: "Banks edited successfully",
    },
}