export default {
    trialBalance: "Trial Balance",
    trialBalanceReport: "Trial Balance Report",
    accountNo: "Account No.",
    credit: "Credit",
    debit: "Debit",
    accountName: "Account Name",
    difference: "Difference",
    totalCreditAmount: "Total Credit:",
    totalDebitAmount: "Total Debit:",
    totalDifference: "Total Difference:",
    execute: "Execute",
    reset: "Reset",
    filters: "Filters",
    resetFilter: "Reset filter",
    excel: "Excel",
    fromDate: "From date",
    toDate: "To date",
}