import Vue from 'vue';
const attachedDocuments = {
    state: {
        attachedDocumentsList: [],
    },
    getters: {
        attachedDocumentsList: (state) => state.attachedDocumentsList,
    },
    mutations: {
        SET_ATTACHED_DOCUMENTS: (state, payload) => {
            state.attachedDocumentsList = payload;
        },
    },
    actions: {
        GetAttachedDocuments: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.attachedDocuments
                        .getAttachedDocuments(params)
                        .then(({ data }) => {
                            context.commit("SET_ATTACHED_DOCUMENTS", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetRemarks', err);
            }
        },
        SetAttachedDocuments: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.attachedDocuments
                    .addAttachedDocuments(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'employee.message.attachedDocumentsAdded' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
        UpdateAttachedDocuments: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.attachedDocuments
                    .updateAttachedDocuments(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'employee.message.attachedDocumentsSuccessfullyEdited' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
        DeleteAttachedDocuments: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.attachedDocuments
                    .deleteAttachedDocuments(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'employee.message.attachedDocumentsSuccessfullyDeleted' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true });
                        }
                        console.log(error)
                        reject(error)
                    });
            });
        },
    },
    namespaced: true,
}

export default attachedDocuments;