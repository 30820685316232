import Vue from 'vue';
const product = {
    state: {
      productList: [],
      productById: {},
      productQuery: null,
      productItemsByCustomer:[],
      productReportList: [],
      productReportQuery: null,
      rowIndex: null,
      discountList: null,
      discountListForSupplier: null
    },
  
    getters: {
      productList: (state) => state.productList,
      productById: (state) => state.productById,
      productQuery: (state) => state.productQuery,
      productItemsByCustomer : (state) => state.productItemsByCustomer,
      productReportList: (state) => state.productReportList,
      productReportQuery: (state) => state.productReportQuery,
      rowIndex: (state) => state.rowIndex,
      discountList: (state) => state.discountList,
      discountListForSupplier: (state) => state.discountListForSupplier,
    },
    mutations: {
      SET_PRODUCT_QUERY: (state, payload) => {
        state.productQuery = payload;
      },
      SET_PRODUCT: (state, payload) => {
        state.productList = payload;
      },
      SET_PRODUCT_BY_ID: (state, payload) => {
        if(payload) {
          state.productById = payload;
        } else {
          state.productById = {};
        }
      },
      SET_PRODUCTS_BY_CUSTOMER: (state,payload) => {
        state.productItemsByCustomer = payload;
      },
      SET_PRODUCT_REPORT_QUERY: (state, payload) => {
        state.productReportQuery = payload;
      },
      SET_PRODUCT_REPORT: (state, payload) => {
        state.productReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      },
      SET_DISCOUNT: (state, payload) => {
        state.discountList = payload;
      },
      SET_DISCOUNT_SUPPLIER: (state, payload) => {
        state.discountListForSupplier = payload;
      }
    },
    actions: {
      GetProductsByPost: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.product
              .getProductListByPost(params)
              .then(({ data }) => {
                context.commit("SET_PRODUCTS_BY_CUSTOMER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getproduct', err);
        }
      },
      GetItemDiscountOrPrice: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.product
              .getItemDiscountOrPrice(params.customer_id, params.item_id)
              .then(({ data }) => {
                context.commit("SET_DISCOUNT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getproduct', err);
        }
      },
      GetSupplierItemDiscountOrPrice: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.product
              .getSupplierItemDiscountOrPrice(params.supplier_id, params.item_id)
              .then(({ data }) => {
                context.commit("SET_DISCOUNT_SUPPLIER", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getproduct', err);
        }
      },
      GetProduct: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.product
              .getProduct(params)
              .then(({ data }) => {
                context.commit("SET_PRODUCT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getproduct', err);
        }
      },
      GetProductReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.product
              .getProduct(params)
              .then(({ data }) => {
                context.commit("SET_PRODUCT_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getproduct', err);
        }
      },
      SetProduct: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.product
            .addProduct(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.productSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditProduct: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.product
            .editProduct(payload.product_id, payload.product)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.productSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetProductById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.product
              .getProductById(payload)
              .then(({ data }) => {
                context.commit('SET_PRODUCT_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetProduct', err);
        }
      },
      DeleteProductItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.product
            .deleteProduct(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.productSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetProduct', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default product;