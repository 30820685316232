export default {
  bank: 'בנק',
  bankActions: "אפשרויות בנק",
  editBank: "ערוך בנק",
  addBank: "הוסף בנק",
  save:"שמור",
  bankList: "רשימת בנקים",
  id: "קוד",
  action: "פעילות",
  basicDetails: "מידע בסיסי",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח בנק",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  message: {
    bankSuccessfullyDeleted: "בנק נמחק בהצלחה",
    bankSuccessfullyAdded: "בנק נוסף בהצלחה",
    bankSuccessfullyEdited: "בנק נערך בהצלחה",
  },
  field: {
    name: "שם הבנק",
    address: "כתובת בנק",
    town: "עיר בנק",
    postalCode: "מיקוד בנק",
    branchNo: "מספר סניף",
    branchName: "שם הסניף",
    accountNo: "מספר חשבון",
    tel1: "1 טלפון",
    tel2: "2 טלפון",
    tel3: "3 טלפון",
    fax: "פקס",
    email: "דואר אלקטרוני",
    site: "אתר",
    contactName: "איש קשר",
    accountingNo: "הנה'ח",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    no: "מספר חשבון",
    addressTown: "כתובת בנק/עיר בנק"
  }
}