import Vue from 'vue';
const activityLog = {

  state: {
    activityLogList: [],
  },

  getters: {
    activityLogList: (state) => state.activityLogList,
  },

  mutations: {
    SET_ACTIVITY_LOG: (state, payload) => {
      state.activityLogList = payload;
    },
  },

  actions: {
    GetActivityLog: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.activityLog
            .getActivityLog(params)
            .then(({ data }) => {
              context.commit("SET_ACTIVITY_LOG", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data })
                context.dispatch('SetSnackBar', errorData, { root: true });
              }
              console.log(error)
              reject(error)
            })
        });

      } catch (error) {
        console.warn('[vuex.user] GetActivityLog', error)
      }
    }
  },
  namespaced: true,
}

export default activityLog;