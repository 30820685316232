export default {
    journalEntry: "יומן",
    journalEntryList: "יומן רשימה",
    id: "קוד",
    opposite: "מול",
    referenceOne: "התייחסות",
    referenceTwo: "התייחסות 2",
    referenceThree: "תאריך מסמך",
    valueDate: "תאריך ערך",
    details: "פרטים",
    debit: "חובה",
    credit: "זכות",
    amount: "יתרה",
    no: "לא.",
    action: "פעילות",
    journalEntryDetail: "פרט יומן",
    listOfJournalEntry: "רשימה של יומן",
    title: "כותרת",
    remarks: "הערות",
    closeAndUpdate: "סגור ועדכן",
    save: "שמור",
    status: "סטָטוּס",
    totalDifference: "הבדל מוחלט",
    message: {
        journalEntrySuccessfullyEdited: "יומן נערך בהצלחה",
        journalEntrySuccessfullyAdded: "יומן נוסף בהצלחה",
        pdfTemplateSuccessfullyAdded: "תבנית PDF נוספה בהצלחה",
        pdfTemplateSuccessfullyDeleted: "תבנית PDF בוטלה בהצלחה",
        pdfTemplateSuccessfullyEdited: "תבנית PDF נערך בהצלחה"
    }
}
