import route from "./route";
import login from "./login";
import singup from "./singup";
import dashboard from "./dashboard";
import roles from "./roles";
import company from "./company";
import employee from "./employee";
import customer from "./customer";
import contact from "./contact";
import auxiliaryTable from "./auxiliaryTable";
import activityLog from "./activityLog";
import settings from "./settings";
import formRules from "./formRules";
import product from "./product";
import supplier from "./supplier";
import quotation from "./quotation";
import quotationPdf from "./quotationPdf";
import order from "./order";
import orderPdf from "./orderPdf";
import delivery from "./delivery";
import deliveryPdf from "./deliveryPdf";
import invoice from "./invoice";
import invoicePdf from "./invoicePdf";
import receipt from "./receipt";
import receiptPdf from "./receiptPdf";
import bank from "./bank";
import deposit from "./deposit";
import depositPdf from "./depositPdf";
import creditInvoice from "./creditInvoice";
import creditInvoicePdf from "./creditInvoicePdf";
import dbQuery from "./dbQuery";
import proforma from "./proforma";
import proformaPdf from "./proformaPdf";
import remarks from "./remarks";
import debitReport from "./debitReport";
import bookkeeping from "./bookkeeping";
import expenses from "./expenses";
import funds from "./funds";
import bookkeepingBank from "./bookkeepingBank";
import other from "./other";
import miscellaneous from "./miscellaneous";
import bookkeepingRecords from "./bookkeepingRecords";
import salesReport from "./salesReport";
import accountingCardReport from "./accountingCardReport";
import receiptTax from "./receiptTax";
import trialBalance from "./trialBalance";
import news from "./news";
import documentSeries from "./documentSeries";
import calendar from "./calendar";
import returnCertificate from "./returnCertificate";
import journalEntry from "./journalEntry";
import purchaseOrder from "./purchaseOrder";
import supplierDeliveryNote from "./supplierDeliveryNote";
import supplierInvoice from "./supplierInvoice";
import supplierCreditInvoice from "./supplierCreditInvoice";
import supplierPayment from "./supplierPayment";
import cashFlow from "./cashFlow";
import generalEvaluation from "./generalEvaluation";

export default {
  route,
  login,
  singup,
  dashboard,
  roles,
  company,
  employee,
  customer,
  contact,
  auxiliaryTable,
  activityLog,
  settings,
  formRules,
  product,
  supplier,
  quotation,
  quotationPdf,
  order,
  orderPdf,
  delivery,
  deliveryPdf,
  invoice,
  invoicePdf,
  receipt,
  bank,
  receiptPdf,
  deposit,
  depositPdf,
  creditInvoice,
  creditInvoicePdf,
  dbQuery,
  proforma,
  proformaPdf,
  remarks,
  debitReport,
  bookkeeping,
  expenses,
  funds,
  bookkeepingBank,
  other,
  miscellaneous,
  bookkeepingRecords,
  salesReport,
  accountingCardReport,
  receiptTax,
  trialBalance,
  news,
  documentSeries,
  calendar,
  returnCertificate,
  journalEntry,
  purchaseOrder,
  supplierDeliveryNote,
  supplierInvoice,
  supplierCreditInvoice,
  supplierPayment,
  cashFlow,
  generalEvaluation,
  toolbar: {
    appname: "יישום",
    settings: "הגדרות חשבון",
    companySetting: "הגדרות חברה",
    profile: "פרופיל",
    logout: "התנתק",
  },
  common: {
    edit: "ערוך",
    delete: "לְבַטֵל",
    view: "הצג",
    employee: "עובד",
    less: "פחות",
    greater: "גדול מ",
    numberOfTotalRows: 'סה"כ שורות',
    search: "חפש",
    noDataFound: "אין מידע",
    loading: "...טוען",
    doYouWant: "האם ברצונך לשנות את מצב המסמך כדי לפתוח או לסגור?",
    pleaseClickOnBelowButton: "אנא לחץ על כפתור הסטטוס למטה.",
    closeStatus: "סטטוס סגור",
    openStatus: "מצב פתוח",
    sendByMail: "שלח בדואר",
    selectContacts: "בחר איש קשר",
    contactEmails: "איש קשר אי מייל",
    otherEmails: "אחרים אי מייל",
    sendEmail: "שלח אי מייל",
    documentReadOnlyModeNotice:
      "מסמך/כרטיס זה נמצא בשימוש על ידי {userId} והוא פתוח לקריאה בלבד",
    byWholeInvoice: "לפי חשבונית שלמה",
    byTheUser: "מאת המשתמש",
    pleaseClickOnBelowOptionButton: "אנא לחץ על כפתור האפשרות למטה.",
    pleaseClickOnBelowOptionButton2:
      "ניתן להכין חשבונית אשראי רק כאשר היתרה מלאה.",
    ok: "בסדר",
    checkStatusInvoice: "ניתן להכין חשבונית אשראי רק כאשר החשבונית סגורה",
    bookkeepingConfirmation:
      "עליך להזין מספר הנה”ח בכרטיס הלקוח לפני שמירת המסמך.",
    copyAndCreateNew:
      "איזה מסמך ברצונך להעתיק וליצור אנא בחר אותו מהאפשרויות הבאות",
    newDocCreated: "המסמך נוצר",
    documentVAT: 'כולל מע"מ',
    pdfTemplate: "תבניות הדפסה",
    createPdfTemplate: "צור תבנית PDF",
    fontSize: "גודל גופן",
    fontStyle: "סיגנון גופן",
    fontWeight: "משקל גופן",
    textColor: "צבע טקסט",
    textDecoration: "קישוט טקסט",
    templateName: "שם התבנית",
    previewTemplate: "תבנית תצוגה מקדימה",
    chooseDyamicTemplate: "אנא בחר תבנית להדפסה",
    selectTemplate: "בחר תבנית",
    documentDiscount: "הנחת מסמך",
    copyDocument: "העתק מסמך",
    suppliedMsg: "לא נותרו פריטים לאספקה במסמך זה.",
    suppliedQuantities: "כמויות שסופקו",
    supplierBookkeepingConfirmation:
      "יש להזין מספר הנהלת חשבונות בכרטיס הספק לפני שמירת המסמך.",
    details: "פרטים",
    daily: "יום יומי",
    monthly: "יַרחוֹן",
    supplierInvoiceCancel:
      "לא ניתן לבטל את המסמך, כאשר חשבונית זיכוי, או תשלום משויך",
    purchaseOrderCancel: "לא ניתן לבטל את המסמך, כאשר משלוח או חשבונית משויכים",
    supplierDeliveryNoteCancel: "לא ניתן לבטל את המסמך, כאשר משויכת חשבונית",
    supplierPaymentCancel: "לא ניתן לבטל את המסמך, כאשר החשבונית משויכת",
    supplierCreditInvoiceCancel: "לא ניתן לבטל את המסמך, כאשר החשבונית משויכת",
  },
};
