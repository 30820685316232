export default {
    dbInfo:"מידע DB",
    importDbInfo: "לייבא נתונים",
    query:"שאילתא",
    showData:"הצג נתונים",
    executeQuery:"לבצע שאילתה",
    selectTable: "בחר טבלה",
    download: "הורד",
    importExcel: "ייבוא ​​אקסל",
    selectFile: "בחר קובץ",
    upload: "להעלות",
    fileData: "נתוני קובץ",
    selectFields: "בחר שדות",
    save: "שמור שדות",
    userList: "רשימת משתמשים נוכחית",
    uploadExcel: "העלה אקסל",
    update: "תאשר",
    cancel: "לְבַטֵל",
    security: "קוד אבטחה",
    access: "הגבלת גישה",
    personalMenu: "תפריט אישי",
    saveMenu: "שמור",
    selectDatabaseColumn: "בחר עמודת DB",
    message: {
        importSuccessfully: "הקובץ יובא בהצלחה",
        fileIsRequired: "נדרש קובץ",
        selectTable: "יש צורך בטבלה",
        csvRequired: "נדרש קובץ .csv",
        verifyCode: "הקוד שלך אומת בהצלחה."
    },
    field: {
        id: "קוד",
        name: "שם",
        type: "סוּג",
        email: "אימייל",
        slug: "שבלול"
    }
}