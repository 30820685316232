export default {
  purchaseOrderList: "Purchase Order List",
  supplier: "Supplier",
  supplierGroup: "Supplier Group",
  orderDate: "Order Date",
  requiredDeliveryDate: "Required Delivery Date",
  actualDeliveryDate: "Actual Delivery Date",
  supplierName: "Supplier Name",
  purchaseOrderAction: "Purchase Order Action",
  purchaseOrderItems: "Purchase Order Items",
  purchaseOrderDetail: "Purchase Order Detail",
  purchaseOrderStatus: "Purchase Order Status",
  quotationNo: "Quotation No.",
  supplierDetails: "Supplier Detail",
  orderNo: "Order No.",
  supplierNo: "Supplier No.",
  address: "Address",
  town: "Town",
  country: "Country",
  tel1: "Tel 1",
  tel2: "Tel 2",
  cel: "Cel",
  fax: "Fax",
  authorisedDealorNo: "Authorized dealer number",
  agent: "Employee",
  title: "Title",
  remarks: "Remarks",
  customerOrderNo: "Customer Order No.",
  status: "Status",
  total: 'Total',
  discountPercentage: "Discount Percentage",
  discount: "Discount",
  afterDiscount: "After Discount",
  vat: 'VAT',
  vatPercentage: 'Vat Percentage',
  grandTotal: 'Grand Total',
  recordId: "RecordId",
  remainder: "Remainder",
  purchaseOrderNumber: "Purchase Order No.",
  message: {
    purchaseOrderSuccessfullyDeleted: "Purchase Order cancelled successfully",
    purchaseOrderSuccessfullyAdded: "Purchase Order added successfully",
    purchaseOrderSuccessfullyEdited: "Purchase Order edited successfully",
  },
};
