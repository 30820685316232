import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"
import i18n from '@/locale/index'

Vue.use(VueRouter)

function hasPermission(roles, permissionRoles) {
  if (roles.slug === 'admin') return true;
  // if (roles.filter((obj) => obj.name === 'admin').length > 0) return true;
  if (!permissionRoles) return true;
  // return roles.some((role) => permissionRoles.includes(role.name));
  return permissionRoles.includes(roles.slug);
}

const beforeEnter = (_to, _from, next) => {
  if (_to.meta.auth) {
    if (store.state.user.token) {
      if (_to.path === "/signin") {
        next({
          path: "/",
        });
      } else {
        if (hasPermission(store.state.user.user.role, _to.meta.accessRoles)) {
          next();
        } else {
          next('/');
        }
      }
    } else {
      next("/signin");
    }
  } else {
    if (_to.path === "/signin") {
      if (!store.state.user.token) {
        next();
      } else {
        next({
          path: "/",
        });
      }
    } else {
      next();
    }
  }
};

export const routes = [
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '',
        component: () => import("@/views/adminApp/Dashboard/index.vue"),
        name: 'Dashboard',
        meta: {
          auth: true, title: 'route.dashboard', icon: 'mdi-monitor-dashboard', noCache: true, affix: true,
        },
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/settings',
        component: () => import("@/views/adminApp/settings/index.vue"),
        name: 'Settings',
        meta: {
          auth: true, title: 'route.settings', accessRoles: ['company', 'demo'], noCache: true, affix: true, icon: 'mdi-account-cog-outline', logout: true
        }
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/role',
        component: () => import("@/views/adminApp/roles/index.vue"),
        name: 'Roles',
        noVisibleChildren: true,
        meta: {
          auth: true, title: 'route.roles', accessRoles: ['roles', 'demo'], icon: 'mdi-account-tie', noCache: true, affix: true,
        },
        children: [
          {
            path: 'roleAction/:id?',
            component: () => import("@/views/adminApp/roles/RolesAction.vue"),
            name: 'RolesAction',
            hidden: true,
            alias: ['editRole/:id', 'addRole'],
            meta: {
              auth: true, title: 'route.rolesAction', noCache: true, affix: true,
            },
          },
        ]
      },
      // {
      //   path: '/role/roleAction/:id?',
      //   component: () => import("@/views/roles/RolesAction.vue"),
      //   name: 'RolesAction',
      //   hidden: true,
      //   meta: {
      //     auth: true, title: 'route.roles', icon: 'mdi-home-outline', noCache: true, affix: true,
      //   },
      // },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/companies',
        component: () => import("@/views/adminApp/companies/index.vue"),
        name: 'Company',
        noVisibleChildren: true,
        meta: {
          auth: true, title: 'route.companies', accessRoles: ['company', 'demo'],  icon: 'mdi-home-group',
        },
        children: [
          {
            path: 'companyAction/:id?',
            component: () => import("@/views/adminApp/companies/CompanyAction.vue"),
            name: 'CompanyAction',
            hidden: true,
            alias: ['editCompany/:id', 'addCompany'],
            meta: {
              auth: true, title: 'route.companyAction'
            },
          }
        ]
      },
      // {
      //   path: '/companies/companyAction/:id?',
      //   component: () => import("@/views/companies/CompanyAction.vue"),
      //   name: 'CompanyAction',
      //   hidden: true,
      //   alias: ['/companies/editCompany/:id', '/companies/addCompany'],
      //   meta: {
      //     auth: true, title: 'route.companies', icon: 'mdi-home-outline',
      //   },
      // },
      // {
      //   path: '/companies/:id/employee',
      //   component: () => import("@/views/employee/Employee.vue"),
      //   name: 'Employee',
      //   hidden: true,
      //   meta: {
      //     auth: true, title: 'route.employee', icon: 'mdi-home-outline', accessRoles: ['company'],
      //   },
      // },
      // {
      //   path: '/companies/:id/employee/employeeAction/:employee_id?',
      //   component: () => import("@/views/employee/EmployeeAction.vue"),
      //   name: 'EmployeeAction',
      //   hidden: true,
      //   alias: ['/companies/:id/employee/editEmployee/:employee_id', '/companies/:id/employee/addEmployee'],
      //   meta: {
      //     auth: true, title: 'route.employee', icon: 'mdi-home-outline', accessRoles: ['company'],
      //   },
      // }
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/',
    component: () => import("@/views/layout/TheLayout.vue"),
    children: [
      {
        path: '/news',
        component: () => import("@/views/adminApp/news/index.vue"),
        name: 'News',
        noVisibleChildren: true,
        meta: {
          auth: true, title: 'route.news', accessRoles: ['company', 'demo'],  icon: 'mdi-newspaper-variant',
        },
        children: [
          {
            path: 'newsAction/:id?',
            component: () => import("@/views/adminApp/news/NewsAction.vue"),
            name: 'NewsAction',
            hidden: true,
            alias: ['editNews/:id', 'addNews'],
            meta: {
              auth: true, title: 'route.newsAction'
            },
          }
        ]
      },
    ],
    meta: {
      auth: true,
    },
    beforeEnter: beforeEnter,
  },
  {
    path: '/signin',
    name: 'login',
    hidden: true,
    component: () => import('@/views/adminApp/auth/SingIn.vue'),
    meta: {
      title: 'login.titleIn',
    },
    beforeEnter: beforeEnter,
  },
  // {
  //   path: '/singup',
  //   name: 'register',
  //   hidden: true,
  //   component: () => import('@/views/adminApp/auth/SingUp.vue'),
  //   beforeEnter: beforeEnter,
  // },
  {
    path: '*',
    redirect: () => {
      return { path: '/' }
    },
    hidden: true,
  },
]

const adminRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

adminRouter.afterEach((to) => {
  Vue.nextTick(() => {
      document.title = i18n.t(`${to.meta.title}`) || 'Bina';
  });
});

export default adminRouter;
