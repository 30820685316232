export default {
  testByBina: 'Test Software By BINA',
  companyAddress: "ת.ד. 274 גבעת שמואל מיקוד 5410102",
  fax: "פקס",
  tel: "טלפון.",
  vatRegNo: "עוסק מורשה מספר.",
  customerInvoiceNo: "חשבון עסקה מספר מסמך'.",
  To: "לכבוד",
  att: "לידי",
  date: "תאריך",
  ourNumber: "מספר לקוח",
  yourOrder: "הזמנתכם",
  validTtill: "תאריך אספקה",
  printedAt: "תאריך הדפסה",
  printingHr: "שעת הדפסה",
  page: "עמוד",
  of: "מתוך",
  no: "מס'",
  product: "פריט",
  quantity: "כמות",
  unitPrice: "מחיר",
  lineDiscount: "הנחה",
  itemDescription: "תאור פריט",
  total: 'סה"כ',
  withRegards: "בברכה",
  generatePDF: "PDF לִיצוֹר",
  previewPDF: "PDF תצוגה מקדימה",
  time: "זְמַן",
  original: "מקור",
  copy: "עותק",
  cancelled: "מבוטל",
  message: {
    generatingPdf: "PDF יוצר",
  },
  field: {
    total: 'סה"כ',
    discountPrecentage: "אחוז הנחה",
    discount: "הנחה",
    afterDiscount: "לאחר הנחה",
    vat: 'מע"מ',
    vatPrecentage: 'אחוז מע"מ',
    grandTotal: 'סה"כ סופי',
  }
}
