import Vue from 'vue';
const proforma = {
    state: {
      proformaList: [],
      proformaById: {},
      proformaQuery: null,
      proformaReportList: [],
      proformaReportQuery: null,
      rowIndex: null,
    },
  
    getters: {
      proformaList: (state) => state.proformaList,
      proformaById: (state) => state.proformaById,
      proformaQuery: (state) => state.proformaQuery,
      proformaReportList: (state) => state.proformaReportList,
      proformaReportQuery: (state) => state.proformaReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_PROFORMA_QUERY: (state, payload) => {
        state.proformaQuery = payload;
      },
      SET_PROFORMA: (state, payload) => {
        state.proformaList = payload;
      },
      SET_PROFORMA_BY_ID: (state, payload) => {
        if(payload) {
          state.proformaById = payload;
        } else {
          state.proformaById = {};
        }
      },
      SET_PROFORMA_REPORT_QUERY: (state, payload) => {
        state.proformaReportQuery = payload;
      },
      SET_PROFORMA_REPORT: (state, payload) => {
        state.proformaReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetProforma: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.proforma
              .getProforma(params)
              .then(({ data }) => {
                context.commit("SET_PROFORMA", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetProformaReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_PROFORMA_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetProforma: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.proforma
            .addProforma(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'proforma.message.proformaSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditProforma: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.proforma
            .editProforma(payload.proforma_id, payload.proforma)
            .then((res) => {
              let successData = Object.assign({ success: 'proforma.message.proformaSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetProformaById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.proforma
              .getProformaById(payload)
              .then(({ data }) => {
                context.commit('SET_PROFORMA_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteProformaItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.proforma
            .deleteProforma(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'proforma.message.proformaSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetProforma', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default proforma;