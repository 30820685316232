import { getUserInfo } from '@/api/login';
import Vue from 'vue';

const user = {
  state: {
    token: '',
    user: '',
    accessRight: [],
    rememberUser: null,
  },

  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    accessRight: (state) => state.accessRight,
    rememberUser: (state) => state.rememberUser
  },

  mutations: {
    SET_REMEMBER_USER: (state, payload) => {
      state.rememberUser = payload;
    },
    SET_USER_INFO: (state, payload) => {
      if (payload.logout) {
        state.token = '';
        state.user = '';
        state.accessRight = [];
      } else {
        state.user = payload || state.user;
        state.accessRight = payload.role.slug === 'admin' || payload.role.slug === 'manager' ? ['create', 'edit', 'show', 'delete', 'financial', 'bookkeeping'] : payload.role.permissions.map((role) => {return role.slug}) || state.accessRight;
      }
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },

  actions: {
    LoginByEmail: async ({ commit, dispatch }, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auth
            .login(payload)
            .then(({ data }) => {
              commit("SET_TOKEN", data.token);
              commit('SET_USER_INFO', data.user);
              // let successData = Object.assign({ success: this.$t('login.successFullylogin')})
              // dispatch('SetSnackBar', successData);
              resolve(data);
            })
            .catch((error) => {
              let errorData = Object.assign({ error: error.response.data})
              dispatch('SetSnackBar', errorData);
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
      }
    },
    ForgotByEmail: async ({ dispatch }, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auth
            .forgot(payload)
            .then(({ data }) => {
              // commit("SET_TOKEN", data.token);
              // commit('SET_USER_INFO', data.user);
              let successData = Object.assign({ success: 'login.emailHasBeenSent', subSuccess: 'login.checkYourInbox'});
              dispatch('SetSnackBar', successData);
              resolve(data);
            })
            .catch((error) => {
              let errorData = Object.assign({ error: error.response.data})
              dispatch('SetSnackBar', errorData);
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
      }
    },
    ResetPassword: async ({ dispatch }, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auth
            .reset(payload)
            .then(({ data }) => {
              // commit("SET_TOKEN", data.token);
              // commit('SET_USER_INFO', data.user);
              let successData = Object.assign({ success: 'login.passwordResetSuccessfully'});
              dispatch('SetSnackBar', successData);
              resolve(data);
            })
            .catch((error) => {
              let errorData = Object.assign({ error: error.response.data})
              dispatch('SetSnackBar', errorData);
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
      }
    },

    ChangePassword: async ({ dispatch }, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auth
            .change(payload)
            .then(({ data }) => {
              let successData = Object.assign({ success: 'login.passwordResetSuccessfully'});
              dispatch('SetSnackBar', successData);
              resolve(data);
            })
            .catch((error) => {
              let errorData = Object.assign({ error: error.response.data})
              dispatch('SetSnackBar', errorData);
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
      }
    },

    RegisterNewAccount: async ({dispatch} , payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.auth
            .register(payload)
            .then(({ data }) => {
              // let successData = Object.assign({ success: this.$t('singup.successFullyRegister')})
              // dispatch('SetSnackBar', successData);
              resolve(data);
            })
            .catch((error) => {
              let errorData = Object.assign({ error: error.response.data})
              dispatch('SetSnackBar', errorData);
              reject(error)
            });
        });
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
      }
    },

    GetUserInfo: async ({ commit, state }) => {
      try {
        const response = await getUserInfo(state.token);

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!response) {
          throw new Error('Verification failed, please login again.');
        }

        // Verify returned roles are a non-null array
        if (response.user.roles && response.user.roles.length === 0) {
          throw new Error('getInfo: roles must be a non-null array!');
        }

        commit('SET_USER_INFO', response.user);
      } catch (err) {
        console.warn('[vuex.user] GetUserInfo', err);
      }
    },

    LogOut: async ({ commit }) => {
      try {
        await commit('SET_USER_INFO', { logout: true });
      } catch (err) {
        console.warn('[vuex.user] LogOut', err);
      }
    },

    // Dynamically modify permissions
    /* ChangeRoles: async ({ commit, dispatch }, role) => {
      try {
        console.log('[vuex.user] ChangeRoles', role);
        await commit('SET_TOKEN', role);
        const data = await getUserInfo(role);
        await commit('SET_USER_INFO', data);
        // Redraw the side menu after dynamically modifying the permissions
        await dispatch('GenerateRoutes', data);
      } catch (err) {
        console.warn('[vuex.user] ChangeRoles', err);
      }
    }, */
  },
};

export default user;
