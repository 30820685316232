import Vue from 'vue';
const priceList = {
    state: {
      priceListData: [],
      priceListQuery: null,
      checkUniqueData: null,
    },
    getters: {
      priceListData: (state) => state.priceListData,
      priceListQuery: (state) => state.priceListQuery,
      checkUniqueData: (state) => state.checkUniqueData,
    },
    mutations: {
      SET_PRICE_LIST_QUERY: (state, payload) => {
        state.priceListQuery = payload;
      },
      SET_PRICE_LIST: (state, payload) => {
        state.priceListData = payload;
      },
      SET_CHECK_UNIQUE_DATA: (state, payload) => {
        state.checkUniqueData = payload;
      }
    },
    actions: {
      DataIsExist: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.priceList
              .checkListDataIsExist(params)
              .then(({ data }) => {
                context.commit("SET_CHECK_UNIQUE_DATA", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      GetPriceListData: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.priceList
              .getPriceList(params)
              .then(({ data }) => {
                context.commit("SET_PRICE_LIST", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetBank', err);
        }
      },
      SetPriceListData: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.priceList
            .addPriceList(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.priceSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditPriceListData: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.priceList
            .editPriceList(payload.id, {
              discount: payload.discount,
              price_group_id: payload.priceGroupId,
              ztable_id: payload.ztable_id,
            })
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.priceSuccessfullyEdited'})
              context.dispatch('GetPriceListData')
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      DeletePriceListData:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.priceList
            .deletePriceList(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'product.message.priceSuccessfullyDeleted'})
              context.dispatch('GetPriceListData')
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default priceList;