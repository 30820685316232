import Vue from 'vue';

const receiptTaxInvoice = {
    state: {
      receiptTaxInvoiceList: [],
      receiptTaxInvoiceById: {},
      receiptTaxInvoiceQuery: null,
      receiptTaxInvoiceReportList: [],
      receiptTaxInvoiceReportQuery: null,
    },
    getters: {
      receiptTaxInvoiceList: (state) => state.receiptTaxInvoiceList,
      receiptTaxInvoiceById: (state) => state.receiptTaxInvoiceById,
      receiptTaxInvoiceQuery: (state) => state.receiptTaxInvoiceQuery,
      receiptTaxInvoiceReportList: (state) => state.receiptTaxInvoiceReportList,
      receiptTaxInvoiceReportQuery: (state) => state.receiptTaxInvoiceReportQuery,
    },
    mutations: {
      SET_RECEIPTTAXINVOICE_QUERY: (state, payload) => {
        state.receiptTaxInvoiceQuery = payload;
      },
      SET_RECEIPTTAXINVOICE: (state, payload) => {
        state.receiptTaxInvoiceList = payload;
      },
      SET_RECEIPT_TAX_INVOICE_BY_ID: (state, payload) => {
        if(payload) {
          state.receiptTaxInvoiceById = payload;
        } else {
          state.receiptTaxInvoiceById = {};
        }
      },
      SET_RECEIPT_TAX_INVOICE_REPORT_QUERY: (state, payload) => {
        state.receiptTaxInvoiceReportQuery = payload;
      },
      SET_RECEIPT_TAX_INVOICE_REPORT: (state, payload) => {
        state.receiptTaxInvoiceReportList = payload;
      },
    },
    actions: {
      GetReceiptTaxInvoice: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.receiptTaxInvoice
            .getReceiptTaxInvoice(params)
            .then(({ data }) => {
                context.commit("SET_RECEIPTTAXINVOICE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetReceiptTaxReportReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_RECEIPT_TAX_INVOICE_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetReceiptTaxInvoice: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receiptTaxInvoice
            .addReceiptTaxInvoice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'receiptTax.message.receiptTaxSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditReceiptTaxInvoice: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receiptTaxInvoice
            .editReceiptTaxInvoice(payload.tax_id, payload.invoice)
            .then((res) => {
              if (res.data.type === 'confirmation') {
                context.commit('SET_BOOKKEEPING_CONFIRMATION', res.data, {root: true});
              }
              let successData = Object.assign({ success: 'receiptTax.message.receiptTaxSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetReceiptTaxInvoiceById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.receiptTaxInvoice
              .getReceiptTaxInvoiceById(payload)
              .then(({ data }) => {
                context.commit('SET_RECEIPT_TAX_INVOICE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteReceiptTaxInvoiceItemById: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receiptTaxInvoice
            .deleteReceiptTaxInvoice(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'receiptTax.message.receiptTaxSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default receiptTaxInvoice;