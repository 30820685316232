export default {
  supplierInvoiceList: "Supplier Invoice List",
  invoiceDate: "Invoice Date",
  vatDate: "VAT Date",
  deliveryNo: "Delivery Note No.",
  deliveryYear: "Delivery Note Year",
  supplierInvoiceAction: "Supplier Invoice Action",
  supplierInvoiceDetail: "Supplier Invoice Details",
  supplierInvoiceItems: "Supplier Invoice Items",
  invoiceNo: "Invoice No.",
  supplierNumber: "Supplier Number",
  totalPerSupplier: "Total Per Supplier",
  supplierTotal: "Supplier Total",
  SupplierInvoice: "Supplier Invoice",
  SupplierCreditInvoice: "Supplier Credit Invoice",
  creditInvoiceNo: "Credit Invoice No.",
  supplierInvoiceNo: "Supplier Invoice No.",
  supplierDeliveryNoteNo: "Supplier Delivery Note No.",
  SupplierCreditInvoiceNo: "Supplier Credit Invoice No.",
  amount: "Amount",
  message: {
    supplierInvoiceSuccessfullyDeleted: "Supplier Invoice cancelled successfully",
    supplierInvoiceSuccessfullyAdded: "Supplier Invoice added successfully",
    supplierInvoiceSuccessfullyEdited: "Supplier Invoice edited successfully",
    invoiceNoAlreadyUsed: "Invoice no. is already used",
    creditInvoiceNoAlreadyUsed: "Credit Invoice no. is already used",
  },
};
