import Vue from 'vue';

const returnCertificate = {
    state: {
      returnCertificateList: [],
      returnCertificateById: {},
      returnCertificateQuery: null,
      returnCertificateReportList: [],
      returnCertificateReportQuery: null,
      rowIndex: null,
    },
    getters: {
      returnCertificateList: (state) => state.returnCertificateList,
      returnCertificateById: (state) => state.returnCertificateById,
      returnCertificateQuery: (state) => state.returnCertificateQuery,
      returnCertificateReportList: (state) => state.returnCertificateReportList,
      returnCertificateReportQuery: (state) => state.returnCertificateReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_RETURNCERTIFICATE_QUERY: (state, payload) => {
        state.returnCertificateQuery = payload;
      },
      SET_RETURNCERTIFICATE: (state, payload) => {
        state.returnCertificateList = payload;
      },
      SET_RETURN_CERTIFICATE_BY_ID: (state, payload) => {
        if(payload) {
          state.returnCertificateById = payload;
        } else {
          state.returnCertificateById = {};
        }
      },
      SET_RETURN_CERTIFICATE_REPORT_QUERY: (state, payload) => {
        state.returnCertificateReportQuery = payload;
      },
      SET_RETURN_CERTIFICATE_REPORT: (state, payload) => {
        state.returnCertificateReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetReturnCertificate: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.returnCertificate
            .getReturnCertificate(params)
            .then(({ data }) => {
                context.commit("SET_RETURNCERTIFICATE", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetReturnCertificateReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_RETURN_CERTIFICATE_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetReturnCertificate: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.returnCertificate
            .addReturnCertificate(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'returnCertificate.message.returnCertificateSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditReturnCertificate: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.returnCertificate
            .editReturnCertificate(payload.tax_id, payload.delivery)
            .then((res) => {
              let successData = Object.assign({ success: 'returnCertificate.message.returnCertificateSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetReturnCertificateById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.returnCertificate
              .getReturnCertificateById(payload)
              .then(({ data }) => {
                context.commit('SET_RETURN_CERTIFICATE_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteReturnCertificateItemById: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.returnCertificate
            .deleteReturnCertificate(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'returnCertificate.message.returnCertificateSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default returnCertificate;