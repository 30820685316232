const trialBalancePagination = {
    state: {
        pageChange: {}
    },
    getters: {
        pageChange: (state) => state.pageChange
    },
    mutations: {
        SHOW_PAGE_CHANGE: (state, payload) => {
            state.pageChange = payload;
        }
    },
    namespaced: true,
}
export default trialBalancePagination;