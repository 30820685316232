export default {
  receipt: 'קבלה',
  receiptActions: "פעולות קבלה",
  editReceipt: "ערוך קבלה",
  addReceipt: "הוסף קבלה",
  save:"שמור",
  getInvoices: "קבל חשבונית",
  receiptList: "רשימת קבלות",
  receiptItem: "קבלות תשלומים",
  id: "קוד",
  action: "פעילות",
  no: "מס' שורה",
  paymentType: "סוג תשלום",
  chequeNumber: "מספר המחאה/אישור",
  bankNo: "בנק/חברת אשראי",
  accountNo: "מספר חשבון/כרטיס",
  branch: "סניף/תוקף",
  amount: "סכום",
  total: 'סה"כ',
  receiptDetail: "פרטי קבלה",
  customerDetail: "מידע לקוח",
  additionalCustomerDetail: "פרטי לקוח נוספים",
  listOfItems: "רשימת פריטים",
  listOfPayment: "רשימת תשלומים",
  from: "מ",
  to: "עד",
  originalAmount: "סכום מקורי",
  originalBalance: "יתרה מקורית",
  amountPaid: "סכום משולם",
  updatedDocuments: "מסמכים מעודכנים",    
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח קבלה",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerGroup: "קבוצה של לקוחות",
  totalPerReport: "סך הכל לדוח",
  totalPerPaymentType: "סך התשלום",
  bank: "בַּנק",
  newUpdate: "עדכון מחדש",
  receiptStatus: "סטטוס קבלות",
  message: {
    receiptSuccessfullyDeleted: "קבלה נמחקה בהצלחה",
    receiptSuccessfullyAdded: "קבלה נוספה בהצלחה",
    receiptSuccessfullyEdited: "קבלה נערכה בהצלחה",
  },
  field: {
    customer: "לקוח",
    customerName: "שם לקוח",
    receiptDate: "תאריך קבלה",
    paymentDate:"תאריך תשלום",
    address: "כתובת לקוח",
    town: "עיר",
    country: "מדינה",
    tel1: "טל. נוסף 1",
    tel2: "טל. נוסף 2",
    cel: "נוסף סלולרי",
    fax: "נוסף פקס",
    email: "אי מייל",
    authorizedDealerNo: "מספר עוסק מורשה",
    agent: "סוכן",
    remarks: "הערות",
    status: "מצב הצעה",
    total: 'סה"כ',
    discount: "מס במקור",
    afterDiscount: "לאחר הנחה",
    grandTotal: 'סה"כ סופי',
    all: "כולם",
    open: "פתוח",
    finished: "סגור",
    canceled: "מבוטל",
    receiptNo: "קבלה מס.",
    receiptYear: "שנת קבלה",
    balance: "איזון",
    accountUpdate: 'הנה"ח',
    year: "שָׁנָה",
    customerId: "מספר לקוח",
    employeeId: "כרטיס עובד",
    withHoldingTax: "ניכוי מס במקור",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    receiptCINo: "קבלה/ מס' CI"
  }
}