export default {
  supplierCreditInvoice: "Supplier Credit Invoice",
  supplierCreditInvoiceList: "Supplier Credit Invoice List",
  supplierCreditInvoiceDate: "Supplier Credit Invoice Date",
  supplierGroup: "Supplier Group",
  supplierCreditInvoiceItems: "Supplier Credit Invoice Items",
  supplierCreditInvoiceAction: "Supplier Credit Invoice Action",
  supplierCreditInvoiceDetail: "Supplier Credit Invoice Detail",
  getSupplierInvoices: "Get Supplier Invoices",
  message: {
    supplierCreditInvoiceSuccessfullyDeleted: "Supplier Credit Invoice Successfully Deleted",
    supplierCreditInvoiceSuccessfullyAdded: "Supplier Credit Invoice Successfully Added",
    supplierCreditInvoiceSuccessfullyEdited: "Supplier Credit Invoice Successfully Edited"
  }
}