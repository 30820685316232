export default {
  detailed: {
    date: "תאריך",
    type: "סוג",
    paymentType: "סוג תשלום",
    no: "מספר",
    bank: "בנק",
    accountNo: "חשבון",
    name: "לקוח/ספק",
    reference: "אסמכתא",
    credit: "זכות",
    debit: "חובה",
    balance: "יתרה",
  },
  monthly: {
    month: "חודש",
    incoming: "זכות",
    outgoing: "חובה",
    totalMonthly: 'סה"כ חודשי',
    balance: "מצטבר",
  },
  focused: {
    incoming: "זכות",
    outgoing: "חובה",
    balance: "מצטבר",
  },
  daily: {
    date: "תאריך",
    day: "יום ",
    credit: "זכות",
    debit: "חובה",
    dailyBalance: "יומי",
    balance: "יתרה",
  },
  days: {
    Monday: "ב",
    Tuesday: "ג",
    Wednesday: "ד",
    Thursday: "ה",
    Friday: "ו",
    Saturday: "שבת",
    Sunday: "א",
  },
  totalcredit: "סך האשראי",
  totaldebit: "סך חיוב",
  receipt_payment: "קבלה",
  receipt_tax_invoice_payment: "חשבונית/קבלה",
  supplier_receipt_payment: "קבלה של ספק",
  withoutCustomer: "ללא לקוח",
  withoutSupplier: "ללא ספק",
};
