export default {
  employee: "עובד",
  employeeActions: "פעילות עובד",
  editEmployee: "ערוך עובד",
  addEmployee: "הוסף עובד",
  addEmployees: "הוסף עובדים",
  employeeName: "שם עובד",
  save: "שמור",
  employeesList: "רשימת עובדים",
  id: "קוד",
  action: "פעולה",
  group: "קבוצה",
  basicDetails: "מידע בסיסי",
  otherDetails: "מידע נוסף",
  displayText: "תקשורת",
  communication: "תקשורת",
  extension: "שלוחה",
  no: "מספר",
  areaCode: "קידומת",
  country: "מדינה",
  tel: "טלפון",
  cel: "סלולר",
  fax: "פקס",
  filters: "מסננים",
  excel: "Excel",
  resetFilter: "אפס מסנן",
  employeeGroup: "קבוצת עובדים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  selectFields: "בחר שדות",
  update: "עִדכּוּן",
  selectAll: "בחר הכל",
  debitReport: "דוח עוֹבֵד",
  attachedDocuments: "קבצים מצורפים",
  show: "הורד",
  view: "נוף",
  date: "תַאֲרִיך",
  details: "פרטים",
  file: "מסמכים",
  documentType: "סוג מסמך",
  message: {
    employeeSuccessfullyDeleted: "עובד נמחק בהצלחה",
    employeeSuccessfullyAdded: "עובד נוסף בהצלחה",
    employeeSuccessfullyEdited: "עובד נערך בהצלחה",
    pictureSuccessfullyDeleted: "תמונה נמחקה בהצלחה",
    pictureSuccessfullyAdded: "תמונה נוספה בהצלחה",
    pictureSuccessfullyEdited: "תמונה נערכה בהצלחה",
    attachedDocumentsAdded: "מצורף מסמך בהצלחה",
    attachedDocumentsSuccessfullyDeleted: "מצורף מסמך נמחקה בהצלחה",
    attachedDocumentsSuccessfullyEdited: "המסמך המצורף נערך בהצלחה"
  },
  field: {
    employeeNo: "מספר עובד",
    recordType: "סוג רשומה",
    selectPicture: "בחר תמונה",
    group: "קבוצה עובד",
    function: "תפקיד",
    machine: "מכונה",
    status: "סטטוס",
    role: "תפקיד",
    code: "קוד",
    email: "אי מייל",
    password: "סיסמה",
    intrnalMail: "מייל פנימי",
    startDate: "תאריך התחלה",
    endDate: "תאריך סיום",
    address: "כתובת",
    accountNo: "מספר חשבון",
    town: "עיר",
    site: "אתר",
    note: "הערה",
    po: ".ת.ד",
    postalCode: "מיקוד",
    sms1: "SMS 1",
    sms2: "SMS 2",
    countryPrefix0: "מדינה",
    areaPrefix0: "קידומת אזור 1",
    areaPrefix1: "קידומת אזור 2",
    areaPrefix2: "קידומת אזור 3",
    areaPrefix3: "קידומת אזור 4",
    areaPrefix4: "קידומת אזור 5",
    areaPrefix5: "קידומת אזור 6",
    phones0: "טלפון",
    extension0: "שלוחה",
    countryPrefix1: "מדינה",
    cityPrefix1: "קידומת",
    phones1: "טלפון",
    extension1: "שלוחה",
    countryPrefix2: "מדינה",
    cityPrefix2: "קידומת",
    phones2: "טלפון",
    extension2: "שלוחה",
    countryPrefix3: "מדינה",
    cityPrefix3: "קידומת",
    cellular1: "1 סלולר",
    cellular2: "2 סלולר",
    cellular3: "3 סלולר",
    countryPrefix4: "מדינה",
    cityPrefix4: "קידומת",
    cellular4: "4 סלולר",
    countryPrefix5: "מדינה",
    cityPrefix5: "קידומת",
    cellular5: "5 סלולר",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    bookKeepingNumber: "מספר הנהלת חשבונות",
    eventDays: "ימי האירוע"
  }
}