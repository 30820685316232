export default {
  quotation: 'Quotation',
  quotationActions: "Quotation Actions",
  editQuotation: "Edit Quotation",
  addQuotation: "Add Quotation",
  save: "Save",
  activityLog: "Activity Log",
  quotationList: "Quotation List",
  id: "ID",
  action: "Action",
  copy: "Copy & Create New",
  options: "Options",
  no: "No",
  product: "Item",
  quotationItem: "Quotation Item",
  quantity: "Quantity",
  unitPrice: "Unit Price",
  lineDiscount: "Line Discount",
  itemDescription: "Item Description",
  lineNumber: "Line Number",
  suppliedQty: "Supplied Quantity",
  remainingQty: "Remaining Quantity",
  total: "Total",
  quotationDetail: "Quotation Detail",
  customerDetail: "Customer Detail",
  additionalCustomerDetail: "Additional Customer Details",
  listOfItems: "List Of Items",
  from: "From",
  to: "To",
  createorder: "Save & Create Order",
  sendByMail: "Send By Mail",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Quotation Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerGroup: "Customer Group",
  message: {
    quotationSuccessfullyDeleted: "Quotation cancelled successfully",
    quotationSuccessfullyAdded: "Quotation added successfully",
    quotationSuccessfullyEdited: "Quotation edited successfully",
  },
  field: {
    customer: "Customer",
    customerName: "Customer Name",
    quotationStartDate: "Quotation start date",
    quotationEndDate: "Quotation end date",
    title: "Title",
    address: "Address",
    town: "Town",
    country: "Country",
    tel1: "Tel 1",
    tel2: "Tel 2",
    cel: "Cel",
    fax: "Fax",
    email: "E-mail",
    authorizedDealerNo: "Authorized dealer number",
    contact: "Contact",
    agent: "Employee",
    remarks: "Remarks",
    timePrinted: "Time printed",
    orderNo: "Order no.",
    invoiceNo: "Invoice no.",
    invoiceYear: "Invoice year",
    status: "Status",
    total: "Total",
    discountPrecentage: "Discount percentage",
    discount: "Discount",
    afterDiscount: "After discount",
    vat: "VAT",
    vatPrecentage: "Vat percentage",
    grandTotal: "Grand total",
    all: "All",
    open: "Open",
    finished: "Closed",
    canceled: "Cancelled",
    contactId: "Contact Id",
    customerId: "Customer Id",
    employeeId: "Employee Id",
    name: "Name",
    userId: "User Id",
    createdAt: "Created At",
    updatedAt: "Updated At",
    quotationStatus: "Quotations status"
  }
}