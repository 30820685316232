export default {
  customer: 'Customer',
  customerActions: "Customer Actions",
  editCustomer: "Edit Customer",
  addCustomer: "Add Customer",
  addCustomers: "Add Customer",
  customerName: "Customer Name",
  customerName2: "Customer Name 2",
  save: "Save",
  saveAndContinue: "Save and continue",
  customersList: "Customer List",
  id: "ID",
  action: "Action",
  group: "Group",
  basicDetails: "Basic Details",
  otherDetails: "Other Details",
  notes: "Notes",
  displayText: "Communication",
  communication: "Communication",
  extension: "Extension",
  no: "No.",
  areaCode: "Area Code",
  country: "Country",
  tel: "Tel",
  cel: "Cel",
  fax: "Fax",
  customerGroup: "Customer Group",
  area: "Region Group",
  status: "Customer Status",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Customer Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  obligation: "Obligation",
  turnOver: "Turn Over",
  financial: "Financial",
  documentNo: "Document No.",
  documentType: "Document Type",
  customerOrderNo: "Customer Order No.",
  from: "From",
  paymentDate: "Payment Date",
  originalAmount: "Original Amount",
  balance: "Balance",
  agent: "Employee",
  amount: "Amount",
  paymentType: "Payment Type",
  originalDate: "Original Date",
  Invoices: "Invoices",
  ReceiptTaxInvoices: "ReceiptTaxInvoices",
  CreditInvoices: "CreditInvoices",
  message: {
    customerSuccessfullyDeleted: "Customer cancelled successfully",
    customerSuccessfullyAdded: "Customer added successfully",
    customerSuccessfullyEdited: "Customer edited successfully",
  },
  field: {
    salesRepresentative: "Sales representative",
    emailInvoice: "Email for invoice",
    termsOfPayment: "Terms of Payment",
    group: "Customer group",
    area: "Region",
    status: "Status",
    address1: "Address 1",
    pobox1: "P.O.Box 1",
    postalCode1: "Postal Code 1",
    town1: "Town 1",
    address2: "Address 2",
    pobox2: "P.O.Box 2",
    postalCode2: "Postal Code 2",
    town2: "Town 2",
    state: "State",
    country: "Country",
    neighborhood: "Neighborhood",
    floor: "Floor",
    customerOpeningDate: "Customer opening date",
    customerEndDate: "Customer end date",
    email: "E-mail",
    site: "Site",
    authorizedDealerNo: "Authorized dealer number",
    bookKipingNo: "Bookkeeping number",
    vatCharge: "VAT charge",
    customerOrderNoRequired: "Customer order no. compulsory",
    proposalNote: "Proposal note",
    deliveryNote: "Delivery note",
    orderNote: "Order note",
    invoiceNote: "Invoice note",
    warningNote: "Warning note",
    generalRemarks: "General remarks",
    countryPrefix0: "Country prefix",
    dialingArea0: "Area prefix",
    phones0: "Phones",
    extension0: "Extension",
    countryPrefix1: "Country prefix 1",
    dialingArea1: "Dialing area 1",
    cellular1: "Cellular 1",
    phones1: "Phones 1",
    extension1: "Extension 1",
    countryPrefix2: "Country prefix 2",
    dialingArea2: "Dialing area 2",
    cellular2: "Cellular 2",
    phones2: "Phones 2",
    extension2: "Extension 2",
    countryPrefix3: "Country prefix 3",
    dialingArea3: "Dialing area 3",
    cellular3: "Cellular 3",
    countryPrefix4: "Country prefix 4",
    dialingArea4: "Dialing area 4",
    cellular4: "Cellular 4",
    countryPrefix5: "Country prefix 5",
    dialingArea5: "Dialing area 5",
    cellular5: "Cellular 5",
    createdAt: "Created At",
    updatedAt: "Updated At",
    invoices: "Invoices",
    futureChecks: "Future Checks",
    proformaInvoices: "Proforma Invoices",
    deliveryNotes: "Delivery Notes",
    customerOrders: "Customer Orders",
    totalObligations: "Total Obligations",
    thisYear: "This Year",
    lastYear: "Last Year",
    before: "Before",
    totalTurnOver: "Total Turn Over",
    maximumCredit: "Maximum Credit",
    checkCredit: "Check Credit",
    checkPaymentDates: "Check Payment Dates",
    restrictedCustomer: "Restricted Customer",
    fixedDiscount: "Fixed Discount %"
  }
}