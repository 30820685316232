import Vue from 'vue';
const debitReport = {
    state: {
      debitReportList: [],
      debitReportQuery: null,
      rowIndex: null,
      cashFlowReportList: [],
      cashFlowReportQuery: null
    },
    getters: {
      debitReportList: (state) => state.debitReportList,
      debitReportQuery: (state) => state.debitReportQuery,
      rowIndex: (state) => state.rowIndex,
      cashFlowReportList: (state) => state.cashFlowReportList,
      cashFlowReportQuery: (state) => state.cashFlowReportQuery,
    },
    mutations: {
      SET_DEBITREPORT_QUERY: (state, payload) => {
        state.debitReportQuery = payload;
      },
      SET_DEBITREPORT: (state, payload) => {
        state.debitReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      },
      SET_CASHFLOW_REPORT_QUERY: (state, payload) => {
        state.cashFlowReportQuery = payload;
      },
      SET_CASHFLOW_REPORT: (state, payload) => {
        state.cashFlowReportList = payload;
      },
    },
    actions: {
      GetDebitReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.debitReport
              .getDebitReport(params)
              .then(({ data }) => {
                context.commit("SET_DEBITREPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      GetCashFlowReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.cashFlow
              .getCashFlowReport(params)
              .then(({ data }) => {
                context.commit("SET_CASHFLOW_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
    },
    namespaced: true,
  }
  export default debitReport;