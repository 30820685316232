export default {
  contact: 'איש קשר',
  contactActions: "פעילות איש קשר",
  editContact: "ערוך איש קשר",
  addContact: "הוסף איש קשר",
  addContacts: "הוסף אנשי קשר",
  contactName: "שם איש קשר",
  save:"שמור",
  contactsList: "רשימת אנשי קשר",
  id: "קוד",
  action: "פעילות",
  basicDetails: "מידע בסיסי",
  otherDetails: "מידע נוסף",
  displayText: "תקשורת",
  communication: "תקשורת",
  extension: "שלוחה",
  no: "מספר",
  areaCode: "קידומת",
  country: "מדינה",
  tel: "טלפון",
  cel: "סלולר",
  fax: "פקס",
  message: {
    contactSuccessfullyDeleted: "איש קשר נמחק בהצלחה",
    contactSuccessfullyAdded: "איש קשר נוסף בהצלחה",
    contactSuccessfullyEdited: "איש קשר נערך בהצלחה",
  },
  field: {
    jobTitle: "תפקיד",
    email: "אי מייל",
    site: "אתר",
    remarks: "הערות",
    recordType: "Record type",
    sms: "SMS",
    countryPrefix0: "מדינה",
    dialingArea0: "קידומת",
    phones0: "טלפון",
    extension0: "שלוחה",
    countryPrefix1: "מדינה",
    dialingArea1: "קידומת",
    phones1: "טלפון",
    extension1: "שלוחה",
    countryPrefix2: "מדינה",
    dialingArea2: "קידומת",
    phones2: "טלפון",
    extension2: "שלוחה",
    countryPrefix3: "מדינה",
    dialingArea3: "קידומת",
    cellular3: "סלולר",
    countryPrefix4: "מדינה",
    dialingArea4: "שלוחה",
    cellular4: "סלולר",
    countryPrefix5: "מדינה",
    dialingArea5: "קידומת",
    cellular5: "סלולר",
  }
}