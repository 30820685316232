import Vue from "vue";
const supplierPayment = {
  state: {
    supplierPaymentList: [],
    supplierPaymentById: {},
    supplierPaymentQuery: null,
    supplierPaymentReportList: [],
    supplierPaymentReportQuery: null,
    rowIndex: null,
  },
  getters: {
    supplierPaymentList: (state) => state.supplierPaymentList,
    supplierPaymentById: (state) => state.supplierPaymentById,
    supplierPaymentQuery: (state) => state.supplierPaymentQuery,
    supplierPaymentReportList: (state) => state.supplierPaymentReportList,
    supplierPaymentReportQuery: (state) => state.supplierPaymentReportQuery,
    rowIndex: (state) => state.rowIndex,
  },
  mutations: {
    SET_SUPPLIER_PAYMENT_QUERY: (state, payload) => {
      state.supplierPaymentQuery = payload;
    },
    SET_SUPPLIER_PAYMENT: (state, payload) => {
      state.supplierPaymentList = payload;
    },
    SET_SUPPLIER_PAYMENT_BY_ID: (state, payload) => {
      if (payload) {
        state.supplierPaymentById = payload;
      } else {
        state.supplierPaymentById = {};
      }
    },
    SET_SUPPLIER_PAYMENT_REPORT_QUERY: (state, payload) => {
      state.supplierPaymentReportQuery = payload;
    },
    SET_SUPPLIER_PAYMENT_REPORT: (state, payload) => {
      state.supplierPaymentReportList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
  },
  actions: {
    GetSupplierPayment: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierPayment
            .getSupplierPayment(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_PAYMENT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    SetUpdateSupplierReceipt: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPayment
          .updateSupplierReceipt(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetSupplierPaymentReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.receiptSalesReport
            .getReceiptSalesReport(params)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_PAYMENT_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    SetSupplierPayment: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPayment
          .addSupplierPayment(payload)
          .then((res) => {
            let successData = Object.assign({
              success:
                "supplierPayment.message.supplierPaymentSuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              if (error.response?.data?.type === "error") {
                // context.commit("multiple/SET_PERIOD_CLOSURE", error.response?.data?.message, { root: true });
              } else {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditSupplierPayment: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPayment
          .editSupplierPayment(
            payload.supplier_receipt_id,
            payload.supplierPayment
          )
          .then((res) => {
            if (res.data.abc && res.data.abc.type === "confirmation") {
              context.commit("SET_BOOKKEEPING_CONFIRMATION", res.data, {
                root: true,
              });
            } else {
              let successData = Object.assign({
                success:
                  "supplierPayment.message.supplierPaymentSuccessfullyEdited",
              });
              context.dispatch("SetSnackBar", successData, { root: true });
              resolve(res);
            }
          })
          .catch((error) => {
            if (error.response?.data) {
              if (error.response?.data?.type === "error") {
                // context.commit("multiple/SET_PERIOD_CLOSURE",error.response?.data?.message, { root: true });
              } else {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
            }
            console.log(error);
            reject(error);
          });
      });
    },
    GetSupplierPaymentById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.supplierPayment
            .getSupplierPaymentById(payload)
            .then(({ data }) => {
              context.commit("SET_SUPPLIER_PAYMENT_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    DeleteSupplierPaymentById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.supplierPayment
          .deleteSupplierPaymentById(payload)
          .then((res) => {
            if (res?.data?.type !== "delete") {
              let successData = Object.assign({
                success:
                  "supplierPayment.message.supplierPaymentSuccessfullyDeleted",
              });
              context.dispatch("SetSnackBar", successData, { root: true });
            }
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
  },
  namespaced: true,
};
export default supplierPayment;
