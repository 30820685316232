import Vue from 'vue';
const quotation = {
    state: {
      quotationList: [],
      quotationById: {},
      quotationQuery: null,
      quotationReportList: [],
      quotationReportQuery: null,
      rowIndex: null,
    },
    getters: {
      quotationList: (state) => state.quotationList,
      quotationById: (state) => state.quotationById,
      quotationQuery: (state) => state.quotationQuery,
      quotationReportList: (state) => state.quotationReportList,
      quotationReportQuery: (state) => state.quotationReportQuery,
      rowIndex: (state) => state.rowIndex,
    },
    mutations: {
      SET_QUOTATION_QUERY: (state, payload) => {
        state.quotationQuery = payload;
      },
      SET_QUOTATION: (state, payload) => {
        state.quotationList = payload;
      },
      SET_QUOTATION_BY_ID: (state, payload) => {
        if(payload) {
          state.quotationById = payload;
        } else {
          state.quotationById = {};
        }
      },
      SET_QUOTATION_REPORT_QUERY: (state, payload) => {
        state.quotationReportQuery = payload;
      },
      SET_QUOTATION_REPORT: (state, payload) => {
        state.quotationReportList = payload;
      },
      SET_ROWINDEX: (state, payload) => {
        state.rowIndex = payload;
      }
    },
    actions: {
      GetQuotation: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.quotation
              .getQuotation(params)
              .then(({ data }) => {
                context.commit("SET_QUOTATION", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getquotation', err);
        }
      },
      GetChartQuotation: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.quotation
              .getChartQuotation(params)
              .then(({ data }) => {
                context.commit("SET_QUOTATION", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] Getquotation', err);
        }
      },
      GetQuotationReport: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.salesReport
              .getSalesReport(params)
              .then(({ data }) => {
                context.commit("SET_QUOTATION_REPORT", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetQuotation: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.quotation
            .addQuotation(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'quotation.message.quotationSuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditQuotation: async (context, payload) => {
        context.commit('multiple/SET_PERIOD_CLOSURE', '', {root: true});
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.quotation
            .editQuotation(payload.quotation_id, payload.quotation)
            .then((res) => {
              let successData = Object.assign({ success: 'quotation.message.quotationSuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                if (error.response?.data?.type === 'error') {
                  context.commit('multiple/SET_PERIOD_CLOSURE', error.response?.data?.message, {root: true});
                } else {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetQuotationById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.quotation
              .getQuotationById(payload)
              .then(({ data }) => {
                context.commit('SET_QUOTATION_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetQuotation', err);
        }
      },
      DeleteQuotationItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.quotation
            .deleteQuotation(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'quotation.message.quotationSuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData, {root: true});
              // context.dispatch('GetQuotation', {page: 1, limit: 10, where_and: 'status|'+0});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
      CheckRemainingQty: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.checkRemainingQty
            .checkRemainingQty(payload)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                // let errorData = Object.assign({ error: error.response.data})
                // context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      },
    },
    namespaced: true,
  }
  export default quotation;