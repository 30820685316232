export default {
    documentSeries: "סדרת מסמכים",
    series: "סדרה",
    from: "מתוך מספר",
    description: "תאור",
    year: "שָׁנָה",
    invoice: "חשבונית",
    creditInvoice: "חשבונית זיכוי",
    proformaInvoice: "חשבון פרופורמה",
    receipt: "קבלה",
    receiptTaxInvoice: "חשבונית/קבלה",
    delivery: "תעודת משלוח",
    returnCertificate: "תעודת החזרה",
    action: "פעילות",
    docNumber: "מספר מסמך",
    selectNumber: "בחר מספר סדרת מסמכים",
    index: "אינדקס",
    message: {
        documentSeriesSuccessfullyAdded: "סדרת מסמכים נוסף בהצלחה",
        documentSeriesSuccessfullyDeleted: "סדרת מסמכים נמחק בהצלחה",
        confirm: "נא לפתוח מספר סדרה עבור השנה המבוקשת"
    }
}