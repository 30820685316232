export default {
  titleIn: 'Login',
  titleUn: 'Sing Up Form',
  singIn: 'Confirmation',
  singUp: 'Sing Up',
  email: 'Email',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: "Confirm Password",
  send: "Send",
  rememberPassword: "Remember Password?",
  emailHasBeenSent: "Email has been sent!",
  checkYourInbox: "please check your inbox and click in the received link to reset a password",
  changePassword: 'Change Password?',
  forgotPassword: 'Forgot Password?',
  resetPassword: "Reset Password",
  confirm: "Confirm",
  reset: "Reset",
  loginWithCompany: "Login with your company",
  pleaseSignIn: "Please sign-in to your account and start the adventure",
  pleaseForgotPassword: "Enter your registered email below to receive password reset instruction",
  welcomeTo: "Welcome to Bina!",
  newPlatform: "New on our platform?",
  createAccount: "Create an account",
  successFullylogin: "You have successfully logged in to Bina",
  passwordResetSuccessfully: "Password reset successfully",
  rememberMe: "Remember me",
  lastLoggedIn: "Last logged in"
}