export default {
  supplierPaymentList: "רשימת תשלומים של ספקים",
  supplierPaymentNo: "מס' תשלום ספק",
  supplierPaymentDetail: "פרטי תשלום ספק",
  supplierPaymentItem: "פריט תשלום לספק",
  message: {
    supplierPaymentSuccessfullyAdded: "תשלום הספק בוטל בהצלחה",
    supplierPaymentSuccessfullyEdited: "תשלום ספק נוסף בהצלחה",
    supplierPaymentSuccessfullyDeleted: "תשלום הספק נערך בהצלחה",
  },
};
