export default {
  quotation: 'הצעה',
  quotationActions: "אפשרויות הצעת מחיר",
  editQuotation: "ערוך הצעה",
  addQuotation: "הוסף הצעה",
  save:"שמור",
  quotationList: "רשימת הצעות",
  id: "קוד",
  options: "אפשרויות",
  copy: "העתק וצור חדש",
  action: "פעילות",
  no: "מס' שורה",
  activityLog: "יומן פעילות",
  product: "פריט",
  quotationItem: "פריט הצעה",
  quantity: "כמות",
  unitPrice: "מחיר יחידה",
  lineDiscount: "הנחה",
  lineNumber: "מספר קו",
  suppliedQty: "כמות שסופקה",
  remainingQty: "כמות שנותרה",
  itemDescription: "תאור פריט",
  total: 'סה"כ',
  quotationDetail: "מידע הצעת מחיר",
  customerDetail: "מידע לקוח",
  additionalCustomerDetail: "פרטי לקוח נוספים",
  listOfItems: "רשימת פריטים",
  from: "מ",
  to: "עד",
  createorder:"שמור וצור הזמנה",
  sendByMail: "שלח בדואר",
  resetFilter: "אפס מסנן",
  filters: "מסננים",
  execute: "בצע",
  reset: "אתחול",
  fieldView: "תצוגת שדה",
  debitReport: "דוח הצעת מחיר",
  selectFields: "בחר שדות",
  confirm: "אשר",
  cancel: "לְבַטֵל",
  selectAll: "בחר הכל",
  excel: "Excel",
  update: "עִדכּוּן",
  customerGroup: "קבוצה של לקוחות",
  message: {
    quotationSuccessfullyDeleted: "הצעה נמחק בהצלחה",
    quotationSuccessfullyAdded: "הצעה נוסף בהצלחה",
    quotationSuccessfullyEdited: "הצעה נערך בהצלחה",
  },
  field: {
    customer: "לקוח",
    customerName: "שם לקוח",
    quotationStartDate: "תאריך הצעה",
    quotationEndDate: "תוקף ההצעה",
    title: "כותרת הצעת מחיר",
    address: "כתובת לקוח",
    town: "עיר",
    country: "מדינה",
    tel1: "טל. נוסף 1",
    tel2: "טל. נוסף 2",
    cel: "נוסף סלולרי",
    fax: "נוסף פקס",
    email: "אי מייל",
    authorizedDealerNo: "מספר עוסק מורשה",
    contact: "איש קשר",
    agent: "סוכן",
    remarks: "הערות",
    timePrinted: "שעת הדפסה",
    orderNo: "מספר הזמנה",
    invoiceNo: "מספר חשבונית",
    invoiceYear: "שנת חשבונית",
    status: "מצב הצעה",
    total: 'סה"כ',
    discountPrecentage: "אחוז הנחה",
    discount: "הנחה",
    afterDiscount: "לאחר הנחה",
    vat: 'מע"מ',
    vatPrecentage: 'אחוז מע"מ',
    grandTotal: 'סה"כ סופי',
    all: "כולם",
    open: "פתוח",
    finished: "סגור",
    canceled: "מבוטל",
    contactId: "זיהוי איש קשר",
    customerId: "מספר לקוח",
    employeeId: "כרטיס עובד",
    name: "שֵׁם",
    userId: "זהות המשתמש",
    createdAt: "נוצר ב",
    updatedAt: "מעודכן ב",
    quotationStatus: "סטטוס הצעות מחיר"
  }
}