export default {
    id: "id",
    description: "Description",
    newsDate: "News Date",
    action: "Actions",
    addNews: "Add News",
    newsList: "News List",
    newsAction: "News Action",
    news: "News",
    message: {
        newsSuccessfullyDeleted: "News cancelled successfully",
        newsSuccessfullyAdded: "News added successfully",
        newsSuccessfullyEdited: "News edited successfully",
    }
}