import Vue from 'vue';
const company = {
    state: {
      companyList: [],
      companyById: {},
    },
  
    getters: {
      companyList: (state) => state.companyList,
      companyById: (state) => state.companyById,
    },
    mutations: {
      SET_COMPANY: (state, payload) => {
        state.companyList = payload;
      },
      SET_COMPANY_BY_ID: (state, payload) => {
        if(payload) {
          state.companyById = payload;
        } else {
          state.companyById = {};
        }
      },
    },
    actions: {
      GetCompany: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.company
              .getCompany(params)
              .then(({ data }) => {
                context.commit("SET_COMPANY", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      SetCompany: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.company
            .addCompany(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'company.message.companySuccessfullyAdded'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      EditCompany: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.company
            .editCompany(payload.company_id, payload.company)
            .then((res) => {
              let successData = Object.assign({ success: 'company.message.companySuccessfullyEdited'})
              context.dispatch('SetSnackBar', successData);
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
      GetCompanyById: async (context, payload) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.company
              .getCompanyById(payload)
              .then(({ data }) => {
                context.commit('SET_COMPANY_BY_ID', data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData);
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRoles', err);
        }
      },
      DeleteCompanyItemById:async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.company
            .deleteCompany(payload.id)
            .then((res) => {
              let successData = Object.assign({ success: 'company.message.companySuccessfullyDeleted'})
              context.dispatch('SetSnackBar', successData);
              // context.dispatch('GetCompany', {page: 1, limit: 10});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData);
              }
              console.log(error)
              reject(error)
            });
        });
      },
    }
  }
  export default company;