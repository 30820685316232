import Vue from "vue";
const periodClosure = {
  state: {
    periodClouserList: [],
    periodClouserQuery: null,
  },
  getters: {
    periodClouserList: (state) => state.periodClouserList,
    periodClouserQuery: (state) => state.periodClouserQuery,
  },
  mutations: {
    SET_PERIOD_CLOUSER_LIST_QUERY: (state, payload) => {
      state.periodClouserQuery = payload;
    },
    SET_PERIOD_CLOSURE: (state, payload) => {
      state.periodClouserList = payload;
    },
  },
  actions: {
    GetPeriodClosure: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.periodClosure
            .getPeriodClosure(params)
            .then(({ data }) => {
              context.commit("SET_PERIOD_CLOSURE", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetBank", err);
      }
    },
    SetPeriodClosure: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.periodClosure
          .setPeriodClosure(payload)
          .then((res) => {
            let successData = Object.assign({
              success: "invoice.message.periodClosureSuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    DeletePeriodClosureById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.periodClosure
          .deletePeriodClosure(payload)
          .then((res) => {
            let successData = Object.assign({ success: 'invoice.message.periodClosureSuccessfullyDeleted'})
            context.dispatch('SetSnackBar', successData, {root: true});
            resolve(res);
          })
          .catch((error) => {
            if(error.response?.data) {
              let errorData = Object.assign({ error: error.response.data})
              context.dispatch('SetSnackBar', errorData, {root: true});
            }
            console.log(error)
            reject(error)
          });
      });
    },
  },
  namespaced: true,
};
export default periodClosure;
