export default {
  home: "בית",
  about: "אודות",
  dashboard: 'לוח מחוונים',
  roles: "תפקיד",
  companies: "חברות",
  employee: "עובד",
  customer: "לקוח",
  customerCatalog: "קטלוג לקוחות",
  customerReport: "דוח לקוח",
  employeeCatalog: "קטלוג עובדים",
  employeeReport: "דוח עוֹבֵד",
  bankCatalog: "קטלוג הבנקים",
  bankReport: "דוח בנק",
  productCatalog: "קטלוג פריטים",
  productReport: "דוח פריט",
  supplierCatalog: "קטלוג ספקים",
  supplierReport: "דוח ספק",
  documentation: 'תיעוד',
  rolesAction: "הרשאות",
  employeeAction: "פעילות עובד",
  companyAction: "פעילות חברה",
  customerAction: "פעילות לקוח",
  auxiliaryTable: "קטגוריה",
  settings: 'הגדרות כרטיסים',
  companySetting: "הגדרות חברה",
  product: "פריט",
  productAction: "פעילות פריט",
  accounts: "כרטסות",
  supplier: "ספק",
  supplierAction: "פעילות ספק",
  orderManagement: "מכירות",
  quotation: "הצעת מחיר",
  quotationAction: "פעילות הצעה",
  quotationCatalog: "קטלוג הצעות מחיר",
  quotationReport: "דוח הצעת מחיר",
  delivery: "תעודת משלוח",
  deliveryAction: "פעולת משלוח",
  deliveryCatalog: "קטלוג משלוחים",
  deliveryReport: "דוח ת.משלוח",
  order: "הזמנה",
  orderAction: "פעילות הזמנה",
  orderCatalog: "קטלוג הזמנות",
  orderReport: "דוח הזמנה",
  invoice: "חשבונית",
  invoiceAction: "פעילות חשבונית",
  invoiceCatalog: "קטלוג חשבוניות",
  invoiceReport: "דוח חשבונית",
  receipt: "קבלה",
  receiptAction: "פעילות קבלה",
  receiptCatalog: "קטלוג קבלות",
  receiptReport: "דוח קבלה",
  bank: "בנק",
  bankAction: "אפשרויות בנק",
  deposit: "הפקדה",
  depositAction: "פעילות הפקדה",
  depositCatalog: "קטלוג הפקדות",
  depositReport: "דוח הפקדה",
  creditInvoice: "חשבונית זיכוי",
  creditInvoiceAction: "פעילות חשבונית זיכוי",
  creditInvoiceCatalog: "חשבונית זיכוי",
  creditInvoiceReport: "דוח חשבונית זיכוי",
  debitReport: 'דו"ח חייבים',
  salesReport: 'דו"ח מכירות',
  activityLog: "יומני פעילות",
  dbQuery:'DB Query',
  proforma: "חשבון פרופורמה",
  proformaAction: "חשבון עסקה זיכוי",
  proformaCatalog: "קטלוג חשבון פרופורמה",
  proformaReport: "דוח חשבון פרופורמה",
  reports: 'דו"חות',
  personalMenu: "תפריט אישי עבור",
  bookKeeping: "הַנהָלַת חֶשְׁבּוֹנוֹת",
  setting: "הגדרות",
  income: "הַכנָסָה",
  expenses: "הוצאות",
  funds: "כְּסָפִים",
  fund: "קופה",
  banks: "בנקים",
  other: "אחרים",
  miscellaneous: "שונות",
  accountingCardReport: "דוח כרטיס הנהלת חשבונות",
  today: "היום",
  addEmployee: "הוסף עובד",
  addCustomer: "הוסף לקוח",
  addSupplier: "הוסף ספק",
  addItem: "הוסף פריט",
  addBank: "הוסף בנק",
  addQuotation: "הוסף הצעה",
  addOrder: "הוסף הזמנה",
  addProforma: "הוסף חשבון פרופורמה",
  addDelivery: "הוסף תעודת משלוח",
  addInvoice: "הוסף חשבונית",
  addCreditInvoice: "הוסף חשבונית זיכוי",
  addReceipt: "הוסף קבלה",
  addDeposit: "הוסף הפקדה",
  receiptTaxInvoice: "חשבונית/קבלה",
  addReceiptTaxInvoice: "הוסף חשבונית/קבלה",
  receiptTaxInvoiceCatalog: "קטלוג חשבונית/קבלה",
  receiptTaxInvoiceAction: "פעילות חשבונית/קבלה",
  receiptTaxInvoiceReport: 'דו"ח חשבונית/קבלה',
  empty: "ריק",
  trialBalance: "מאזן בוחן",
  priceList: "מחירון לקוח",
  quotationIncludingVat: 'הצעת מחיר מסמך כולל מע"מ',
  invoiceIncludingVat: 'חשבונית מסמך כולל מע"מ',
  orderIncludingVat: 'הזמנה מסמך כולל מע"מ',
  creditInvoiceIncludingVat: 'חשבונית זיכוי מסמך כולל מע"מ',
  proformaIncludingVat: 'חשבון פרופורמה מסמך כולל מע"מ',
  receiptTaxInvoiceIncludingVat: 'חשבונית/קבלה מסמך כולל מע"מ',
  deliveryIncludingVat: 'תעודת משלוח מסמך כולל מע"מ',
  returnCertificateVat: 'תעודת החזרה מסמך כולל מע"מ',
  news: "חדשות",
  newsAction: "אקשן חדשות",
  returnCertificate: "תעודת החזרה",
  addReturnCertificate: "הוסף תעודת החזרה",
  returnCertificateCatalog: "קטלוג תעודת החזרה",
  returnCertificateAction: "פעילות תעודת החזרה",
  returnCertificateReport: 'דו"ח תעודת החזרה',
  createFromMultiple: "ריכוז מסמכים",
  createNewDocument: "צור מסמך חדש",
  supplied: "מסופק",
  periodClosure: "סגירת תקופה",
  journalEntry: "יומן",
  addJournalEntry: "הוסף יומן",
  journalEntryAction: "יומן פעולה",
  journalEntryCatalog: "קטלוג יומן",
  journalEntryReport: "דוח יומן",
  purchases: "רכש",
  purchaseOrder: "הזמנת רכש",
  addPurchaseOrder: "הוסף הזמנת רכש",
  purchaseOrderAction: "פעולת הזמנת רכש",
  purchaseOrderCatalog: "קטלוג הזמנת רכש",
  purchaseOrderReport: "דוח הזמנת רכש",
  supplierDeliveryNote: "תעודת משלוח ספק",
  addSupplierDeliveryNote: "הוסף תעודת משלוח ספק",
  supplierDeliveryNoteAction: "פעולה תעודת משלוח ספק",
  supplierDeliveryNoteCatalog: "קטלוג תעודת משלוח ספק",
  supplierDeliveryNoteReport: "דוח תעודת משלוח ספק",
  supplierInvoice: "חשבונית ספק",
  addSupplierInvoice: "הוסף חשבונית ספק",
  supplierInvoiceAction: "פעולת חשבונית ספק",
  supplierInvoiceCatalog: "קטלוג חשבוניות ספקים",
  supplierInvoiceReport: "דוח חשבונית ספק",
  supplierCreditInvoice: "חשבונית זיכוי ספק",
  addSupplierCreditInvoice: "הוסף חשבונית זיכוי ספק",
  supplierCreditInvoiceAction: "פעולת חשבונית זיכוי ספק",
  supplierCreditInvoiceCatalog: "קטלוג חשבונית זיכוי ספק",
  supplierCreditInvoiceReport: "דוח חשבונית זיכוי ספק",
  purchaseReport: "דו”ח רכישות",
  supplierDebts: "חובות לספקים",
  supplierPayment: "תשלום לספק",
  addSupplierPayment: "הוסף תשלום לספק",
  supplierPaymentAction: "פעולת תשלום לספק",
  supplierPaymentCatalog: "קטלוג תשלום לספק",
  supplierPaymentReport: "דוח תשלום לספק",
  purchaseMerging: "ריכוז מסמכים",
  cashFlow: "דוח תזרים מזומנים",
  generalEvaluation: "שערוך כללי"
};
