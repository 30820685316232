import Vue from "vue";
const purchaseOrder = {
  state: {
    purchaseOrderList: [],
    purchaseOrderQuery: null,
    purchaseOrderReportList: [],
    purchaseOrderReportQuery: null,
    rowIndex: null,
    purchaseOrderById: {},
  },
  getters: {
    purchaseOrderList: (state) => state.purchaseOrderList,
    purchaseOrderQuery: (state) => state.purchaseOrderQuery,
    purchaseOrderReportList: (state) => state.purchaseOrderReportList,
    purchaseOrderReportQuery: (state) => state.purchaseOrderReportQuery,
    rowIndex: (state) => state.rowIndex,
    purchaseOrderById: (state) => state.purchaseOrderById,
  },
  mutations: {
    SET_PURCHASE_ORDER_QUERY: (state, payload) => {
      state.purchaseOrderQuery = payload;
    },
    SET_PURCHASE_ORDER: (state, payload) => {
      state.purchaseOrderList = payload;
    },
    SET_PURCHASE_ORDER_REPORT_QUERY: (state, payload) => {
      state.purchaseOrderReportQuery = payload;
    },
    SET_PURCHASE_ORDER_REPORT: (state, payload) => {
      state.purchaseOrderReportList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
    SET_PURCHASE_ORDER_BY_ID: (state, payload) => {
      if (payload) {
        state.purchaseOrderById = payload;
      } else {
        state.purchaseOrderById = {};
      }
    },
  },
  actions: {
    GetPurchaseOrder: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.purchaseOrder
            .getPurchaseOrder(params)
            .then(({ data }) => {
              context.commit("SET_PURCHASE_ORDER", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] Getorder", err);
      }
    },
    GetPurchaseOrderReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.salesReport
            .getSalesReport(params)
            .then(({ data }) => {
              context.commit("SET_PURCHASE_ORDER_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
    GetPurchaseOrderById: async (context, payload) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.purchaseOrder
            .getPurchaseOrderById(payload)
            .then(({ data }) => {
              context.commit("SET_PURCHASE_ORDER_BY_ID", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetOrder", err);
      }
    },
    DeletePurchaseOrderById: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.purchaseOrder
          .deletePurchaseOrder(payload)
          .then((res) => {
            if (res?.data?.type !== "delete") {
              let successData = Object.assign({
                success: "purchaseOrder.message.purchaseOrderSuccessfullyDeleted",
              });
              context.dispatch("SetSnackBar", successData, { root: true });
            }
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    SetPurchaseOrder: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.purchaseOrder
          .addPurchaseOrder(payload)
          .then((res) => {
            let successData = Object.assign({
              success: "purchaseOrder.message.purchaseOrderSuccessfullyAdded",
            });
            context.dispatch("SetSnackBar", successData, { root: true });
            resolve(res);
          })
          .catch((error) => {
            if (error.response?.data) {
              let errorData = Object.assign({ error: error.response.data });
              context.dispatch("SetSnackBar", errorData, { root: true });
            }
            console.log(error);
            reject(error);
          });
      });
    },
    EditPurchaseOrder: async (context, payload) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.purchaseOrder
          .editPurchaseOrder(payload.purchase_order_id, payload.purchaseOrder)
          .then((res) => {
            let successData = Object.assign({ success: 'purchaseOrder.message.purchaseOrderSuccessfullyEdited'})
            context.dispatch('SetSnackBar', successData, {root: true});
            resolve(res);
          })
          .catch((error) => {
            if(error.response?.data) {
              let errorData = Object.assign({ error: error.response.data})
              context.dispatch('SetSnackBar', errorData, {root: true});
            }
            console.log(error)
            reject(error)
          });
      });
    },
  },

  namespaced: true,
};
export default purchaseOrder;
