export default {
  employee: 'Employee',
  employeeActions: "Employee Actions",
  editEmployee: "Edit Employee",
  addEmployee: "Add Employee",
  addEmployees: "Add Employees",
  employeeName: "Employee Name",
  save: "Save",
  employeesList: "Employee List",
  id: "ID",
  action: "Action",
  group: "Group",
  basicDetails: "Basic Details",
  otherDetails: "Other Details",
  displayText: "Communication",
  communication: "Communication",
  extension: "Extension",
  no: "No.",
  areaCode: "Area Code",
  country: "Country",
  tel: "Tel",
  cel: "Cel",
  fax: "Fax",
  filters: "Filters",
  excel: "Excel",
  resetFilter: "Reset filter",
  employeeGroup: "Employee Group",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  selectFields: "Select Fields",
  update: "Update",
  selectAll: "Select All",
  debitReport: "Employee Report",
  attachedDocuments: "Attached Documents",
  show: "Download",
  date: "Date",
  view: "View",
  details: "Details",
  file: "Documents",
  documentType: "Document Type",
  message: {
    employeeSuccessfullyDeleted: "Employee cancelled successfully",
    employeeSuccessfullyAdded: "Employee added successfully",
    employeeSuccessfullyEdited: "Employee edited successfully",
    pictureSuccessfullyDeleted: "Profile picture cancelled successfully",
    pictureSuccessfullyAdded: "Profile picture added successfully",
    pictureSuccessfullyEdited: "Profile picture edited successfully",
    attachedDocumentsAdded: "Document attached successfully",
    attachedDocumentsSuccessfullyDeleted: "Document attached cancelled successfully",
    attachedDocumentsSuccessfullyEdited: "Document attached edited successfully"
  },
  field: {
    employeeNo: "Employee No",
    recordType: "Record type",
    selectPicture: "Select picture",
    group: "Employee group",
    function: "Function",
    machine: "Machine",
    status: "Status",
    role: "Role",
    code: "Code",
    email: "E-mail",
    password: "Password",
    intrnalMail: "Intrnal Mail",
    startDate: "Start date",
    endDate: "End date",
    address: "Address",
    accountNo: "Account No.",
    town: "Town",
    site: "Site",
    note: "Note",
    po: "P.O.Box",
    postalCode: "Postal Code",
    sms1: "SMS 1",
    sms2: "SMS 2",
    countryPrefix0: "Country prefix",
    areaPrefix0: "Area prefix 1",
    areaPrefix1: "Area prefix 2",
    areaPrefix2: "Area prefix 3",
    areaPrefix3: "Area prefix 4",
    areaPrefix4: "Area prefix 5",
    areaPrefix5: "Area prefix 6",
    phones0: "Phones",
    extension0: "Extension",
    countryPrefix1: "Country prefix 1",
    cityPrefix1: "City prefix 1",
    phones1: "Phones 1",
    extension1: "Extension 1",
    countryPrefix2: "Country prefix 2",
    cityPrefix2: "City prefix 2",
    phones2: "Phones 2",
    extension2: "Extension 2",
    countryPrefix3: "Country prefix 3",
    cityPrefix3: "City prefix 3",
    cellular1: "Cellular 1",
    cellular2: "Cellular 2",
    cellular3: "Cellular 3",
    countryPrefix4: "Country prefix 4",
    cityPrefix4: "City prefix 4",
    cellular4: "Cellular 4",
    countryPrefix5: "Country prefix 5",
    cityPrefix5: "City prefix 5",
    cellular5: "Cellular 5",
    createdAt: "Created At",
    updatedAt: "Updated At",
    bookKeepingNumber: "Bookkeeping Number",
    eventDays: "Calendar Event Days"
  }
}