<template>
  <div class="text-center">
    <v-snackbar
      :value="snackbar"
      :timeout="timeout"
      @input="closeSnackbar"
      :color="message.error ? 'red' : 'green'"
    >
      <div v-if="message.error">
        <div v-if="message.error.errors">
          <h4 v-for="(item, index) in message.error.errors" :key="index">
            {{ item.message }}
          </h4>
        </div>
        <div v-else>
          <h4>{{ message.error }}</h4>
        </div>
      </div>
      <div v-else>
        <h4>{{ $t(message.success) }}</h4>
        <h4 class="text-caption" v-if="message.subSuccess">{{ $t(message.subSuccess) }}</h4>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          x-small
          v-bind="attrs"
          @click="$emit('update:snackbar', false)"
        >
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>

export default {
  name: 'SnackBar',
  props: ['message', 'snackbar'],
  data() {
    return {
      timeout: 2000,
      show: this.snackbar
    }
  },
  methods: {
    closeSnackbar(snackbar) {
      this.$emit('update:snackbar', snackbar)
    }
  }
}
</script>