export default {
    id: "קוד",
    description: "תאור",
    newsDate: "תאריך חדשות",
    action: "פעילות",
    addNews: "הוסף חדשות",
    newsList: "רשימת חדשות",
    newsAction: "אקשן חדשות",
    news: "חדשות",
    message: {
        newsSuccessfullyDeleted: "החדשות בוטלו בהצלחה",
        newsSuccessfullyAdded: "חדשות נוספו בהצלחה",
        newsSuccessfullyEdited: "החדשות נערכו בהצלחה",
    }
}