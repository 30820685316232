export default {
    dbInfo:"DB Info",
    importDbInfo: "Import Data",
    query:"Query",
    showData:"Show Data",
    executeQuery:"Execute Query",
    selectTable: "Select Table",
    download: "Download",
    importExcel: "Import Excel",
    selectFile: "Select file",
    upload: "Upload",
    fileData: "File Data",
    selectFields: "Select Fields",
    save: "Save Fields",
    userList: "Current User List",
    uploadExcel: "Upload Excel",
    update: "Verify",
    cancel: "Cancel",
    security: "Security Code",
    access: "Access Restriction",
    personalMenu: "Personal Menu",
    saveMenu: "Save",
    selectDatabaseColumn: "Select DB Column",
    message: {
        importSuccessfully: "File imported successfully.",
        fileIsRequired: "File is required",
        selectTable: "Table is required",
        csvRequired: ".csv file is required",
        verifyCode: "Your code is successfully verified."
    },
    field: {
        id: "Id",
        name: "Name",
        type: "Type",
        email: "Email",
        slug: "Slug"
    }
}