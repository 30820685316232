export default {
    id: 'קוד',
    expenses: 'הוצאות',
    description: "תאור",
    accountNumber: ".מספר חשבון",
    expensesList: "רשימת ההוצאות",
    expensesAction: 'הוצאות פעולה',
    purchasesVat: 'מע"מ תשומות',
    supplierWithHoldingTax: "מס במקור ספקים",
    equipmentPurchaseVat: 'מע"מ לרכישת ציוד',
    addExpenses: 'הוסף הוצאות',
    save: "שמור",
    actions: "פעילות",
    options: "אפשרויות",
    copy: "העתק וצור חדש",
    activityLog: "יומן פעילות",
    relativeNumber: "מספרים יחסיים",
    grossProfit: "רווח גולמי",
    balanceSheetSection: "סעיפי מאזן בוחן",
    message: {
        expensesSuccessfullyDeleted: "ההוצאות בוטלה בהצלחה",
        expensesSuccessfullyAdded: "הוצאה נוספה בהצלחה",
        expensesSuccessfullyEdited: "ההוצאות נערך בהצלחה",
        complusoryNumberSuccessfullyAdded: "מספר חובה נוסף בהצלחה",
        complusoryNumberSuccessfullyEdited: "מספר חובה נערך בהצלחה"
    },
}