export default {
  testByBina: 'Test Software By BINA',
  companyAddress: "ת.ד. 274 גבעת שמואל מיקוד 5410102",
  fax: "פקס",
  tel: "טלפון.",
  vatRegNo: "עוסק מורשה מספר.",
  depositNo: "הפקדה מספר",
  To: "לכבוד",
  page: "עמוד",
  of: "מתוך",
  no: "מס'",
  withRegards: "בברכה",
  generatePDF: "צור PDF",
  previewPDF: "PDF תצוגה מקדימה",
  type: "סוג הפקדה",
  bank: 'בנק',
  depositToBank: 'הפקדה לבנק',
  paymentType: "סוג תשלום",
  chequeNumber: "מספר המחאה/אישור",
  amount: "סכום",
  paymentDate:"תאריך תשלום",
  depositDate:"תאריך הפקדה",
  customerName: "שם לקוח",
  agent: "סוכן",
  original: "מקור",
  copy: "עותק",
  cancelled: "מבוטל",
  message: {
    generatingPdf: "PDF יוצר",
  }
}