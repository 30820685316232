import Vue from "vue";

const purchaseReport = {
  state: {
    purchaseReportList: [],
    purchaseReportQuery: null,
    rowIndex: null,
  },
  getters: {
    purchaseReportList: (state) => state.purchaseReportList,
    purchaseReportQuery: (state) => state.purchaseReportQuery,
    rowIndex: (state) => state.rowIndex,
  },
  mutations: {
    SET_PURCHASE_REPORT_QUERY: (state, payload) => {
      state.purchaseReportQuery = payload;
    },
    SET_PURCHASE_REPORT: (state, payload) => {
      state.purchaseReportList = payload;
    },
    SET_ROWINDEX: (state, payload) => {
      state.rowIndex = payload;
    },
  },
  actions: {
    GetPurchaseReport: async (context, params = {}) => {
      try {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.purchaseReport
            .getPurchaseReport(params)
            .then(({ data }) => {
              context.commit("SET_PURCHASE_REPORT", data);
              resolve(data);
            })
            .catch((error) => {
              if (error.response?.data) {
                let errorData = Object.assign({ error: error.response.data });
                context.dispatch("SetSnackBar", errorData, { root: true });
              }
              console.log(error);
              reject(error);
            });
        });
      } catch (err) {
        console.warn("[vuex.user] GetRoles", err);
      }
    },
  },
  namespaced: true,
};
export default purchaseReport;
