import Vue from 'vue';

const miscellaneous = {
    state: {
        complusoryNumbers: [],
    },
    getters: {
        complusoryNumbers: (state) => state.complusoryNumbers,
    },
    mutations: {
        SET_COMPLUSORY_NUMBERS: (state, payload) => {
            state.complusoryNumbers = payload;
        },
    },
    actions: {
        GetComplusoryNumbers: async (context, params = {}) => {
            try {
                return new Promise((resolve, reject) => {
                    Vue.prototype.$api.bookkeeping
                        .getComplusoryNumbers(params)
                        .then(({ data }) => {
                            context.commit("SET_COMPLUSORY_NUMBERS", data);
                            resolve(data);
                        })
                        .catch((error) => {
                            if (error.response?.data) {
                                let errorData = Object.assign({ error: error.response.data })
                                context.dispatch('SetSnackBar', errorData, { root: true });
                            }
                            console.log(error)
                            reject(error)
                        });
                });
            } catch (err) {
                console.warn('[vuex.user] GetComplusoryNumbers', err);
            }
        },
        SetComplusoryNumbers: async (context, payload) => {
            return new Promise((resolve, reject) => {
                Vue.prototype.$api.bookkeeping
                    .editComplusoryNumbers(payload)
                    .then((res) => {
                        let successData = Object.assign({ success: 'bookkeeping.message.complusoryNumberSuccessfullyEdited' })
                        context.dispatch('SetSnackBar', successData, { root: true });
                        resolve(res);
                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            let errorData = Object.assign({ error: error.response.data })
                            context.dispatch('SetSnackBar', errorData, { root: true })
                        }
                        console.log(error)
                        reject(error)
                    })
            })
        },
    },
    namespaced: true,
}
export default miscellaneous