import Vue from 'vue';
const remarks = {
    state: {
      remarksList: [],
    },
  
    getters: {
      remarksList: (state) => state.remarksList,
    },
    mutations: {
      SET_REMARKS: (state, payload) => {
        state.remarksList = payload;
      },
    },
    actions: {
      GetRemarks: async (context, params = {}) => {
        try {
          return new Promise((resolve, reject) => {
            Vue.prototype.$api.remarks
              .getRemarks(params)
              .then(({ data }) => {
                context.commit("SET_REMARKS", data);
                resolve(data);
              })
              .catch((error) => {
                if(error.response?.data) {
                  let errorData = Object.assign({ error: error.response.data})
                  context.dispatch('SetSnackBar', errorData, {root: true});
                }
                console.log(error)
                reject(error)
              });
          });
        } catch (err) {
          console.warn('[vuex.user] GetRemarks', err);
        }
      },
      SetRemarks: async (context, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$api.remarks
            .addRemarks(payload)
            .then((res) => {
              let successData = Object.assign({ success: 'remarks.message.remarksSuccessfullySaved'})
              context.dispatch('SetSnackBar', successData, {root: true});
              resolve(res);
            })
            .catch((error) => {
              if(error.response?.data) {
                let errorData = Object.assign({ error: error.response.data})
                context.dispatch('SetSnackBar', errorData, {root: true});
              }
              console.log(error)
              reject(error)
            });
        });
      }
    },
    namespaced: true,
  }
  export default remarks;