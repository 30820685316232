export default {
  welcomeToDashboard: "ברוכים הבאים ללוח המחוונים לניהול",
  welcomeToBina: "ברוכים הבאים לבינה",
  receipts: 'קבלות',
  quotations: 'הצעות מחיר',
  invoices: 'חשבוניות',
  orders: 'הזמנות',
  sumOfAllQuotations: 'סכום כל הצעות המחיר',
  sumOfClosedQuotations: 'סכום הצעות מחיר סגורות',
  sumOfCancelledQuotations: 'סכום הצעות מחיר מבוטלות',
  sumOfAllOrders: 'סכום כל ההזמנות',
  sumOfClosedOrders: 'סכום הזמנות סגורות',
  sumOfAllInvoices: 'סכום כל החשבוניות',
  sumOfInvoicesWithUnpaidBalance: 'סכום חשבוניות עם יתרה',
  sumOfAllReceipts: 'סכום כל הקבלות',
  barChart: 'תרשים עמודות'
}